/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from '@azure/ms-rest-js';

export const AccountingData: msRest.CompositeMapper = {
  serializedName: 'AccountingData',
  type: {
    name: 'Composite',
    className: 'AccountingData',
    modelProperties: {
      provider: {
        serializedName: 'provider',
        type: {
          name: 'String'
        }
      },
      tokens: {
        serializedName: 'tokens',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    }
  }
};

export const AdvanceProjection: msRest.CompositeMapper = {
  serializedName: 'AdvanceProjection',
  type: {
    name: 'Composite',
    className: 'AdvanceProjection',
    modelProperties: {
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amountValue: {
        serializedName: 'amount_Value',
        type: {
          name: 'Number'
        }
      },
      amountCurrencyCode: {
        serializedName: 'amount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      remainingValue: {
        serializedName: 'remaining_Value',
        type: {
          name: 'Number'
        }
      },
      remainingCurrencyCode: {
        serializedName: 'remaining_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentValueValue: {
        serializedName: 'nextRepaymentValue_Value',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentValueCurrencyCode: {
        serializedName: 'nextRepaymentValue_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDateUTC: {
        serializedName: 'nextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDay: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDay: {
        readOnly: true,
        serializedName: 'nextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDay: {
        readOnly: true,
        serializedName: 'lastRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'lastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      totalRepaymentAmount: {
        serializedName: 'totalRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      weeklyPayment: {
        serializedName: 'weeklyPayment',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      terms: {
        serializedName: 'terms',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      percentage: {
        serializedName: 'percentage',
        type: {
          name: 'Number'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      name: {
        readOnly: true,
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      earlyRepaid: {
        serializedName: 'earlyRepaid',
        type: {
          name: 'Boolean'
        }
      },
      earlyRepaidDate: {
        serializedName: 'earlyRepaidDate',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepaymentDateUTC: {
        serializedName: 'overrideLastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepayment: {
        serializedName: 'overrideLastRepayment',
        type: {
          name: 'Boolean'
        }
      },
      legacy: {
        serializedName: 'legacy',
        type: {
          name: 'Boolean'
        }
      },
      monthlyFee: {
        serializedName: 'monthlyFee',
        type: {
          name: 'Boolean'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionAgentFee: {
        serializedName: 'commission_AgentFee',
        type: {
          name: 'Number'
        }
      },
      fixed: {
        serializedName: 'fixed',
        type: {
          name: 'Boolean'
        }
      },
      fixedRepayment: {
        serializedName: 'fixedRepayment',
        type: {
          name: 'Number'
        }
      },
      fixedRepaymentFee: {
        serializedName: 'fixedRepaymentFee',
        type: {
          name: 'Number'
        }
      },
      principleBalance: {
        serializedName: 'principleBalance',
        type: {
          name: 'Number'
        }
      },
      accruedBalance: {
        serializedName: 'accruedBalance',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ValidationError: msRest.CompositeMapper = {
  serializedName: 'ValidationError',
  type: {
    name: 'Composite',
    className: 'ValidationError',
    modelProperties: {
      errorCode: {
        serializedName: 'errorCode',
        type: {
          name: 'String'
        }
      },
      errorMessage: {
        serializedName: 'errorMessage',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const AdvanceProjectionApiResponse: msRest.CompositeMapper = {
  serializedName: 'AdvanceProjectionApiResponse',
  type: {
    name: 'Composite',
    className: 'AdvanceProjectionApiResponse',
    modelProperties: {
      errors: {
        serializedName: 'errors',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ValidationError'
            }
          }
        }
      },
      data: {
        serializedName: 'data',
        type: {
          name: 'Composite',
          className: 'AdvanceProjection'
        }
      },
      success: {
        serializedName: 'success',
        type: {
          name: 'Boolean'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const AdvanceProjectionListApiResponse: msRest.CompositeMapper = {
  serializedName: 'AdvanceProjectionListApiResponse',
  type: {
    name: 'Composite',
    className: 'AdvanceProjectionListApiResponse',
    modelProperties: {
      errors: {
        serializedName: 'errors',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ValidationError'
            }
          }
        }
      },
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AdvanceProjection'
            }
          }
        }
      },
      success: {
        serializedName: 'success',
        type: {
          name: 'Boolean'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const AgentNotes: msRest.CompositeMapper = {
  serializedName: 'AgentNotes',
  type: {
    name: 'Composite',
    className: 'AgentNotes',
    modelProperties: {
      note: {
        serializedName: 'note',
        type: {
          name: 'String'
        }
      },
      noteId: {
        serializedName: 'noteId',
        type: {
          name: 'Uuid'
        }
      },
      noteCreated: {
        serializedName: 'noteCreated',
        type: {
          name: 'DateTime'
        }
      },
      author: {
        serializedName: 'author',
        type: {
          name: 'String'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const Agent: msRest.CompositeMapper = {
  serializedName: 'Agent',
  type: {
    name: 'Composite',
    className: 'Agent',
    modelProperties: {
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      name: {
        readOnly: true,
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      partnerBusinessName: {
        serializedName: 'partnerBusinessName',
        type: {
          name: 'String'
        }
      },
      partnerName: {
        serializedName: 'partnerName',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'Boolean'
        }
      },
      compliance: {
        serializedName: 'compliance',
        type: {
          name: 'Boolean'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AgentNotes'
            }
          }
        }
      },
      deleted: {
        serializedName: 'deleted',
        type: {
          name: 'Boolean'
        }
      },
      deletedDate: {
        serializedName: 'deletedDate',
        type: {
          name: 'DateTime'
        }
      },
      lockoutEnabled: {
        serializedName: 'lockoutEnabled',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const AlertNotificationProjection: msRest.CompositeMapper = {
  serializedName: 'AlertNotificationProjection',
  type: {
    name: 'Composite',
    className: 'AlertNotificationProjection',
    modelProperties: {
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      isRead: {
        serializedName: 'isRead',
        type: {
          name: 'Boolean'
        }
      },
      classification: {
        serializedName: 'classification',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Announcement: msRest.CompositeMapper = {
  serializedName: 'Announcement',
  type: {
    name: 'Composite',
    className: 'Announcement',
    modelProperties: {
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      forAllPartners: {
        serializedName: 'forAllPartners',
        type: {
          name: 'Boolean'
        }
      },
      forAllAgents: {
        serializedName: 'forAllAgents',
        type: {
          name: 'Boolean'
        }
      },
      partnersOrAgents: {
        serializedName: 'partnersOrAgents',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Uuid'
            }
          }
        }
      }
    }
  }
};

export const ApplicantProjection: msRest.CompositeMapper = {
  serializedName: 'ApplicantProjection',
  type: {
    name: 'Composite',
    className: 'ApplicantProjection',
    modelProperties: {
      image: {
        serializedName: 'image',
        type: {
          name: 'ByteArray'
        }
      },
      miscellaneousData: {
        serializedName: 'miscellaneousData',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      address: {
        serializedName: 'address',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      licenceState: {
        serializedName: 'licenceState',
        type: {
          name: 'String'
        }
      },
      licenceNumber: {
        serializedName: 'licenceNumber',
        type: {
          name: 'String'
        }
      },
      licenceExpiryDate: {
        serializedName: 'licenceExpiryDate',
        type: {
          name: 'DateTime'
        }
      },
      licenceName: {
        serializedName: 'licenceName',
        type: {
          name: 'String'
        }
      },
      licenceAddress: {
        serializedName: 'licenceAddress',
        type: {
          name: 'String'
        }
      },
      medicareName: {
        serializedName: 'medicareName',
        type: {
          name: 'String'
        }
      },
      medicareDate: {
        serializedName: 'medicareDate',
        type: {
          name: 'DateTime'
        }
      },
      medicareNumber: {
        serializedName: 'medicareNumber',
        type: {
          name: 'String'
        }
      },
      medicareIssue: {
        serializedName: 'medicareIssue',
        type: {
          name: 'String'
        }
      },
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      licenceCity: {
        serializedName: 'licenceCity',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const ApplicationAndCustomerAndApplicantResponse: msRest.CompositeMapper = {
  serializedName: 'ApplicationAndCustomerAndApplicantResponse',
  type: {
    name: 'Composite',
    className: 'ApplicationAndCustomerAndApplicantResponse',
    modelProperties: {
      applicantId: {
        serializedName: 'applicantId',
        type: {
          name: 'Uuid'
        }
      },
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      url: {
        serializedName: 'url',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      token: {
        serializedName: 'token',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const ApplicationAndCustomerResponse: msRest.CompositeMapper = {
  serializedName: 'ApplicationAndCustomerResponse',
  type: {
    name: 'Composite',
    className: 'ApplicationAndCustomerResponse',
    modelProperties: {
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      url: {
        serializedName: 'url',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      token: {
        serializedName: 'token',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Tag: msRest.CompositeMapper = {
  serializedName: 'Tag',
  type: {
    name: 'Composite',
    className: 'Tag',
    modelProperties: {
      creditDebit: {
        serializedName: 'creditDebit',
        type: {
          name: 'String'
        }
      },
      pending: {
        serializedName: 'pending',
        type: {
          name: 'String'
        }
      },
      original: {
        serializedName: 'original',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Transaction: msRest.CompositeMapper = {
  serializedName: 'Transaction',
  type: {
    name: 'Composite',
    className: 'Transaction',
    modelProperties: {
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      realAmount: {
        readOnly: true,
        serializedName: 'realAmount',
        type: {
          name: 'Number'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      text: {
        serializedName: 'text',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      tags: {
        serializedName: 'tags',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Tag'
            }
          }
        }
      },
      catgeory: {
        serializedName: 'catgeory',
        type: {
          name: 'String'
        }
      },
      textSize: {
        readOnly: true,
        serializedName: 'textSize',
        type: {
          name: 'Number'
        }
      },
      tagSize: {
        readOnly: true,
        serializedName: 'tagSize',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const BankAccount: msRest.CompositeMapper = {
  serializedName: 'BankAccount',
  type: {
    name: 'Composite',
    className: 'BankAccount',
    modelProperties: {
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      selected: {
        serializedName: 'selected',
        type: {
          name: 'Boolean'
        }
      },
      transactions: {
        serializedName: 'transactions',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Transaction'
            }
          }
        }
      },
      recentTransaction: {
        serializedName: 'recentTransaction',
        type: {
          name: 'Composite',
          className: 'Transaction'
        }
      },
      bank: {
        serializedName: 'bank',
        type: {
          name: 'String'
        }
      },
      institutionId: {
        serializedName: 'institutionId',
        type: {
          name: 'String'
        }
      },
      institutionName: {
        serializedName: 'institutionName',
        type: {
          name: 'String'
        }
      },
      accountName: {
        serializedName: 'accountName',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      },
      bsb: {
        serializedName: 'bsb',
        type: {
          name: 'String'
        }
      },
      currentBalance: {
        serializedName: 'currentBalance',
        type: {
          name: 'String'
        }
      },
      availableBalance: {
        serializedName: 'availableBalance',
        type: {
          name: 'String'
        }
      },
      accountHolder: {
        serializedName: 'accountHolder',
        type: {
          name: 'String'
        }
      },
      accountType: {
        serializedName: 'accountType',
        type: {
          name: 'String'
        }
      },
      slug: {
        serializedName: 'slug',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'Boolean'
        }
      },
      archived: {
        serializedName: 'archived',
        type: {
          name: 'Boolean'
        }
      },
      isConnected: {
        serializedName: 'isConnected',
        type: {
          name: 'Boolean'
        }
      },
      requestId: {
        serializedName: 'requestId',
        type: {
          name: 'String'
        }
      },
      isPlaid: {
        serializedName: 'isPlaid',
        type: {
          name: 'Boolean'
        }
      },
      isYodlee: {
        serializedName: 'isYodlee',
        type: {
          name: 'Boolean'
        }
      },
      isProviso: {
        serializedName: 'isProviso',
        type: {
          name: 'Boolean'
        }
      },
      isAuxiliary: {
        serializedName: 'isAuxiliary',
        type: {
          name: 'Boolean'
        }
      },
      accountId: {
        readOnly: true,
        serializedName: 'accountId',
        type: {
          name: 'String'
        }
      },
      displayName: {
        readOnly: true,
        serializedName: 'displayName',
        type: {
          name: 'String'
        }
      },
      oldAvailableBalance: {
        serializedName: 'oldAvailableBalance',
        type: {
          name: 'String'
        }
      },
      oldCurrentBalance: {
        serializedName: 'oldCurrentBalance',
        type: {
          name: 'String'
        }
      },
      expireCurrentBalance: {
        serializedName: 'expireCurrentBalance',
        type: {
          name: 'String'
        }
      },
      notFound: {
        serializedName: 'notFound',
        type: {
          name: 'Boolean'
        }
      },
      expiredAvailableBalance: {
        serializedName: 'expiredAvailableBalance',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlaidBankAccount: msRest.CompositeMapper = {
  serializedName: 'PlaidBankAccount',
  type: {
    name: 'Composite',
    className: 'PlaidBankAccount',
    modelProperties: {
      accountId: {
        serializedName: 'accountId',
        type: {
          name: 'String'
        }
      },
      mask: {
        serializedName: 'mask',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const BankAccountConnectionDetails: msRest.CompositeMapper = {
  serializedName: 'BankAccountConnectionDetails',
  type: {
    name: 'Composite',
    className: 'BankAccountConnectionDetails',
    modelProperties: {
      requestId: {
        serializedName: 'requestId',
        type: {
          name: 'String'
        }
      },
      isConnected: {
        serializedName: 'isConnected',
        type: {
          name: 'Boolean'
        }
      },
      transferStatus: {
        serializedName: 'transferStatus',
        type: {
          name: 'String'
        }
      },
      wallet: {
        serializedName: 'wallet',
        type: {
          name: 'String'
        }
      },
      publicToken: {
        serializedName: 'publicToken',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      providerId: {
        serializedName: 'providerId',
        type: {
          name: 'String'
        }
      },
      providerName: {
        serializedName: 'providerName',
        type: {
          name: 'String'
        }
      },
      accountId: {
        serializedName: 'accountId',
        type: {
          name: 'String'
        }
      },
      linkSessionId: {
        serializedName: 'linkSessionId',
        type: {
          name: 'String'
        }
      },
      accounts: {
        serializedName: 'accounts',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlaidBankAccount'
            }
          }
        }
      }
    }
  }
};

export const BankDetails: msRest.CompositeMapper = {
  serializedName: 'BankDetails',
  type: {
    name: 'Composite',
    className: 'BankDetails',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      bsb: {
        serializedName: 'bsb',
        type: {
          name: 'String'
        }
      },
      number: {
        serializedName: 'number',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const BankingData: msRest.CompositeMapper = {
  serializedName: 'BankingData',
  type: {
    name: 'Composite',
    className: 'BankingData',
    modelProperties: {
      bankName: {
        serializedName: 'bankName',
        type: {
          name: 'String'
        }
      },
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'String'
        }
      },
      password: {
        serializedName: 'password',
        type: {
          name: 'String'
        }
      },
      extraDetails: {
        serializedName: 'extraDetails',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      yodlee: {
        serializedName: 'yodlee',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const CancelReason: msRest.CompositeMapper = {
  serializedName: 'CancelReason',
  type: {
    name: 'Composite',
    className: 'CancelReason',
    modelProperties: {
      reasonId: {
        serializedName: 'reasonId',
        type: {
          name: 'Uuid'
        }
      },
      reason: {
        serializedName: 'reason',
        type: {
          name: 'String'
        }
      },
      noteToPartnerAgent: {
        serializedName: 'noteToPartnerAgent',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Commission: msRest.CompositeMapper = {
  serializedName: 'Commission',
  type: {
    name: 'Composite',
    className: 'Commission',
    modelProperties: {
      upfrontFee: {
        serializedName: 'upfrontFee',
        type: {
          name: 'Number'
        }
      },
      trailing: {
        serializedName: 'trailing',
        type: {
          name: 'Number'
        }
      },
      drawFee: {
        serializedName: 'drawFee',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CommissionRequest: msRest.CompositeMapper = {
  serializedName: 'CommissionRequest',
  type: {
    name: 'Composite',
    className: 'CommissionRequest',
    modelProperties: {
      upfrontFee: {
        serializedName: 'upfrontFee',
        type: {
          name: 'Number'
        }
      },
      trailing: {
        serializedName: 'trailing',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CommissionSet: msRest.CompositeMapper = {
  serializedName: 'CommissionSet',
  type: {
    name: 'Composite',
    className: 'CommissionSet',
    modelProperties: {
      nonSecured: {
        serializedName: 'nonSecured',
        type: {
          name: 'Composite',
          className: 'Commission'
        }
      },
      secured: {
        serializedName: 'secured',
        type: {
          name: 'Composite',
          className: 'Commission'
        }
      },
      lineOfCredit: {
        serializedName: 'lineOfCredit',
        type: {
          name: 'Composite',
          className: 'Commission'
        }
      }
    }
  }
};

export const Communication: msRest.CompositeMapper = {
  serializedName: 'Communication',
  type: {
    name: 'Composite',
    className: 'Communication',
    modelProperties: {
      createdDateUtc: {
        serializedName: 'createdDateUtc',
        type: {
          name: 'DateTime'
        }
      },
      message: {
        required: true,
        serializedName: 'message',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      user: {
        required: true,
        serializedName: 'user',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const CommunicationProjectionExtension: msRest.CompositeMapper = {
  serializedName: 'CommunicationProjectionExtension',
  type: {
    name: 'Composite',
    className: 'CommunicationProjectionExtension',
    modelProperties: {
      isRead: {
        serializedName: 'isRead',
        type: {
          name: 'Boolean'
        }
      },
      updatedDateUtc: {
        serializedName: 'updatedDateUtc',
        type: {
          name: 'DateTime'
        }
      },
      forwardNotes: {
        serializedName: 'forwardNotes',
        type: {
          name: 'String'
        }
      },
      originatingPartnerId: {
        serializedName: 'originatingPartnerId',
        type: {
          name: 'Uuid'
        }
      },
      notificationId: {
        serializedName: 'notificationId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      createdDateUtc: {
        serializedName: 'createdDateUtc',
        type: {
          name: 'DateTime'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      user: {
        serializedName: 'user',
        type: {
          name: 'String'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      sendToPartner: {
        serializedName: 'sendToPartner',
        type: {
          name: 'Boolean'
        }
      },
      sendToAgent: {
        serializedName: 'sendToAgent',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const CommunicationRule: msRest.CompositeMapper = {
  serializedName: 'CommunicationRule',
  type: {
    name: 'Composite',
    className: 'CommunicationRule',
    modelProperties: {
      userId: {
        serializedName: 'userId',
        type: {
          name: 'Uuid'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const CommunicationTemplateSetting: msRest.CompositeMapper = {
  serializedName: 'CommunicationTemplateSetting',
  type: {
    name: 'Composite',
    className: 'CommunicationTemplateSetting',
    modelProperties: {
      templateId: {
        serializedName: 'templateId',
        type: {
          name: 'Uuid'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      template: {
        serializedName: 'template',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const CompleteContractRequest: msRest.CompositeMapper = {
  serializedName: 'CompleteContractRequest',
  type: {
    name: 'Composite',
    className: 'CompleteContractRequest',
    modelProperties: {
      ipAddress: {
        serializedName: 'ipAddress',
        type: {
          name: 'String'
        }
      },
      signature: {
        serializedName: 'signature',
        type: {
          name: 'ByteArray'
        }
      },
      signature2: {
        serializedName: 'signature2',
        type: {
          name: 'ByteArray'
        }
      },
      mimeType: {
        serializedName: 'mimeType',
        type: {
          name: 'String'
        }
      },
      secondaryApplicant: {
        serializedName: 'secondaryApplicant',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const Contact: msRest.CompositeMapper = {
  serializedName: 'Contact',
  type: {
    name: 'Composite',
    className: 'Contact',
    modelProperties: {
      contactId: {
        serializedName: 'contactId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      addedDate: {
        serializedName: 'addedDate',
        type: {
          name: 'DateTime'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const PrimaryApplicant: msRest.CompositeMapper = {
  serializedName: 'PrimaryApplicant',
  type: {
    name: 'Composite',
    className: 'PrimaryApplicant',
    modelProperties: {
      isManuallyAdded: {
        serializedName: 'isManuallyAdded',
        type: {
          name: 'Boolean'
        }
      },
      image: {
        serializedName: 'image',
        type: {
          name: 'ByteArray'
        }
      },
      miscellaneousData: {
        serializedName: 'miscellaneousData',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      address: {
        serializedName: 'address',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      licenceState: {
        serializedName: 'licenceState',
        type: {
          name: 'String'
        }
      },
      licenceNumber: {
        serializedName: 'licenceNumber',
        type: {
          name: 'String'
        }
      },
      licenceExpiryDate: {
        serializedName: 'licenceExpiryDate',
        type: {
          name: 'DateTime'
        }
      },
      licenceName: {
        serializedName: 'licenceName',
        type: {
          name: 'String'
        }
      },
      licenceAddress: {
        serializedName: 'licenceAddress',
        type: {
          name: 'String'
        }
      },
      medicareName: {
        serializedName: 'medicareName',
        type: {
          name: 'String'
        }
      },
      medicareDate: {
        serializedName: 'medicareDate',
        type: {
          name: 'DateTime'
        }
      },
      medicareNumber: {
        serializedName: 'medicareNumber',
        type: {
          name: 'String'
        }
      },
      medicareIssue: {
        serializedName: 'medicareIssue',
        type: {
          name: 'String'
        }
      },
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      licenceCity: {
        serializedName: 'licenceCity',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const DocumentRequest: msRest.CompositeMapper = {
  serializedName: 'DocumentRequest',
  type: {
    name: 'Composite',
    className: 'DocumentRequest',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      filename: {
        serializedName: 'filename',
        type: {
          name: 'String'
        }
      },
      fileData: {
        serializedName: 'fileData',
        type: {
          name: 'String'
        }
      },
      fileType: {
        serializedName: 'fileType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const StartApplicationRequest: msRest.CompositeMapper = {
  serializedName: 'StartApplicationRequest',
  type: {
    name: 'Composite',
    className: 'StartApplicationRequest',
    modelProperties: {
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      customerId: {
        required: true,
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      mobile: {
        required: true,
        serializedName: 'mobile',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      email: {
        required: true,
        serializedName: 'email',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      extraInformation: {
        serializedName: 'extraInformation',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    }
  }
};

export const Customer: msRest.CompositeMapper = {
  serializedName: 'Customer',
  type: {
    name: 'Composite',
    className: 'Customer',
    modelProperties: {
      email: {
        required: true,
        serializedName: 'email',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      mobileNumber: {
        required: true,
        serializedName: 'mobileNumber',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      password: {
        serializedName: 'password',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      parentId: {
        serializedName: 'parentId',
        type: {
          name: 'Uuid'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      hasLoggedIn: {
        serializedName: 'hasLoggedIn',
        type: {
          name: 'Boolean'
        }
      },
      lockoutEnabled: {
        serializedName: 'lockoutEnabled',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const CreateApplicationAndCustomerAndApplicantAndSupportDocumentsRequest: msRest.CompositeMapper = {
  serializedName: 'CreateApplicationAndCustomerAndApplicantAndSupportDocumentsRequest',
  type: {
    name: 'Composite',
    className: 'CreateApplicationAndCustomerAndApplicantAndSupportDocumentsRequest',
    modelProperties: {
      applicant: {
        serializedName: 'applicant',
        type: {
          name: 'Composite',
          className: 'PrimaryApplicant'
        }
      },
      documents: {
        serializedName: 'documents',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DocumentRequest'
            }
          }
        }
      },
      trackingCode: {
        serializedName: 'trackingCode',
        type: {
          name: 'String'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'String'
        }
      },
      application: {
        serializedName: 'application',
        type: {
          name: 'Composite',
          className: 'StartApplicationRequest'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'Composite',
          className: 'Customer'
        }
      }
    }
  }
};

export const CreateApplicationAndCustomerAndSupportDocumentsRequest: msRest.CompositeMapper = {
  serializedName: 'CreateApplicationAndCustomerAndSupportDocumentsRequest',
  type: {
    name: 'Composite',
    className: 'CreateApplicationAndCustomerAndSupportDocumentsRequest',
    modelProperties: {
      documents: {
        serializedName: 'documents',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DocumentRequest'
            }
          }
        }
      },
      trackingCode: {
        serializedName: 'trackingCode',
        type: {
          name: 'String'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'String'
        }
      },
      application: {
        serializedName: 'application',
        type: {
          name: 'Composite',
          className: 'StartApplicationRequest'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'Composite',
          className: 'Customer'
        }
      }
    }
  }
};

export const CreateApplicationAndCustomerRequest: msRest.CompositeMapper = {
  serializedName: 'CreateApplicationAndCustomerRequest',
  type: {
    name: 'Composite',
    className: 'CreateApplicationAndCustomerRequest',
    modelProperties: {
      application: {
        serializedName: 'application',
        type: {
          name: 'Composite',
          className: 'StartApplicationRequest'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'Composite',
          className: 'Customer'
        }
      }
    }
  }
};

export const CreateApplicationAndCustomerWithApplicantRequest: msRest.CompositeMapper = {
  serializedName: 'CreateApplicationAndCustomerWithApplicantRequest',
  type: {
    name: 'Composite',
    className: 'CreateApplicationAndCustomerWithApplicantRequest',
    modelProperties: {
      application: {
        serializedName: 'application',
        type: {
          name: 'Composite',
          className: 'StartApplicationRequest'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'Composite',
          className: 'Customer'
        }
      },
      applicant: {
        serializedName: 'applicant',
        type: {
          name: 'Composite',
          className: 'PrimaryApplicant'
        }
      }
    }
  }
};

export const CustomerLoanProjection: msRest.CompositeMapper = {
  serializedName: 'CustomerLoanProjection',
  type: {
    name: 'Composite',
    className: 'CustomerLoanProjection',
    modelProperties: {
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      customerFullName: {
        serializedName: 'customerFullName',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      health: {
        serializedName: 'health',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      salesPerson: {
        serializedName: 'salesPerson',
        type: {
          name: 'String'
        }
      },
      lastUpdated: {
        serializedName: 'lastUpdated',
        type: {
          name: 'DateTime'
        }
      },
      organisationId: {
        serializedName: 'organisationId',
        type: {
          name: 'Uuid'
        }
      },
      offerId: {
        serializedName: 'offerId',
        type: {
          name: 'Uuid'
        }
      },
      closed: {
        serializedName: 'closed',
        type: {
          name: 'Boolean'
        }
      },
      archived: {
        serializedName: 'archived',
        type: {
          name: 'Boolean'
        }
      },
      enabledDebtors: {
        serializedName: 'enabledDebtors',
        type: {
          name: 'Boolean'
        }
      },
      showAllDebtors: {
        serializedName: 'showAllDebtors',
        type: {
          name: 'Boolean'
        }
      },
      bankId: {
        serializedName: 'bankId',
        type: {
          name: 'String'
        }
      },
      funder: {
        serializedName: 'funder',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'String'
        }
      },
      flagged: {
        serializedName: 'flagged',
        type: {
          name: 'Boolean'
        }
      },
      flaggedReason: {
        serializedName: 'flaggedReason',
        type: {
          name: 'String'
        }
      },
      flaggedDate: {
        serializedName: 'flaggedDate',
        type: {
          name: 'DateTime'
        }
      },
      excluded: {
        serializedName: 'excluded',
        type: {
          name: 'Boolean'
        }
      },
      excludedReason: {
        serializedName: 'excludedReason',
        type: {
          name: 'String'
        }
      },
      countryCode: {
        serializedName: 'countryCode',
        type: {
          name: 'String'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      fullBusinessName: {
        serializedName: 'fullBusinessName',
        type: {
          name: 'String'
        }
      },
      secured: {
        serializedName: 'secured',
        type: {
          name: 'Boolean'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      nextAuditDate: {
        serializedName: 'nextAuditDate',
        type: {
          name: 'DateTime'
        }
      },
      auditLowRange: {
        serializedName: 'auditLowRange',
        type: {
          name: 'Number'
        }
      },
      auditHighRange: {
        serializedName: 'auditHighRange',
        type: {
          name: 'Number'
        }
      },
      auditType: {
        serializedName: 'auditType',
        type: {
          name: 'String'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'String'
        }
      },
      closedDate: {
        serializedName: 'closedDate',
        type: {
          name: 'DateTime'
        }
      },
      onOffAudit: {
        serializedName: 'onOffAudit',
        type: {
          name: 'Boolean'
        }
      },
      auditId: {
        serializedName: 'auditId',
        type: {
          name: 'Uuid'
        }
      },
      balanceOverride: {
        serializedName: 'balanceOverride',
        type: {
          name: 'Boolean'
        }
      },
      defaulted: {
        serializedName: 'defaulted',
        type: {
          name: 'Boolean'
        }
      },
      defaultStatus: {
        serializedName: 'defaultStatus',
        type: {
          name: 'String'
        }
      },
      associatedCustomerIdStr: {
        serializedName: 'associatedCustomerIdStr',
        type: {
          name: 'String'
        }
      },
      totalAmountValue: {
        serializedName: 'totalAmount_Value',
        type: {
          name: 'Number'
        }
      },
      totalAmountCurrencyCode: {
        serializedName: 'totalAmount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      availableCreditValue: {
        serializedName: 'availableCredit_Value',
        type: {
          name: 'Number'
        }
      },
      availableCreditCurrencyCode: {
        serializedName: 'availableCredit_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      securedCommissionUpfrontFee: {
        serializedName: 'securedCommission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      securedCommissionTrailing: {
        serializedName: 'securedCommission_Trailing',
        type: {
          name: 'Number'
        }
      },
      securedCommissionDrawFee: {
        serializedName: 'securedCommission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionUpfrontFee: {
        serializedName: 'lineOfCreditCommission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionTrailing: {
        serializedName: 'lineOfCreditCommission_Trailing',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionDrawFee: {
        serializedName: 'lineOfCreditCommission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      mobileNumber: {
        serializedName: 'mobileNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const DecisionDetails: msRest.CompositeMapper = {
  serializedName: 'DecisionDetails',
  type: {
    name: 'Composite',
    className: 'DecisionDetails',
    modelProperties: {
      reasons: {
        serializedName: 'reasons',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      },
      reasonsToString: {
        readOnly: true,
        serializedName: 'reasonsToString',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'Number'
        }
      },
      statusName: {
        readOnly: true,
        serializedName: 'statusName',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const DisplayMessageModal: msRest.CompositeMapper = {
  serializedName: 'DisplayMessageModal',
  type: {
    name: 'Composite',
    className: 'DisplayMessageModal',
    modelProperties: {
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      isDismiss: {
        serializedName: 'isDismiss',
        type: {
          name: 'Boolean'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      isActiveLoansOnly: {
        serializedName: 'isActiveLoansOnly',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const GuidApiResponse: msRest.CompositeMapper = {
  serializedName: 'GuidApiResponse',
  type: {
    name: 'Composite',
    className: 'GuidApiResponse',
    modelProperties: {
      errors: {
        serializedName: 'errors',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ValidationError'
            }
          }
        }
      },
      data: {
        serializedName: 'data',
        type: {
          name: 'Uuid'
        }
      },
      success: {
        serializedName: 'success',
        type: {
          name: 'Boolean'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const GuidListApiResponse: msRest.CompositeMapper = {
  serializedName: 'GuidListApiResponse',
  type: {
    name: 'Composite',
    className: 'GuidListApiResponse',
    modelProperties: {
      errors: {
        serializedName: 'errors',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ValidationError'
            }
          }
        }
      },
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Uuid'
            }
          }
        }
      },
      success: {
        serializedName: 'success',
        type: {
          name: 'Boolean'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const HealthCheckRule: msRest.CompositeMapper = {
  serializedName: 'HealthCheckRule',
  type: {
    name: 'Composite',
    className: 'HealthCheckRule',
    modelProperties: {
      internalNote: {
        serializedName: 'internalNote',
        type: {
          name: 'String'
        }
      },
      externalNote: {
        serializedName: 'externalNote',
        type: {
          name: 'String'
        }
      },
      passRule: {
        serializedName: 'passRule',
        type: {
          name: 'Boolean'
        }
      },
      testName: {
        serializedName: 'testName',
        type: {
          name: 'String'
        }
      },
      transactionDataStaleTest: {
        serializedName: 'transactionDataStaleTest',
        type: {
          name: 'Boolean'
        }
      },
      rawData: {
        serializedName: 'rawData',
        type: {
          name: 'String'
        }
      },
      allowDisable: {
        serializedName: 'allowDisable',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const HealthCheckCollection: msRest.CompositeMapper = {
  serializedName: 'HealthCheckCollection',
  type: {
    name: 'Composite',
    className: 'HealthCheckCollection',
    modelProperties: {
      rules: {
        serializedName: 'rules',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'HealthCheckRule'
            }
          }
        }
      },
      transactionBalance: {
        serializedName: 'transactionBalance',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const HealthRuleDetails: msRest.CompositeMapper = {
  serializedName: 'HealthRuleDetails',
  type: {
    name: 'Composite',
    className: 'HealthRuleDetails',
    modelProperties: {
      loan: {
        serializedName: 'loan',
        type: {
          name: 'Composite',
          className: 'CustomerLoanProjection'
        }
      },
      rules: {
        serializedName: 'rules',
        type: {
          name: 'Composite',
          className: 'HealthCheckCollection'
        }
      },
      rulesString: {
        readOnly: true,
        serializedName: 'rulesString',
        type: {
          name: 'String'
        }
      },
      isStatusChanged: {
        serializedName: 'isStatusChanged',
        type: {
          name: 'Boolean'
        }
      },
      refinanceScore: {
        serializedName: 'refinanceScore',
        type: {
          name: 'Number'
        }
      },
      status: {
        readOnly: true,
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      overrideEnabled: {
        serializedName: 'overrideEnabled',
        type: {
          name: 'DateTime'
        }
      },
      getDaysRemaining: {
        readOnly: true,
        serializedName: 'getDaysRemaining',
        type: {
          name: 'Number'
        }
      },
      daysSinceCreated: {
        readOnly: true,
        serializedName: 'daysSinceCreated',
        type: {
          name: 'Number'
        }
      },
      rawData: {
        serializedName: 'rawData',
        type: {
          name: 'String'
        }
      },
      lastAdvance: {
        serializedName: 'lastAdvance',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const Insight: msRest.CompositeMapper = {
  serializedName: 'Insight',
  type: {
    name: 'Composite',
    className: 'Insight',
    modelProperties: {
      insightId: {
        serializedName: 'insightId',
        type: {
          name: 'Uuid'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      description: {
        serializedName: 'description',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      isNew: {
        serializedName: 'isNew',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const Investor: msRest.CompositeMapper = {
  serializedName: 'Investor',
  type: {
    name: 'Composite',
    className: 'Investor',
    modelProperties: {
      email: {
        required: true,
        serializedName: 'email',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      phoneNumber: {
        serializedName: 'phoneNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Note: msRest.CompositeMapper = {
  serializedName: 'Note',
  type: {
    name: 'Composite',
    className: 'Note',
    modelProperties: {
      noteId: {
        serializedName: 'noteId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      temporary: {
        serializedName: 'temporary',
        type: {
          name: 'Boolean'
        }
      },
      author: {
        serializedName: 'author',
        type: {
          name: 'String'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const Notification: msRest.CompositeMapper = {
  serializedName: 'Notification',
  type: {
    name: 'Composite',
    className: 'Notification',
    modelProperties: {
      createdOnUtc: {
        serializedName: 'createdOnUtc',
        type: {
          name: 'DateTime'
        }
      },
      message: {
        required: true,
        serializedName: 'message',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      read: {
        serializedName: 'read',
        type: {
          name: 'Boolean'
        }
      },
      readOnUtc: {
        serializedName: 'readOnUtc',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const OriginationBankAccount: msRest.CompositeMapper = {
  serializedName: 'OriginationBankAccount',
  type: {
    name: 'Composite',
    className: 'OriginationBankAccount',
    modelProperties: {
      bankAccountId: {
        serializedName: 'bankAccountId',
        type: {
          name: 'String'
        }
      },
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      selected: {
        serializedName: 'selected',
        type: {
          name: 'Boolean'
        }
      },
      transactions: {
        serializedName: 'transactions',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Transaction'
            }
          }
        }
      },
      recentTransaction: {
        serializedName: 'recentTransaction',
        type: {
          name: 'Composite',
          className: 'Transaction'
        }
      },
      bank: {
        serializedName: 'bank',
        type: {
          name: 'String'
        }
      },
      institutionId: {
        serializedName: 'institutionId',
        type: {
          name: 'String'
        }
      },
      institutionName: {
        serializedName: 'institutionName',
        type: {
          name: 'String'
        }
      },
      accountName: {
        serializedName: 'accountName',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      },
      bsb: {
        serializedName: 'bsb',
        type: {
          name: 'String'
        }
      },
      currentBalance: {
        serializedName: 'currentBalance',
        type: {
          name: 'String'
        }
      },
      availableBalance: {
        serializedName: 'availableBalance',
        type: {
          name: 'String'
        }
      },
      accountHolder: {
        serializedName: 'accountHolder',
        type: {
          name: 'String'
        }
      },
      accountType: {
        serializedName: 'accountType',
        type: {
          name: 'String'
        }
      },
      slug: {
        serializedName: 'slug',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'Boolean'
        }
      },
      archived: {
        serializedName: 'archived',
        type: {
          name: 'Boolean'
        }
      },
      isConnected: {
        serializedName: 'isConnected',
        type: {
          name: 'Boolean'
        }
      },
      requestId: {
        serializedName: 'requestId',
        type: {
          name: 'String'
        }
      },
      isPlaid: {
        serializedName: 'isPlaid',
        type: {
          name: 'Boolean'
        }
      },
      isYodlee: {
        serializedName: 'isYodlee',
        type: {
          name: 'Boolean'
        }
      },
      isProviso: {
        serializedName: 'isProviso',
        type: {
          name: 'Boolean'
        }
      },
      isAuxiliary: {
        serializedName: 'isAuxiliary',
        type: {
          name: 'Boolean'
        }
      },
      accountId: {
        readOnly: true,
        serializedName: 'accountId',
        type: {
          name: 'String'
        }
      },
      displayName: {
        readOnly: true,
        serializedName: 'displayName',
        type: {
          name: 'String'
        }
      },
      oldAvailableBalance: {
        serializedName: 'oldAvailableBalance',
        type: {
          name: 'String'
        }
      },
      oldCurrentBalance: {
        serializedName: 'oldCurrentBalance',
        type: {
          name: 'String'
        }
      },
      expireCurrentBalance: {
        serializedName: 'expireCurrentBalance',
        type: {
          name: 'String'
        }
      },
      notFound: {
        serializedName: 'notFound',
        type: {
          name: 'Boolean'
        }
      },
      expiredAvailableBalance: {
        serializedName: 'expiredAvailableBalance',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PartnerCommission: msRest.CompositeMapper = {
  serializedName: 'PartnerCommission',
  type: {
    name: 'Composite',
    className: 'PartnerCommission',
    modelProperties: {
      unsecuredTrail: {
        serializedName: 'unsecuredTrail',
        type: {
          name: 'Number'
        }
      },
      securedTrail: {
        serializedName: 'securedTrail',
        type: {
          name: 'Number'
        }
      },
      locTrail: {
        serializedName: 'locTrail',
        type: {
          name: 'Number'
        }
      },
      upFrontFee: {
        serializedName: 'upFrontFee',
        type: {
          name: 'Number'
        }
      },
      drawFee: {
        serializedName: 'drawFee',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const Partner: msRest.CompositeMapper = {
  serializedName: 'Partner',
  type: {
    name: 'Composite',
    className: 'Partner',
    modelProperties: {
      email: {
        required: true,
        serializedName: 'email',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      mobilePhoneNumber: {
        serializedName: 'mobilePhoneNumber',
        type: {
          name: 'String'
        }
      },
      commission: {
        serializedName: 'commission',
        type: {
          name: 'Composite',
          className: 'PartnerCommission'
        }
      },
      urlReferral: {
        serializedName: 'urlReferral',
        type: {
          name: 'String'
        }
      },
      owner: {
        serializedName: 'owner',
        type: {
          name: 'Boolean'
        }
      },
      displayCommission: {
        serializedName: 'displayCommission',
        type: {
          name: 'Boolean'
        }
      },
      agentSupport: {
        serializedName: 'agentSupport',
        type: {
          name: 'Boolean'
        }
      },
      editAgentSupport: {
        serializedName: 'editAgentSupport',
        type: {
          name: 'Boolean'
        }
      },
      isPartnerAPIEnabled: {
        serializedName: 'isPartnerAPIEnabled',
        type: {
          name: 'Boolean'
        }
      },
      displayAdvances: {
        serializedName: 'displayAdvances',
        type: {
          name: 'Boolean'
        }
      },
      hideAgentApplication: {
        serializedName: 'hideAgentApplication',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const PartnerOwnerDetails: msRest.CompositeMapper = {
  serializedName: 'PartnerOwnerDetails',
  type: {
    name: 'Composite',
    className: 'PartnerOwnerDetails',
    modelProperties: {
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      preferredName: {
        serializedName: 'preferredName',
        type: {
          name: 'String'
        }
      },
      lastname: {
        serializedName: 'lastname',
        type: {
          name: 'String'
        }
      },
      cellNumber: {
        serializedName: 'cellNumber',
        type: {
          name: 'String'
        }
      },
      officeNumber: {
        serializedName: 'officeNumber',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      driversLicenceNumber: {
        serializedName: 'driversLicenceNumber',
        type: {
          name: 'String'
        }
      },
      stateIssued: {
        serializedName: 'stateIssued',
        type: {
          name: 'String'
        }
      },
      ssn: {
        serializedName: 'ssn',
        type: {
          name: 'String'
        }
      },
      dob: {
        serializedName: 'dob',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const PartnerBusinessDetails: msRest.CompositeMapper = {
  serializedName: 'PartnerBusinessDetails',
  type: {
    name: 'Composite',
    className: 'PartnerBusinessDetails',
    modelProperties: {
      companyName: {
        serializedName: 'companyName',
        type: {
          name: 'String'
        }
      },
      companyAddress: {
        serializedName: 'companyAddress',
        type: {
          name: 'String'
        }
      },
      officeNumber: {
        serializedName: 'officeNumber',
        type: {
          name: 'String'
        }
      },
      webAddress: {
        serializedName: 'webAddress',
        type: {
          name: 'String'
        }
      },
      accountPayableEmail: {
        serializedName: 'accountPayableEmail',
        type: {
          name: 'String'
        }
      },
      optionFirst: {
        serializedName: 'optionFirst',
        type: {
          name: 'Boolean'
        }
      },
      optionSecond: {
        serializedName: 'optionSecond',
        type: {
          name: 'Boolean'
        }
      },
      optionThird: {
        serializedName: 'optionThird',
        type: {
          name: 'Boolean'
        }
      },
      optionFourth: {
        serializedName: 'optionFourth',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const PartnerAchDetail: msRest.CompositeMapper = {
  serializedName: 'PartnerAchDetail',
  type: {
    name: 'Composite',
    className: 'PartnerAchDetail',
    modelProperties: {
      bankName: {
        serializedName: 'bankName',
        type: {
          name: 'String'
        }
      },
      accountType: {
        serializedName: 'accountType',
        type: {
          name: 'String'
        }
      },
      routingNumber: {
        serializedName: 'routingNumber',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PartnerAccreditationRequest: msRest.CompositeMapper = {
  serializedName: 'PartnerAccreditationRequest',
  type: {
    name: 'Composite',
    className: 'PartnerAccreditationRequest',
    modelProperties: {
      ownerDetails: {
        serializedName: 'ownerDetails',
        type: {
          name: 'Composite',
          className: 'PartnerOwnerDetails'
        }
      },
      businessDetails: {
        serializedName: 'businessDetails',
        type: {
          name: 'Composite',
          className: 'PartnerBusinessDetails'
        }
      },
      achDetail: {
        serializedName: 'achDetail',
        type: {
          name: 'Composite',
          className: 'PartnerAchDetail'
        }
      },
      companyDetail: {
        serializedName: 'companyDetail',
        type: {
          name: 'Object'
        }
      }
    }
  }
};

export const PartnerBankDetails: msRest.CompositeMapper = {
  serializedName: 'PartnerBankDetails',
  type: {
    name: 'Composite',
    className: 'PartnerBankDetails',
    modelProperties: {
      bankName: {
        serializedName: 'bankName',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      },
      routingNumber: {
        serializedName: 'routingNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PasswordResetModel: msRest.CompositeMapper = {
  serializedName: 'PasswordResetModel',
  type: {
    name: 'Composite',
    className: 'PasswordResetModel',
    modelProperties: {
      userName: {
        required: true,
        serializedName: 'userName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      password: {
        required: true,
        serializedName: 'password',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      passwordConfirmation: {
        required: true,
        serializedName: 'passwordConfirmation',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PaymentDetails: msRest.CompositeMapper = {
  serializedName: 'PaymentDetails',
  type: {
    name: 'Composite',
    className: 'PaymentDetails',
    modelProperties: {
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      paymentId: {
        serializedName: 'paymentId',
        type: {
          name: 'Uuid'
        }
      },
      syntheticPaymentId: {
        serializedName: 'syntheticPaymentId',
        type: {
          name: 'Uuid'
        }
      },
      paymentQueryId: {
        serializedName: 'paymentQueryId',
        type: {
          name: 'Uuid'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      transactionId: {
        serializedName: 'transactionId',
        type: {
          name: 'Uuid'
        }
      },
      paymentDate: {
        serializedName: 'paymentDate',
        type: {
          name: 'DateTime'
        }
      },
      paymentAmount: {
        serializedName: 'paymentAmount',
        type: {
          name: 'Number'
        }
      },
      paymentReference: {
        serializedName: 'paymentReference',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlatformAgreementSettings: msRest.CompositeMapper = {
  serializedName: 'PlatformAgreementSettings',
  type: {
    name: 'Composite',
    className: 'PlatformAgreementSettings',
    modelProperties: {
      platformAgreementHTML: {
        serializedName: 'platformAgreementHTML',
        type: {
          name: 'String'
        }
      },
      platformAgreementSignCondition1: {
        serializedName: 'platformAgreementSignCondition1',
        type: {
          name: 'String'
        }
      },
      platformAgreementSignCondition2: {
        serializedName: 'platformAgreementSignCondition2',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsTransactionMatrix: msRest.CompositeMapper = {
  serializedName: 'PlexeOrganisationServiceModelsTransactionMatrix',
  type: {
    name: 'Composite',
    className: 'PlexeOrganisationServiceModelsTransactionMatrix',
    modelProperties: {
      institutionName: {
        serializedName: 'institutionName',
        type: {
          name: 'String'
        }
      },
      accountName: {
        serializedName: 'accountName',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      },
      transactionDate: {
        serializedName: 'transactionDate',
        type: {
          name: 'DateTime'
        }
      },
      dateStr: {
        serializedName: 'dateStr',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      uploaded: {
        serializedName: 'uploaded',
        type: {
          name: 'Boolean'
        }
      },
      uploadedDate: {
        serializedName: 'uploadedDate',
        type: {
          name: 'DateTime'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      isDelete: {
        serializedName: 'isDelete',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const SecondaryApplicantRequested: msRest.CompositeMapper = {
  serializedName: 'SecondaryApplicantRequested',
  type: {
    name: 'Composite',
    className: 'SecondaryApplicantRequested',
    modelProperties: {
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PrimaryApplicantAndSecondaryApplicantRequested: msRest.CompositeMapper = {
  serializedName: 'PrimaryApplicantAndSecondaryApplicantRequested',
  type: {
    name: 'Composite',
    className: 'PrimaryApplicantAndSecondaryApplicantRequested',
    modelProperties: {
      secondaryApplicantRequested: {
        serializedName: 'secondaryApplicantRequested',
        type: {
          name: 'Composite',
          className: 'SecondaryApplicantRequested'
        }
      },
      primaryApplicant: {
        serializedName: 'primaryApplicant',
        type: {
          name: 'Composite',
          className: 'PrimaryApplicant'
        }
      }
    }
  }
};

export const QuestionMessageModal: msRest.CompositeMapper = {
  serializedName: 'QuestionMessageModal',
  type: {
    name: 'Composite',
    className: 'QuestionMessageModal',
    modelProperties: {
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      isReplied: {
        serializedName: 'isReplied',
        type: {
          name: 'Boolean'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      answer: {
        serializedName: 'answer',
        type: {
          name: 'Boolean'
        }
      },
      isActiveLoansOnly: {
        serializedName: 'isActiveLoansOnly',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const ReconciliationDetails: msRest.CompositeMapper = {
  serializedName: 'ReconciliationDetails',
  type: {
    name: 'Composite',
    className: 'ReconciliationDetails',
    modelProperties: {
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      transactionId: {
        serializedName: 'transactionId',
        type: {
          name: 'Uuid'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      account: {
        serializedName: 'account',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const RegisterCustomerRequest: msRest.CompositeMapper = {
  serializedName: 'RegisterCustomerRequest',
  type: {
    name: 'Composite',
    className: 'RegisterCustomerRequest',
    modelProperties: {
      customer: {
        serializedName: 'customer',
        type: {
          name: 'Composite',
          className: 'Customer'
        }
      }
    }
  }
};

export const RegisterInvestorRequest: msRest.CompositeMapper = {
  serializedName: 'RegisterInvestorRequest',
  type: {
    name: 'Composite',
    className: 'RegisterInvestorRequest',
    modelProperties: {
      investor: {
        serializedName: 'investor',
        type: {
          name: 'Composite',
          className: 'Investor'
        }
      }
    }
  }
};

export const RegisterPartnerRequest: msRest.CompositeMapper = {
  serializedName: 'RegisterPartnerRequest',
  type: {
    name: 'Composite',
    className: 'RegisterPartnerRequest',
    modelProperties: {
      partner: {
        serializedName: 'partner',
        type: {
          name: 'Composite',
          className: 'Partner'
        }
      }
    }
  }
};

export const RepaymentAdjustmentProjection: msRest.CompositeMapper = {
  serializedName: 'RepaymentAdjustmentProjection',
  type: {
    name: 'Composite',
    className: 'RepaymentAdjustmentProjection',
    modelProperties: {
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'Uuid'
        }
      },
      adjustmentType: {
        serializedName: 'adjustmentType',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      transactionDateUTC: {
        serializedName: 'transactionDateUTC',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const SecondaryApplicant: msRest.CompositeMapper = {
  serializedName: 'SecondaryApplicant',
  type: {
    name: 'Composite',
    className: 'SecondaryApplicant',
    modelProperties: {
      isManuallyAdded: {
        serializedName: 'isManuallyAdded',
        type: {
          name: 'Boolean'
        }
      },
      image: {
        serializedName: 'image',
        type: {
          name: 'ByteArray'
        }
      },
      miscellaneousData: {
        serializedName: 'miscellaneousData',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      address: {
        serializedName: 'address',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      licenceState: {
        serializedName: 'licenceState',
        type: {
          name: 'String'
        }
      },
      licenceNumber: {
        serializedName: 'licenceNumber',
        type: {
          name: 'String'
        }
      },
      licenceExpiryDate: {
        serializedName: 'licenceExpiryDate',
        type: {
          name: 'DateTime'
        }
      },
      licenceName: {
        serializedName: 'licenceName',
        type: {
          name: 'String'
        }
      },
      licenceAddress: {
        serializedName: 'licenceAddress',
        type: {
          name: 'String'
        }
      },
      medicareName: {
        serializedName: 'medicareName',
        type: {
          name: 'String'
        }
      },
      medicareDate: {
        serializedName: 'medicareDate',
        type: {
          name: 'DateTime'
        }
      },
      medicareNumber: {
        serializedName: 'medicareNumber',
        type: {
          name: 'String'
        }
      },
      medicareIssue: {
        serializedName: 'medicareIssue',
        type: {
          name: 'String'
        }
      },
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      licenceCity: {
        serializedName: 'licenceCity',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const SendIssueDefaultModel: msRest.CompositeMapper = {
  serializedName: 'SendIssueDefaultModel',
  type: {
    name: 'Composite',
    className: 'SendIssueDefaultModel',
    modelProperties: {
      sendEmail: {
        serializedName: 'sendEmail',
        type: {
          name: 'Boolean'
        }
      },
      sendSms: {
        serializedName: 'sendSms',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const SendLinkRequest: msRest.CompositeMapper = {
  serializedName: 'SendLinkRequest',
  type: {
    name: 'Composite',
    className: 'SendLinkRequest',
    modelProperties: {
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      ein: {
        serializedName: 'ein',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const SiteDetails: msRest.CompositeMapper = {
  serializedName: 'SiteDetails',
  type: {
    name: 'Composite',
    className: 'SiteDetails',
    modelProperties: {
      items: {
        serializedName: 'items',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      images: {
        serializedName: 'images',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      urls: {
        serializedName: 'urls',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      },
      returnUrl: {
        serializedName: 'returnUrl',
        type: {
          name: 'String'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      darkThemeEnabled: {
        serializedName: 'darkThemeEnabled',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const StringApiResponse: msRest.CompositeMapper = {
  serializedName: 'StringApiResponse',
  type: {
    name: 'Composite',
    className: 'StringApiResponse',
    modelProperties: {
      errors: {
        serializedName: 'errors',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ValidationError'
            }
          }
        }
      },
      data: {
        serializedName: 'data',
        type: {
          name: 'String'
        }
      },
      success: {
        serializedName: 'success',
        type: {
          name: 'Boolean'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const SystemMessageModal: msRest.CompositeMapper = {
  serializedName: 'SystemMessageModal',
  type: {
    name: 'Composite',
    className: 'SystemMessageModal',
    modelProperties: {
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const TransactionDataFile: msRest.CompositeMapper = {
  serializedName: 'TransactionDataFile',
  type: {
    name: 'Composite',
    className: 'TransactionDataFile',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'ByteArray'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      fileType: {
        serializedName: 'fileType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const TransactionDetail: msRest.CompositeMapper = {
  serializedName: 'TransactionDetail',
  type: {
    name: 'Composite',
    className: 'TransactionDetail',
    modelProperties: {
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'String'
        }
      },
      advanceName: {
        serializedName: 'advanceName',
        type: {
          name: 'String'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      reconciled: {
        serializedName: 'reconciled',
        type: {
          name: 'Boolean'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      batchId: {
        serializedName: 'batchId',
        type: {
          name: 'Uuid'
        }
      },
      batchDateUTC: {
        serializedName: 'batchDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      batchName: {
        serializedName: 'batchName',
        type: {
          name: 'String'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'String'
        }
      },
      bankReceipt: {
        serializedName: 'bankReceipt',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const TransactionProjection: msRest.CompositeMapper = {
  serializedName: 'TransactionProjection',
  type: {
    name: 'Composite',
    className: 'TransactionProjection',
    modelProperties: {
      overrideId: {
        serializedName: 'overrideId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'String'
        }
      },
      advanceName: {
        serializedName: 'advanceName',
        type: {
          name: 'String'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amountValue: {
        serializedName: 'amount_Value',
        type: {
          name: 'Number'
        }
      },
      amountCurrencyCode: {
        serializedName: 'amount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      balanceValue: {
        serializedName: 'balance_Value',
        type: {
          name: 'Number'
        }
      },
      balanceCurrencyCode: {
        serializedName: 'balance_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      reconciled: {
        serializedName: 'reconciled',
        type: {
          name: 'Boolean'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      batchName: {
        serializedName: 'batchName',
        type: {
          name: 'String'
        }
      },
      batchDate: {
        serializedName: 'batchDate',
        type: {
          name: 'DateTime'
        }
      },
      batchId: {
        serializedName: 'batchId',
        type: {
          name: 'Uuid'
        }
      },
      isCredit: {
        serializedName: 'isCredit',
        type: {
          name: 'Boolean'
        }
      },
      bankReceipt: {
        serializedName: 'bankReceipt',
        type: {
          name: 'String'
        }
      },
      force: {
        serializedName: 'force',
        type: {
          name: 'Boolean'
        }
      },
      typeDisplay: {
        readOnly: true,
        serializedName: 'typeDisplay',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Trust: msRest.CompositeMapper = {
  serializedName: 'Trust',
  type: {
    name: 'Composite',
    className: 'Trust',
    modelProperties: {
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      surname: {
        serializedName: 'surname',
        type: {
          name: 'String'
        }
      },
      isCompanyTrust: {
        serializedName: 'isCompanyTrust',
        type: {
          name: 'Boolean'
        }
      },
      acn: {
        serializedName: 'acn',
        type: {
          name: 'String'
        }
      },
      companyName: {
        serializedName: 'companyName',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const UpdateApplicationRequest: msRest.CompositeMapper = {
  serializedName: 'UpdateApplicationRequest',
  type: {
    name: 'Composite',
    className: 'UpdateApplicationRequest',
    modelProperties: {
      applicationId: {
        required: true,
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      abn: {
        required: true,
        serializedName: 'abn',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      businessName: {
        required: true,
        serializedName: 'businessName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      mobile: {
        required: true,
        serializedName: 'mobile',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      email: {
        required: true,
        serializedName: 'email',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      offerId: {
        serializedName: 'offerId',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const WithdrawFixedRequest: msRest.CompositeMapper = {
  serializedName: 'WithdrawFixedRequest',
  type: {
    name: 'Composite',
    className: 'WithdrawFixedRequest',
    modelProperties: {
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      term: {
        serializedName: 'term',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      weeklyPayment: {
        serializedName: 'weeklyPayment',
        type: {
          name: 'Number'
        }
      },
      totalRepaymentAmount: {
        serializedName: 'totalRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentDate: {
        serializedName: 'nextRepaymentDate',
        type: {
          name: 'DateTime'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      otp: {
        serializedName: 'otp',
        type: {
          name: 'String'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      commission: {
        serializedName: 'commission',
        type: {
          name: 'Composite',
          className: 'CommissionRequest'
        }
      },
      ipAddress: {
        serializedName: 'ipAddress',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const WithdrawInvoiceRequest: msRest.CompositeMapper = {
  serializedName: 'WithdrawInvoiceRequest',
  type: {
    name: 'Composite',
    className: 'WithdrawInvoiceRequest',
    modelProperties: {
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentDate: {
        serializedName: 'nextRepaymentDate',
        type: {
          name: 'DateTime'
        }
      },
      otp: {
        serializedName: 'otp',
        type: {
          name: 'String'
        }
      },
      commission: {
        serializedName: 'commission',
        type: {
          name: 'Composite',
          className: 'CommissionRequest'
        }
      },
      ipAddress: {
        serializedName: 'ipAddress',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const WithdrawLineOfCreditRequest: msRest.CompositeMapper = {
  serializedName: 'WithdrawLineOfCreditRequest',
  type: {
    name: 'Composite',
    className: 'WithdrawLineOfCreditRequest',
    modelProperties: {
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      percentageOfIncome: {
        serializedName: 'percentageOfIncome',
        type: {
          name: 'Number'
        }
      },
      weeklyPayment: {
        serializedName: 'weeklyPayment',
        type: {
          name: 'Number'
        }
      },
      totalRepaymentAmount: {
        serializedName: 'totalRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentDate: {
        serializedName: 'nextRepaymentDate',
        type: {
          name: 'DateTime'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      otp: {
        serializedName: 'otp',
        type: {
          name: 'String'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      commission: {
        serializedName: 'commission',
        type: {
          name: 'Composite',
          className: 'CommissionRequest'
        }
      },
      fixed: {
        serializedName: 'fixed',
        type: {
          name: 'Boolean'
        }
      },
      terms: {
        serializedName: 'terms',
        type: {
          name: 'Number'
        }
      },
      fixedRepaymentFee: {
        serializedName: 'fixedRepaymentFee',
        type: {
          name: 'Number'
        }
      },
      fixedRepayment: {
        serializedName: 'fixedRepayment',
        type: {
          name: 'Number'
        }
      },
      ipAddress: {
        serializedName: 'ipAddress',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const WithdrawPercentageRequest: msRest.CompositeMapper = {
  serializedName: 'WithdrawPercentageRequest',
  type: {
    name: 'Composite',
    className: 'WithdrawPercentageRequest',
    modelProperties: {
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      percentage: {
        serializedName: 'percentage',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentDate: {
        serializedName: 'nextRepaymentDate',
        type: {
          name: 'DateTime'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      totalRepaymentAmount: {
        serializedName: 'totalRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      otp: {
        serializedName: 'otp',
        type: {
          name: 'String'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      commission: {
        serializedName: 'commission',
        type: {
          name: 'Composite',
          className: 'CommissionRequest'
        }
      }
    }
  }
};
