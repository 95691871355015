/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from '@azure/ms-rest-js';

export const AdvanceAdjustmentProjection: msRest.CompositeMapper = {
  serializedName: 'AdvanceAdjustmentProjection',
  type: {
    name: 'Composite',
    className: 'AdvanceAdjustmentProjection',
    modelProperties: {
      endDate: {
        serializedName: 'endDate',
        type: {
          name: 'DateTime'
        }
      },
      principalOnly: {
        serializedName: 'principalOnly',
        type: {
          name: 'Boolean'
        }
      },
      adjustment: {
        serializedName: 'adjustment',
        type: {
          name: 'Boolean'
        }
      },
      partnerAdvanceId: {
        serializedName: 'partnerAdvanceId',
        type: {
          name: 'Uuid'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amountValue: {
        serializedName: 'amount_Value',
        type: {
          name: 'Number'
        }
      },
      amountCurrencyCode: {
        serializedName: 'amount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      remainingValue: {
        serializedName: 'remaining_Value',
        type: {
          name: 'Number'
        }
      },
      remainingCurrencyCode: {
        serializedName: 'remaining_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentValueValue: {
        serializedName: 'nextRepaymentValue_Value',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentValueCurrencyCode: {
        serializedName: 'nextRepaymentValue_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDateUTC: {
        serializedName: 'nextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDay: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDay: {
        readOnly: true,
        serializedName: 'nextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDay: {
        readOnly: true,
        serializedName: 'lastRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'lastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      totalRepaymentAmount: {
        serializedName: 'totalRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      weeklyPayment: {
        serializedName: 'weeklyPayment',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      terms: {
        serializedName: 'terms',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      percentage: {
        serializedName: 'percentage',
        type: {
          name: 'Number'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      name: {
        readOnly: true,
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      earlyRepaid: {
        serializedName: 'earlyRepaid',
        type: {
          name: 'Boolean'
        }
      },
      earlyRepaidDate: {
        serializedName: 'earlyRepaidDate',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepaymentDateUTC: {
        serializedName: 'overrideLastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepayment: {
        serializedName: 'overrideLastRepayment',
        type: {
          name: 'Boolean'
        }
      },
      legacy: {
        serializedName: 'legacy',
        type: {
          name: 'Boolean'
        }
      },
      monthlyFee: {
        serializedName: 'monthlyFee',
        type: {
          name: 'Boolean'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionAgentFee: {
        serializedName: 'commission_AgentFee',
        type: {
          name: 'Number'
        }
      },
      fixed: {
        serializedName: 'fixed',
        type: {
          name: 'Boolean'
        }
      },
      fixedRepayment: {
        serializedName: 'fixedRepayment',
        type: {
          name: 'Number'
        }
      },
      fixedRepaymentFee: {
        serializedName: 'fixedRepaymentFee',
        type: {
          name: 'Number'
        }
      },
      principleBalance: {
        serializedName: 'principleBalance',
        type: {
          name: 'Number'
        }
      },
      accruedBalance: {
        serializedName: 'accruedBalance',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AdvanceAdjustmentProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'AdvanceAdjustmentProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'AdvanceAdjustmentProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AdvanceAdjustmentProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AdvanceFullDetail: msRest.CompositeMapper = {
  serializedName: 'AdvanceFullDetail',
  type: {
    name: 'Composite',
    className: 'AdvanceFullDetail',
    modelProperties: {
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      agentName: {
        serializedName: 'agentName',
        type: {
          name: 'String'
        }
      },
      partnerName: {
        serializedName: 'partnerName',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amountValue: {
        serializedName: 'amount_Value',
        type: {
          name: 'Number'
        }
      },
      amountCurrencyCode: {
        serializedName: 'amount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      remainingValue: {
        serializedName: 'remaining_Value',
        type: {
          name: 'Number'
        }
      },
      remainingCurrencyCode: {
        serializedName: 'remaining_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentValueValue: {
        serializedName: 'nextRepaymentValue_Value',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentValueCurrencyCode: {
        serializedName: 'nextRepaymentValue_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDateUTC: {
        serializedName: 'nextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDay: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDay: {
        readOnly: true,
        serializedName: 'nextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDay: {
        readOnly: true,
        serializedName: 'lastRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'lastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      totalRepaymentAmount: {
        serializedName: 'totalRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      weeklyPayment: {
        serializedName: 'weeklyPayment',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      terms: {
        serializedName: 'terms',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      percentage: {
        serializedName: 'percentage',
        type: {
          name: 'Number'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      name: {
        readOnly: true,
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      earlyRepaid: {
        serializedName: 'earlyRepaid',
        type: {
          name: 'Boolean'
        }
      },
      earlyRepaidDate: {
        serializedName: 'earlyRepaidDate',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepaymentDateUTC: {
        serializedName: 'overrideLastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepayment: {
        serializedName: 'overrideLastRepayment',
        type: {
          name: 'Boolean'
        }
      },
      legacy: {
        serializedName: 'legacy',
        type: {
          name: 'Boolean'
        }
      },
      monthlyFee: {
        serializedName: 'monthlyFee',
        type: {
          name: 'Boolean'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionAgentFee: {
        serializedName: 'commission_AgentFee',
        type: {
          name: 'Number'
        }
      },
      fixed: {
        serializedName: 'fixed',
        type: {
          name: 'Boolean'
        }
      },
      fixedRepayment: {
        serializedName: 'fixedRepayment',
        type: {
          name: 'Number'
        }
      },
      fixedRepaymentFee: {
        serializedName: 'fixedRepaymentFee',
        type: {
          name: 'Number'
        }
      },
      principleBalance: {
        serializedName: 'principleBalance',
        type: {
          name: 'Number'
        }
      },
      accruedBalance: {
        serializedName: 'accruedBalance',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AdvanceFullDetailPagedList: msRest.CompositeMapper = {
  serializedName: 'AdvanceFullDetailPagedList',
  type: {
    name: 'Composite',
    className: 'AdvanceFullDetailPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AdvanceFullDetail'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AdvanceProjection: msRest.CompositeMapper = {
  serializedName: 'AdvanceProjection',
  type: {
    name: 'Composite',
    className: 'AdvanceProjection',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amountValue: {
        serializedName: 'amount_Value',
        type: {
          name: 'Number'
        }
      },
      amountCurrencyCode: {
        serializedName: 'amount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      remainingValue: {
        serializedName: 'remaining_Value',
        type: {
          name: 'Number'
        }
      },
      remainingCurrencyCode: {
        serializedName: 'remaining_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentValueValue: {
        serializedName: 'nextRepaymentValue_Value',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentValueCurrencyCode: {
        serializedName: 'nextRepaymentValue_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDateUTC: {
        serializedName: 'nextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDay: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDay: {
        readOnly: true,
        serializedName: 'nextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDay: {
        readOnly: true,
        serializedName: 'lastRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'lastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      totalRepaymentAmount: {
        serializedName: 'totalRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      weeklyPayment: {
        serializedName: 'weeklyPayment',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      terms: {
        serializedName: 'terms',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      percentage: {
        serializedName: 'percentage',
        type: {
          name: 'Number'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      name: {
        readOnly: true,
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      earlyRepaid: {
        serializedName: 'earlyRepaid',
        type: {
          name: 'Boolean'
        }
      },
      earlyRepaidDate: {
        serializedName: 'earlyRepaidDate',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepaymentDateUTC: {
        serializedName: 'overrideLastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepayment: {
        serializedName: 'overrideLastRepayment',
        type: {
          name: 'Boolean'
        }
      },
      legacy: {
        serializedName: 'legacy',
        type: {
          name: 'Boolean'
        }
      },
      monthlyFee: {
        serializedName: 'monthlyFee',
        type: {
          name: 'Boolean'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionAgentFee: {
        serializedName: 'commission_AgentFee',
        type: {
          name: 'Number'
        }
      },
      fixed: {
        serializedName: 'fixed',
        type: {
          name: 'Boolean'
        }
      },
      fixedRepayment: {
        serializedName: 'fixedRepayment',
        type: {
          name: 'Number'
        }
      },
      fixedRepaymentFee: {
        serializedName: 'fixedRepaymentFee',
        type: {
          name: 'Number'
        }
      },
      principleBalance: {
        serializedName: 'principleBalance',
        type: {
          name: 'Number'
        }
      },
      accruedBalance: {
        serializedName: 'accruedBalance',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AdvanceProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'AdvanceProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'AdvanceProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AdvanceProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AgentNotes: msRest.CompositeMapper = {
  serializedName: 'AgentNotes',
  type: {
    name: 'Composite',
    className: 'AgentNotes',
    modelProperties: {
      note: {
        serializedName: 'note',
        type: {
          name: 'String'
        }
      },
      noteId: {
        serializedName: 'noteId',
        type: {
          name: 'Uuid'
        }
      },
      noteCreated: {
        serializedName: 'noteCreated',
        type: {
          name: 'DateTime'
        }
      },
      author: {
        serializedName: 'author',
        type: {
          name: 'String'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const Agent: msRest.CompositeMapper = {
  serializedName: 'Agent',
  type: {
    name: 'Composite',
    className: 'Agent',
    modelProperties: {
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      name: {
        readOnly: true,
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      partnerBusinessName: {
        serializedName: 'partnerBusinessName',
        type: {
          name: 'String'
        }
      },
      partnerName: {
        serializedName: 'partnerName',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'Boolean'
        }
      },
      compliance: {
        serializedName: 'compliance',
        type: {
          name: 'Boolean'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AgentNotes'
            }
          }
        }
      },
      deleted: {
        serializedName: 'deleted',
        type: {
          name: 'Boolean'
        }
      },
      deletedDate: {
        serializedName: 'deletedDate',
        type: {
          name: 'DateTime'
        }
      },
      lockoutEnabled: {
        serializedName: 'lockoutEnabled',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const Alert: msRest.CompositeMapper = {
  serializedName: 'Alert',
  type: {
    name: 'Composite',
    className: 'Alert',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const AlertNotificationEmailCategoryProjection: msRest.CompositeMapper = {
  serializedName: 'AlertNotificationEmailCategoryProjection',
  type: {
    name: 'Composite',
    className: 'AlertNotificationEmailCategoryProjection',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Number'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      classification: {
        serializedName: 'classification',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const AlertNotificationProjection: msRest.CompositeMapper = {
  serializedName: 'AlertNotificationProjection',
  type: {
    name: 'Composite',
    className: 'AlertNotificationProjection',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Number'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      isRead: {
        serializedName: 'isRead',
        type: {
          name: 'Boolean'
        }
      },
      classification: {
        serializedName: 'classification',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const LoanBalances: msRest.CompositeMapper = {
  serializedName: 'LoanBalances',
  type: {
    name: 'Composite',
    className: 'LoanBalances',
    modelProperties: {
      availableFunds: {
        readOnly: true,
        serializedName: 'availableFunds',
        type: {
          name: 'Number'
        }
      },
      systemPricing: {
        serializedName: 'systemPricing',
        type: {
          name: 'Number'
        }
      },
      balance: {
        readOnly: true,
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      principleBalance: {
        serializedName: 'principleBalance',
        type: {
          name: 'Number'
        }
      },
      transactionBalance: {
        serializedName: 'transactionBalance',
        type: {
          name: 'Number'
        }
      },
      amortised: {
        serializedName: 'amortised',
        type: {
          name: 'Boolean'
        }
      },
      totalLimit: {
        readOnly: true,
        serializedName: 'totalLimit',
        type: {
          name: 'Number'
        }
      },
      limits: {
        serializedName: 'limits',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Number'
            }
          }
        }
      },
      rawTotal: {
        serializedName: 'rawTotal',
        type: {
          name: 'Number'
        }
      },
      adjustment: {
        serializedName: 'adjustment',
        type: {
          name: 'Number'
        }
      },
      totalDebtors: {
        serializedName: 'totalDebtors',
        type: {
          name: 'Number'
        }
      },
      totalFundingRate: {
        serializedName: 'totalFundingRate',
        type: {
          name: 'Number'
        }
      },
      utilisationPercentage: {
        readOnly: true,
        serializedName: 'utilisationPercentage',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AllLoanBalances: msRest.CompositeMapper = {
  serializedName: 'AllLoanBalances',
  type: {
    name: 'Composite',
    className: 'AllLoanBalances',
    modelProperties: {
      all: {
        serializedName: 'all',
        type: {
          name: 'Composite',
          className: 'LoanBalances'
        }
      },
      fixed: {
        serializedName: 'fixed',
        type: {
          name: 'Composite',
          className: 'LoanBalances'
        }
      },
      invoice: {
        serializedName: 'invoice',
        type: {
          name: 'Composite',
          className: 'LoanBalances'
        }
      },
      percentage: {
        serializedName: 'percentage',
        type: {
          name: 'Composite',
          className: 'LoanBalances'
        }
      },
      lineOfCredit: {
        serializedName: 'lineOfCredit',
        type: {
          name: 'Composite',
          className: 'LoanBalances'
        }
      },
      creditCard: {
        serializedName: 'creditCard',
        type: {
          name: 'Composite',
          className: 'LoanBalances'
        }
      }
    }
  }
};

export const Amount: msRest.CompositeMapper = {
  serializedName: 'Amount',
  type: {
    name: 'Composite',
    className: 'Amount',
    modelProperties: {
      value: {
        serializedName: 'value',
        type: {
          name: 'Number'
        }
      },
      currencyCode: {
        serializedName: 'currencyCode',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Announcement: msRest.CompositeMapper = {
  serializedName: 'Announcement',
  type: {
    name: 'Composite',
    className: 'Announcement',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      forAllPartners: {
        serializedName: 'forAllPartners',
        type: {
          name: 'Boolean'
        }
      },
      forAllAgents: {
        serializedName: 'forAllAgents',
        type: {
          name: 'Boolean'
        }
      },
      partnersOrAgents: {
        serializedName: 'partnersOrAgents',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Uuid'
            }
          }
        }
      }
    }
  }
};

export const ApplicantProjection: msRest.CompositeMapper = {
  serializedName: 'ApplicantProjection',
  type: {
    name: 'Composite',
    className: 'ApplicantProjection',
    modelProperties: {
      image: {
        serializedName: 'image',
        type: {
          name: 'ByteArray'
        }
      },
      miscellaneousData: {
        serializedName: 'miscellaneousData',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      address: {
        serializedName: 'address',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      licenceState: {
        serializedName: 'licenceState',
        type: {
          name: 'String'
        }
      },
      licenceNumber: {
        serializedName: 'licenceNumber',
        type: {
          name: 'String'
        }
      },
      licenceExpiryDate: {
        serializedName: 'licenceExpiryDate',
        type: {
          name: 'DateTime'
        }
      },
      licenceName: {
        serializedName: 'licenceName',
        type: {
          name: 'String'
        }
      },
      licenceAddress: {
        serializedName: 'licenceAddress',
        type: {
          name: 'String'
        }
      },
      medicareName: {
        serializedName: 'medicareName',
        type: {
          name: 'String'
        }
      },
      medicareDate: {
        serializedName: 'medicareDate',
        type: {
          name: 'DateTime'
        }
      },
      medicareNumber: {
        serializedName: 'medicareNumber',
        type: {
          name: 'String'
        }
      },
      medicareIssue: {
        serializedName: 'medicareIssue',
        type: {
          name: 'String'
        }
      },
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      licenceCity: {
        serializedName: 'licenceCity',
        type: {
          name: 'String'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const ApplicationListProjection: msRest.CompositeMapper = {
  serializedName: 'ApplicationListProjection',
  type: {
    name: 'Composite',
    className: 'ApplicationListProjection',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      customerFullName: {
        serializedName: 'customerFullName',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      cancelledReason: {
        serializedName: 'cancelledReason',
        type: {
          name: 'String'
        }
      },
      creditType: {
        serializedName: 'creditType',
        type: {
          name: 'String'
        }
      },
      salesPerson: {
        serializedName: 'salesPerson',
        type: {
          name: 'String'
        }
      },
      abn: {
        serializedName: 'abn',
        type: {
          name: 'String'
        }
      },
      lastUpdated: {
        serializedName: 'lastUpdated',
        type: {
          name: 'DateTime'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      offerCreatedDate: {
        serializedName: 'offerCreatedDate',
        type: {
          name: 'DateTime'
        }
      },
      offerReadyDate: {
        serializedName: 'offerReadyDate',
        type: {
          name: 'DateTime'
        }
      },
      cancelledDate: {
        serializedName: 'cancelledDate',
        type: {
          name: 'DateTime'
        }
      },
      organisationId: {
        serializedName: 'organisationId',
        type: {
          name: 'Uuid'
        }
      },
      offerId: {
        serializedName: 'offerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      hasLoggedIn: {
        serializedName: 'hasLoggedIn',
        type: {
          name: 'Boolean'
        }
      },
      countryCode: {
        serializedName: 'countryCode',
        type: {
          name: 'String'
        }
      },
      fullBusinessName: {
        serializedName: 'fullBusinessName',
        type: {
          name: 'String'
        }
      },
      secured: {
        serializedName: 'secured',
        type: {
          name: 'Boolean'
        }
      },
      offerAccepted: {
        serializedName: 'offerAccepted',
        type: {
          name: 'Boolean'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'String'
        }
      },
      accountingConnected: {
        serializedName: 'accountingConnected',
        type: {
          name: 'Boolean'
        }
      },
      accountingSkipped: {
        serializedName: 'accountingSkipped',
        type: {
          name: 'Boolean'
        }
      },
      bankingConnected: {
        serializedName: 'bankingConnected',
        type: {
          name: 'Boolean'
        }
      },
      bankingSkipped: {
        serializedName: 'bankingSkipped',
        type: {
          name: 'Boolean'
        }
      },
      contactEmail: {
        serializedName: 'contactEmail',
        type: {
          name: 'Boolean'
        }
      },
      contactSms: {
        serializedName: 'contactSms',
        type: {
          name: 'Boolean'
        }
      },
      contractIPAddress: {
        serializedName: 'contractIPAddress',
        type: {
          name: 'String'
        }
      },
      contractSignatureLocation: {
        serializedName: 'contractSignatureLocation',
        type: {
          name: 'String'
        }
      },
      contractSignature2Location: {
        serializedName: 'contractSignature2Location',
        type: {
          name: 'String'
        }
      },
      contractSignatureLocationSecondary: {
        serializedName: 'contractSignatureLocationSecondary',
        type: {
          name: 'String'
        }
      },
      contractSignature2LocationSecondary: {
        serializedName: 'contractSignature2LocationSecondary',
        type: {
          name: 'String'
        }
      },
      contractSignatureDate: {
        serializedName: 'contractSignatureDate',
        type: {
          name: 'String'
        }
      },
      contractSignature2Date: {
        serializedName: 'contractSignature2Date',
        type: {
          name: 'String'
        }
      },
      contractSignatureSecondaryDate: {
        serializedName: 'contractSignatureSecondaryDate',
        type: {
          name: 'String'
        }
      },
      contractSignature2SecondaryDate: {
        serializedName: 'contractSignature2SecondaryDate',
        type: {
          name: 'String'
        }
      },
      contractIPAddressSecondary: {
        serializedName: 'contractIPAddressSecondary',
        type: {
          name: 'String'
        }
      },
      secondaryApplicantRequested: {
        serializedName: 'secondaryApplicantRequested',
        type: {
          name: 'Boolean'
        }
      },
      primaryApplicantAdded: {
        serializedName: 'primaryApplicantAdded',
        type: {
          name: 'Boolean'
        }
      },
      secondaryApplicantAdded: {
        serializedName: 'secondaryApplicantAdded',
        type: {
          name: 'Boolean'
        }
      },
      customerIds: {
        serializedName: 'customerIds',
        type: {
          name: 'String'
        }
      },
      quickUrl: {
        serializedName: 'quickUrl',
        type: {
          name: 'String'
        }
      },
      previousName: {
        serializedName: 'previousName',
        type: {
          name: 'String'
        }
      },
      totalAmountValue: {
        serializedName: 'totalAmount_Value',
        type: {
          name: 'Number'
        }
      },
      totalAmountCurrencyCode: {
        serializedName: 'totalAmount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      availableCreditValue: {
        serializedName: 'availableCredit_Value',
        type: {
          name: 'Number'
        }
      },
      availableCreditCurrencyCode: {
        serializedName: 'availableCredit_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      securedCommissionUpfrontFee: {
        serializedName: 'securedCommission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      securedCommissionTrailing: {
        serializedName: 'securedCommission_Trailing',
        type: {
          name: 'Number'
        }
      },
      securedCommissionDrawFee: {
        serializedName: 'securedCommission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionUpfrontFee: {
        serializedName: 'lineOfCreditCommission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionTrailing: {
        serializedName: 'lineOfCreditCommission_Trailing',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionDrawFee: {
        serializedName: 'lineOfCreditCommission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      mobileNumber: {
        serializedName: 'mobileNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const ApplicationListProjectionExtension: msRest.CompositeMapper = {
  serializedName: 'ApplicationListProjectionExtension',
  type: {
    name: 'Composite',
    className: 'ApplicationListProjectionExtension',
    modelProperties: {
      availableFunds: {
        serializedName: 'availableFunds',
        type: {
          name: 'Number'
        }
      },
      currentBalance: {
        serializedName: 'currentBalance',
        type: {
          name: 'Number'
        }
      },
      activated: {
        serializedName: 'activated',
        type: {
          name: 'Boolean'
        }
      },
      disabledReasons: {
        serializedName: 'disabledReasons',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      customerFullName: {
        serializedName: 'customerFullName',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      cancelledReason: {
        serializedName: 'cancelledReason',
        type: {
          name: 'String'
        }
      },
      creditType: {
        serializedName: 'creditType',
        type: {
          name: 'String'
        }
      },
      salesPerson: {
        serializedName: 'salesPerson',
        type: {
          name: 'String'
        }
      },
      abn: {
        serializedName: 'abn',
        type: {
          name: 'String'
        }
      },
      lastUpdated: {
        serializedName: 'lastUpdated',
        type: {
          name: 'DateTime'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      offerCreatedDate: {
        serializedName: 'offerCreatedDate',
        type: {
          name: 'DateTime'
        }
      },
      offerReadyDate: {
        serializedName: 'offerReadyDate',
        type: {
          name: 'DateTime'
        }
      },
      cancelledDate: {
        serializedName: 'cancelledDate',
        type: {
          name: 'DateTime'
        }
      },
      organisationId: {
        serializedName: 'organisationId',
        type: {
          name: 'Uuid'
        }
      },
      offerId: {
        serializedName: 'offerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      hasLoggedIn: {
        serializedName: 'hasLoggedIn',
        type: {
          name: 'Boolean'
        }
      },
      countryCode: {
        serializedName: 'countryCode',
        type: {
          name: 'String'
        }
      },
      fullBusinessName: {
        serializedName: 'fullBusinessName',
        type: {
          name: 'String'
        }
      },
      secured: {
        serializedName: 'secured',
        type: {
          name: 'Boolean'
        }
      },
      offerAccepted: {
        serializedName: 'offerAccepted',
        type: {
          name: 'Boolean'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'String'
        }
      },
      accountingConnected: {
        serializedName: 'accountingConnected',
        type: {
          name: 'Boolean'
        }
      },
      accountingSkipped: {
        serializedName: 'accountingSkipped',
        type: {
          name: 'Boolean'
        }
      },
      bankingConnected: {
        serializedName: 'bankingConnected',
        type: {
          name: 'Boolean'
        }
      },
      bankingSkipped: {
        serializedName: 'bankingSkipped',
        type: {
          name: 'Boolean'
        }
      },
      contactEmail: {
        serializedName: 'contactEmail',
        type: {
          name: 'Boolean'
        }
      },
      contactSms: {
        serializedName: 'contactSms',
        type: {
          name: 'Boolean'
        }
      },
      contractIPAddress: {
        serializedName: 'contractIPAddress',
        type: {
          name: 'String'
        }
      },
      contractSignatureLocation: {
        serializedName: 'contractSignatureLocation',
        type: {
          name: 'String'
        }
      },
      contractSignature2Location: {
        serializedName: 'contractSignature2Location',
        type: {
          name: 'String'
        }
      },
      contractSignatureLocationSecondary: {
        serializedName: 'contractSignatureLocationSecondary',
        type: {
          name: 'String'
        }
      },
      contractSignature2LocationSecondary: {
        serializedName: 'contractSignature2LocationSecondary',
        type: {
          name: 'String'
        }
      },
      contractSignatureDate: {
        serializedName: 'contractSignatureDate',
        type: {
          name: 'String'
        }
      },
      contractSignature2Date: {
        serializedName: 'contractSignature2Date',
        type: {
          name: 'String'
        }
      },
      contractSignatureSecondaryDate: {
        serializedName: 'contractSignatureSecondaryDate',
        type: {
          name: 'String'
        }
      },
      contractSignature2SecondaryDate: {
        serializedName: 'contractSignature2SecondaryDate',
        type: {
          name: 'String'
        }
      },
      contractIPAddressSecondary: {
        serializedName: 'contractIPAddressSecondary',
        type: {
          name: 'String'
        }
      },
      secondaryApplicantRequested: {
        serializedName: 'secondaryApplicantRequested',
        type: {
          name: 'Boolean'
        }
      },
      primaryApplicantAdded: {
        serializedName: 'primaryApplicantAdded',
        type: {
          name: 'Boolean'
        }
      },
      secondaryApplicantAdded: {
        serializedName: 'secondaryApplicantAdded',
        type: {
          name: 'Boolean'
        }
      },
      customerIds: {
        serializedName: 'customerIds',
        type: {
          name: 'String'
        }
      },
      quickUrl: {
        serializedName: 'quickUrl',
        type: {
          name: 'String'
        }
      },
      previousName: {
        serializedName: 'previousName',
        type: {
          name: 'String'
        }
      },
      totalAmountValue: {
        serializedName: 'totalAmount_Value',
        type: {
          name: 'Number'
        }
      },
      totalAmountCurrencyCode: {
        serializedName: 'totalAmount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      availableCreditValue: {
        serializedName: 'availableCredit_Value',
        type: {
          name: 'Number'
        }
      },
      availableCreditCurrencyCode: {
        serializedName: 'availableCredit_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      securedCommissionUpfrontFee: {
        serializedName: 'securedCommission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      securedCommissionTrailing: {
        serializedName: 'securedCommission_Trailing',
        type: {
          name: 'Number'
        }
      },
      securedCommissionDrawFee: {
        serializedName: 'securedCommission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionUpfrontFee: {
        serializedName: 'lineOfCreditCommission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionTrailing: {
        serializedName: 'lineOfCreditCommission_Trailing',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionDrawFee: {
        serializedName: 'lineOfCreditCommission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      mobileNumber: {
        serializedName: 'mobileNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const ApplicationListProjectionExtensionPagedList: msRest.CompositeMapper = {
  serializedName: 'ApplicationListProjectionExtensionPagedList',
  type: {
    name: 'Composite',
    className: 'ApplicationListProjectionExtensionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicationListProjectionExtension'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ApplicationListProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'ApplicationListProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'ApplicationListProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicationListProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ApplicationProcessStatus: msRest.CompositeMapper = {
  serializedName: 'ApplicationProcessStatus',
  type: {
    name: 'Composite',
    className: 'ApplicationProcessStatus',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      percentageComplete: {
        serializedName: 'percentageComplete',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ApplicationSettings: msRest.CompositeMapper = {
  serializedName: 'ApplicationSettings',
  type: {
    name: 'Composite',
    className: 'ApplicationSettings',
    modelProperties: {
      fixedRepayment: {
        serializedName: 'fixedRepayment',
        type: {
          name: 'Boolean'
        }
      },
      percentOfIncome: {
        serializedName: 'percentOfIncome',
        type: {
          name: 'Boolean'
        }
      },
      invoicePayment: {
        serializedName: 'invoicePayment',
        type: {
          name: 'Boolean'
        }
      },
      lineOfCreditPayment: {
        serializedName: 'lineOfCreditPayment',
        type: {
          name: 'Boolean'
        }
      },
      creditCardPayment: {
        serializedName: 'creditCardPayment',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const ApplicationsOverview: msRest.CompositeMapper = {
  serializedName: 'ApplicationsOverview',
  type: {
    name: 'Composite',
    className: 'ApplicationsOverview',
    modelProperties: {
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      totalAmountApproved: {
        serializedName: 'totalAmountApproved',
        type: {
          name: 'Number'
        }
      },
      totalAplicationsApproved: {
        serializedName: 'totalAplicationsApproved',
        type: {
          name: 'Number'
        }
      },
      totalAmountPending: {
        serializedName: 'totalAmountPending',
        type: {
          name: 'Number'
        }
      },
      totalApplicationsPending: {
        serializedName: 'totalApplicationsPending',
        type: {
          name: 'Number'
        }
      },
      totalCommissionsPaid: {
        serializedName: 'totalCommissionsPaid',
        type: {
          name: 'Number'
        }
      },
      totalCommissionsDeals: {
        serializedName: 'totalCommissionsDeals',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const Tag: msRest.CompositeMapper = {
  serializedName: 'Tag',
  type: {
    name: 'Composite',
    className: 'Tag',
    modelProperties: {
      creditDebit: {
        serializedName: 'creditDebit',
        type: {
          name: 'String'
        }
      },
      pending: {
        serializedName: 'pending',
        type: {
          name: 'String'
        }
      },
      original: {
        serializedName: 'original',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Transaction: msRest.CompositeMapper = {
  serializedName: 'Transaction',
  type: {
    name: 'Composite',
    className: 'Transaction',
    modelProperties: {
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      realAmount: {
        readOnly: true,
        serializedName: 'realAmount',
        type: {
          name: 'Number'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      text: {
        serializedName: 'text',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      tags: {
        serializedName: 'tags',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Tag'
            }
          }
        }
      },
      catgeory: {
        serializedName: 'catgeory',
        type: {
          name: 'String'
        }
      },
      textSize: {
        readOnly: true,
        serializedName: 'textSize',
        type: {
          name: 'Number'
        }
      },
      tagSize: {
        readOnly: true,
        serializedName: 'tagSize',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const BankAccount: msRest.CompositeMapper = {
  serializedName: 'BankAccount',
  type: {
    name: 'Composite',
    className: 'BankAccount',
    modelProperties: {
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      selected: {
        serializedName: 'selected',
        type: {
          name: 'Boolean'
        }
      },
      transactions: {
        serializedName: 'transactions',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Transaction'
            }
          }
        }
      },
      recentTransaction: {
        serializedName: 'recentTransaction',
        type: {
          name: 'Composite',
          className: 'Transaction'
        }
      },
      bank: {
        serializedName: 'bank',
        type: {
          name: 'String'
        }
      },
      institutionId: {
        serializedName: 'institutionId',
        type: {
          name: 'String'
        }
      },
      institutionName: {
        serializedName: 'institutionName',
        type: {
          name: 'String'
        }
      },
      accountName: {
        serializedName: 'accountName',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      bsb: {
        serializedName: 'bsb',
        type: {
          name: 'String'
        }
      },
      currentBalance: {
        serializedName: 'currentBalance',
        type: {
          name: 'String'
        }
      },
      availableBalance: {
        serializedName: 'availableBalance',
        type: {
          name: 'String'
        }
      },
      accountHolder: {
        serializedName: 'accountHolder',
        type: {
          name: 'String'
        }
      },
      accountType: {
        serializedName: 'accountType',
        type: {
          name: 'String'
        }
      },
      slug: {
        serializedName: 'slug',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'Boolean'
        }
      },
      archived: {
        serializedName: 'archived',
        type: {
          name: 'Boolean'
        }
      },
      isConnected: {
        serializedName: 'isConnected',
        type: {
          name: 'Boolean'
        }
      },
      requestId: {
        serializedName: 'requestId',
        type: {
          name: 'String'
        }
      },
      isPlaid: {
        serializedName: 'isPlaid',
        type: {
          name: 'Boolean'
        }
      },
      isYodlee: {
        serializedName: 'isYodlee',
        type: {
          name: 'Boolean'
        }
      },
      isProviso: {
        serializedName: 'isProviso',
        type: {
          name: 'Boolean'
        }
      },
      isAuxiliary: {
        serializedName: 'isAuxiliary',
        type: {
          name: 'Boolean'
        }
      },
      accountId: {
        readOnly: true,
        serializedName: 'accountId',
        type: {
          name: 'String'
        }
      },
      displayName: {
        readOnly: true,
        serializedName: 'displayName',
        type: {
          name: 'String'
        }
      },
      oldAvailableBalance: {
        serializedName: 'oldAvailableBalance',
        type: {
          name: 'String'
        }
      },
      oldCurrentBalance: {
        serializedName: 'oldCurrentBalance',
        type: {
          name: 'String'
        }
      },
      expireCurrentBalance: {
        serializedName: 'expireCurrentBalance',
        type: {
          name: 'String'
        }
      },
      notFound: {
        serializedName: 'notFound',
        type: {
          name: 'Boolean'
        }
      },
      expiredAvailableBalance: {
        serializedName: 'expiredAvailableBalance',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlaidBankAccount: msRest.CompositeMapper = {
  serializedName: 'PlaidBankAccount',
  type: {
    name: 'Composite',
    className: 'PlaidBankAccount',
    modelProperties: {
      accountId: {
        serializedName: 'accountId',
        type: {
          name: 'String'
        }
      },
      mask: {
        serializedName: 'mask',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const BankAccountConnectionDetails: msRest.CompositeMapper = {
  serializedName: 'BankAccountConnectionDetails',
  type: {
    name: 'Composite',
    className: 'BankAccountConnectionDetails',
    modelProperties: {
      requestId: {
        serializedName: 'requestId',
        type: {
          name: 'String'
        }
      },
      isConnected: {
        serializedName: 'isConnected',
        type: {
          name: 'Boolean'
        }
      },
      transferStatus: {
        serializedName: 'transferStatus',
        type: {
          name: 'String'
        }
      },
      wallet: {
        serializedName: 'wallet',
        type: {
          name: 'String'
        }
      },
      publicToken: {
        serializedName: 'publicToken',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      providerId: {
        serializedName: 'providerId',
        type: {
          name: 'String'
        }
      },
      providerName: {
        serializedName: 'providerName',
        type: {
          name: 'String'
        }
      },
      accountId: {
        serializedName: 'accountId',
        type: {
          name: 'String'
        }
      },
      linkSessionId: {
        serializedName: 'linkSessionId',
        type: {
          name: 'String'
        }
      },
      accounts: {
        serializedName: 'accounts',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlaidBankAccount'
            }
          }
        }
      }
    }
  }
};

export const BankAccountConnectionSettings: msRest.CompositeMapper = {
  serializedName: 'BankAccountConnectionSettings',
  type: {
    name: 'Composite',
    className: 'BankAccountConnectionSettings',
    modelProperties: {
      isYodlee: {
        serializedName: 'isYodlee',
        type: {
          name: 'Boolean'
        }
      },
      isPlaid: {
        serializedName: 'isPlaid',
        type: {
          name: 'Boolean'
        }
      },
      enablePlaidMultibank: {
        serializedName: 'enablePlaidMultibank',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const BankData: msRest.CompositeMapper = {
  serializedName: 'BankData',
  type: {
    name: 'Composite',
    className: 'BankData',
    modelProperties: {
      instituionId: {
        serializedName: 'instituionId',
        type: {
          name: 'String'
        }
      },
      bankName: {
        serializedName: 'bankName',
        type: {
          name: 'String'
        }
      },
      bankSlug: {
        serializedName: 'bankSlug',
        type: {
          name: 'String'
        }
      },
      bankAccounts: {
        serializedName: 'bankAccounts',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'BankAccount'
            }
          }
        }
      },
      getTransactionCount: {
        readOnly: true,
        serializedName: 'getTransactionCount',
        type: {
          name: 'Number'
        }
      },
      isPlaidSupport: {
        serializedName: 'isPlaidSupport',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const BankDetails: msRest.CompositeMapper = {
  serializedName: 'BankDetails',
  type: {
    name: 'Composite',
    className: 'BankDetails',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      bsb: {
        serializedName: 'bsb',
        type: {
          name: 'String'
        }
      },
      number: {
        serializedName: 'number',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const BankStatementStaleStatus: msRest.CompositeMapper = {
  serializedName: 'BankStatementStaleStatus',
  type: {
    name: 'Composite',
    className: 'BankStatementStaleStatus',
    modelProperties: {
      isReconnect: {
        serializedName: 'isReconnect',
        type: {
          name: 'Boolean'
        }
      },
      isUploadStatement: {
        serializedName: 'isUploadStatement',
        type: {
          name: 'Boolean'
        }
      },
      lastTransactionDate: {
        serializedName: 'lastTransactionDate',
        type: {
          name: 'DateTime'
        }
      },
      missingStatements: {
        serializedName: 'missingStatements',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    }
  }
};

export const BankStatementStaleDetail: msRest.CompositeMapper = {
  serializedName: 'BankStatementStaleDetail',
  type: {
    name: 'Composite',
    className: 'BankStatementStaleDetail',
    modelProperties: {
      plaidDisconnected: {
        serializedName: 'plaidDisconnected',
        type: {
          name: 'Composite',
          className: 'BankStatementStaleStatus'
        }
      },
      manualUpload: {
        serializedName: 'manualUpload',
        type: {
          name: 'Composite',
          className: 'BankStatementStaleStatus'
        }
      },
      plaidConnected: {
        serializedName: 'plaidConnected',
        type: {
          name: 'Composite',
          className: 'BankStatementStaleStatus'
        }
      }
    }
  }
};

export const BoolWrapper: msRest.CompositeMapper = {
  serializedName: 'BoolWrapper',
  type: {
    name: 'Composite',
    className: 'BoolWrapper',
    modelProperties: {
      value: {
        serializedName: 'value',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const CancelReason: msRest.CompositeMapper = {
  serializedName: 'CancelReason',
  type: {
    name: 'Composite',
    className: 'CancelReason',
    modelProperties: {
      reasonId: {
        serializedName: 'reasonId',
        type: {
          name: 'Uuid'
        }
      },
      reason: {
        serializedName: 'reason',
        type: {
          name: 'String'
        }
      },
      noteToPartnerAgent: {
        serializedName: 'noteToPartnerAgent',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const CashFlow: msRest.CompositeMapper = {
  serializedName: 'CashFlow',
  type: {
    name: 'Composite',
    className: 'CashFlow',
    modelProperties: {
      expense: {
        serializedName: 'expense',
        type: {
          name: 'Number'
        }
      },
      income: {
        serializedName: 'income',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CashflowDetails: msRest.CompositeMapper = {
  serializedName: 'CashflowDetails',
  type: {
    name: 'Composite',
    className: 'CashflowDetails',
    modelProperties: {
      lastQuarterProfits: {
        serializedName: 'lastQuarterProfits',
        type: {
          name: 'Number'
        }
      },
      agedPayables: {
        serializedName: 'agedPayables',
        type: {
          name: 'Number'
        }
      },
      invoices: {
        serializedName: 'invoices',
        type: {
          name: 'Number'
        }
      },
      bills: {
        serializedName: 'bills',
        type: {
          name: 'Number'
        }
      },
      cashFlow: {
        serializedName: 'cashFlow',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Number'
            }
          }
        }
      }
    }
  }
};

export const Check: msRest.CompositeMapper = {
  serializedName: 'Check',
  type: {
    name: 'Composite',
    className: 'Check',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      isComplete: {
        readOnly: true,
        serializedName: 'isComplete',
        type: {
          name: 'Boolean'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      user: {
        serializedName: 'user',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const CheckList: msRest.CompositeMapper = {
  serializedName: 'CheckList',
  type: {
    name: 'Composite',
    className: 'CheckList',
    modelProperties: {
      checks: {
        serializedName: 'checks',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Check'
            }
          }
        }
      },
      isComplete: {
        readOnly: true,
        serializedName: 'isComplete',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const Commission: msRest.CompositeMapper = {
  serializedName: 'Commission',
  type: {
    name: 'Composite',
    className: 'Commission',
    modelProperties: {
      upfrontFee: {
        serializedName: 'upfrontFee',
        type: {
          name: 'Number'
        }
      },
      trailing: {
        serializedName: 'trailing',
        type: {
          name: 'Number'
        }
      },
      drawFee: {
        serializedName: 'drawFee',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CommissionDetails: msRest.CompositeMapper = {
  serializedName: 'CommissionDetails',
  type: {
    name: 'Composite',
    className: 'CommissionDetails',
    modelProperties: {
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      upFrontBrokerEstablishmentFee: {
        serializedName: 'upFrontBrokerEstablishmentFee',
        type: {
          name: 'Number'
        }
      },
      firstAdvanceCommissionFee: {
        serializedName: 'firstAdvanceCommissionFee',
        type: {
          name: 'Number'
        }
      },
      ongoingAdvanceCommissionFee: {
        serializedName: 'ongoingAdvanceCommissionFee',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CommunicationProjectionExtension: msRest.CompositeMapper = {
  serializedName: 'CommunicationProjectionExtension',
  type: {
    name: 'Composite',
    className: 'CommunicationProjectionExtension',
    modelProperties: {
      isRead: {
        serializedName: 'isRead',
        type: {
          name: 'Boolean'
        }
      },
      updatedDateUtc: {
        serializedName: 'updatedDateUtc',
        type: {
          name: 'DateTime'
        }
      },
      forwardNotes: {
        serializedName: 'forwardNotes',
        type: {
          name: 'String'
        }
      },
      originatingPartnerId: {
        serializedName: 'originatingPartnerId',
        type: {
          name: 'Uuid'
        }
      },
      notificationId: {
        serializedName: 'notificationId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      createdDateUtc: {
        serializedName: 'createdDateUtc',
        type: {
          name: 'DateTime'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      user: {
        serializedName: 'user',
        type: {
          name: 'String'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      sendToPartner: {
        serializedName: 'sendToPartner',
        type: {
          name: 'Boolean'
        }
      },
      sendToAgent: {
        serializedName: 'sendToAgent',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const CommunicationRule: msRest.CompositeMapper = {
  serializedName: 'CommunicationRule',
  type: {
    name: 'Composite',
    className: 'CommunicationRule',
    modelProperties: {
      userId: {
        serializedName: 'userId',
        type: {
          name: 'Uuid'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const CommunicationTemplateSetting: msRest.CompositeMapper = {
  serializedName: 'CommunicationTemplateSetting',
  type: {
    name: 'Composite',
    className: 'CommunicationTemplateSetting',
    modelProperties: {
      templateId: {
        serializedName: 'templateId',
        type: {
          name: 'Uuid'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      template: {
        serializedName: 'template',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const LinkExchangeResponse: msRest.CompositeMapper = {
  serializedName: 'LinkExchangeResponse',
  type: {
    name: 'Composite',
    className: 'LinkExchangeResponse',
    modelProperties: {
      accessToken: {
        serializedName: 'access_token',
        type: {
          name: 'String'
        }
      },
      itemId: {
        serializedName: 'item_id',
        type: {
          name: 'String'
        }
      },
      linkTokenRequestId: {
        serializedName: 'link_token_request_id',
        type: {
          name: 'String'
        }
      },
      institutionId: {
        serializedName: 'institution_id',
        type: {
          name: 'String'
        }
      },
      institutionName: {
        serializedName: 'institution_name',
        type: {
          name: 'String'
        }
      },
      displayMessage: {
        serializedName: 'display_message',
        type: {
          name: 'String'
        }
      },
      errorCode: {
        serializedName: 'error_code',
        type: {
          name: 'String'
        }
      },
      errorMessage: {
        serializedName: 'error_message',
        type: {
          name: 'String'
        }
      },
      errorType: {
        serializedName: 'error_type',
        type: {
          name: 'String'
        }
      },
      requestId: {
        serializedName: 'request_id',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const ConnectionStatusProjection: msRest.CompositeMapper = {
  serializedName: 'ConnectionStatusProjection',
  type: {
    name: 'Composite',
    className: 'ConnectionStatusProjection',
    modelProperties: {
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      institutionName: {
        serializedName: 'institutionName',
        type: {
          name: 'String'
        }
      },
      accountName: {
        serializedName: 'accountName',
        type: {
          name: 'String'
        }
      },
      notFoundAccounts: {
        serializedName: 'notFoundAccounts',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LinkExchangeResponse'
            }
          }
        }
      },
      notFound: {
        serializedName: 'notFound',
        type: {
          name: 'Boolean'
        }
      },
      accountId: {
        serializedName: 'accountId',
        type: {
          name: 'String'
        }
      },
      organisationId: {
        serializedName: 'organisationId',
        type: {
          name: 'Uuid'
        }
      },
      isConnected: {
        serializedName: 'isConnected',
        type: {
          name: 'Boolean'
        }
      },
      requestId: {
        serializedName: 'requestId',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      reason: {
        serializedName: 'reason',
        type: {
          name: 'String'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const Contact: msRest.CompositeMapper = {
  serializedName: 'Contact',
  type: {
    name: 'Composite',
    className: 'Contact',
    modelProperties: {
      contactId: {
        serializedName: 'contactId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      addedDate: {
        serializedName: 'addedDate',
        type: {
          name: 'DateTime'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const ContractDocument: msRest.CompositeMapper = {
  serializedName: 'ContractDocument',
  type: {
    name: 'Composite',
    className: 'ContractDocument',
    modelProperties: {
      displayName: {
        serializedName: 'displayName',
        type: {
          name: 'String'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      documentId: {
        serializedName: 'documentId',
        type: {
          name: 'Uuid'
        }
      },
      data: {
        serializedName: 'data',
        type: {
          name: 'ByteArray'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ContractDocumentPagedList: msRest.CompositeMapper = {
  serializedName: 'ContractDocumentPagedList',
  type: {
    name: 'Composite',
    className: 'ContractDocumentPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ContractDocument'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ContractSignature: msRest.CompositeMapper = {
  serializedName: 'ContractSignature',
  type: {
    name: 'Composite',
    className: 'ContractSignature',
    modelProperties: {
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      signature1: {
        serializedName: 'signature1',
        type: {
          name: 'ByteArray'
        }
      },
      signature2: {
        serializedName: 'signature2',
        type: {
          name: 'ByteArray'
        }
      },
      secondaryApplicant: {
        serializedName: 'secondaryApplicant',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const CreditUtilisation: msRest.CompositeMapper = {
  serializedName: 'CreditUtilisation',
  type: {
    name: 'Composite',
    className: 'CreditUtilisation',
    modelProperties: {
      monthName: {
        serializedName: 'monthName',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      used: {
        serializedName: 'used',
        type: {
          name: 'Number'
        }
      },
      available: {
        serializedName: 'available',
        type: {
          name: 'Number'
        }
      },
      total: {
        readOnly: true,
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const Customer: msRest.CompositeMapper = {
  serializedName: 'Customer',
  type: {
    name: 'Composite',
    className: 'Customer',
    modelProperties: {
      email: {
        required: true,
        serializedName: 'email',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      mobileNumber: {
        required: true,
        serializedName: 'mobileNumber',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      password: {
        serializedName: 'password',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      parentId: {
        serializedName: 'parentId',
        type: {
          name: 'Uuid'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      hasLoggedIn: {
        serializedName: 'hasLoggedIn',
        type: {
          name: 'Boolean'
        }
      },
      lockoutEnabled: {
        serializedName: 'lockoutEnabled',
        type: {
          name: 'Boolean'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const CustomerLoanProjection: msRest.CompositeMapper = {
  serializedName: 'CustomerLoanProjection',
  type: {
    name: 'Composite',
    className: 'CustomerLoanProjection',
    modelProperties: {
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      customerFullName: {
        serializedName: 'customerFullName',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      health: {
        serializedName: 'health',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      salesPerson: {
        serializedName: 'salesPerson',
        type: {
          name: 'String'
        }
      },
      lastUpdated: {
        serializedName: 'lastUpdated',
        type: {
          name: 'DateTime'
        }
      },
      organisationId: {
        serializedName: 'organisationId',
        type: {
          name: 'Uuid'
        }
      },
      offerId: {
        serializedName: 'offerId',
        type: {
          name: 'Uuid'
        }
      },
      closed: {
        serializedName: 'closed',
        type: {
          name: 'Boolean'
        }
      },
      archived: {
        serializedName: 'archived',
        type: {
          name: 'Boolean'
        }
      },
      enabledDebtors: {
        serializedName: 'enabledDebtors',
        type: {
          name: 'Boolean'
        }
      },
      showAllDebtors: {
        serializedName: 'showAllDebtors',
        type: {
          name: 'Boolean'
        }
      },
      bankId: {
        serializedName: 'bankId',
        type: {
          name: 'String'
        }
      },
      funder: {
        serializedName: 'funder',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'String'
        }
      },
      flagged: {
        serializedName: 'flagged',
        type: {
          name: 'Boolean'
        }
      },
      flaggedReason: {
        serializedName: 'flaggedReason',
        type: {
          name: 'String'
        }
      },
      flaggedDate: {
        serializedName: 'flaggedDate',
        type: {
          name: 'DateTime'
        }
      },
      excluded: {
        serializedName: 'excluded',
        type: {
          name: 'Boolean'
        }
      },
      excludedReason: {
        serializedName: 'excludedReason',
        type: {
          name: 'String'
        }
      },
      countryCode: {
        serializedName: 'countryCode',
        type: {
          name: 'String'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      fullBusinessName: {
        serializedName: 'fullBusinessName',
        type: {
          name: 'String'
        }
      },
      secured: {
        serializedName: 'secured',
        type: {
          name: 'Boolean'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      nextAuditDate: {
        serializedName: 'nextAuditDate',
        type: {
          name: 'DateTime'
        }
      },
      auditLowRange: {
        serializedName: 'auditLowRange',
        type: {
          name: 'Number'
        }
      },
      auditHighRange: {
        serializedName: 'auditHighRange',
        type: {
          name: 'Number'
        }
      },
      auditType: {
        serializedName: 'auditType',
        type: {
          name: 'String'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'String'
        }
      },
      closedDate: {
        serializedName: 'closedDate',
        type: {
          name: 'DateTime'
        }
      },
      onOffAudit: {
        serializedName: 'onOffAudit',
        type: {
          name: 'Boolean'
        }
      },
      auditId: {
        serializedName: 'auditId',
        type: {
          name: 'Uuid'
        }
      },
      balanceOverride: {
        serializedName: 'balanceOverride',
        type: {
          name: 'Boolean'
        }
      },
      defaulted: {
        serializedName: 'defaulted',
        type: {
          name: 'Boolean'
        }
      },
      defaultStatus: {
        serializedName: 'defaultStatus',
        type: {
          name: 'String'
        }
      },
      associatedCustomerIdStr: {
        serializedName: 'associatedCustomerIdStr',
        type: {
          name: 'String'
        }
      },
      totalAmountValue: {
        serializedName: 'totalAmount_Value',
        type: {
          name: 'Number'
        }
      },
      totalAmountCurrencyCode: {
        serializedName: 'totalAmount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      availableCreditValue: {
        serializedName: 'availableCredit_Value',
        type: {
          name: 'Number'
        }
      },
      availableCreditCurrencyCode: {
        serializedName: 'availableCredit_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      securedCommissionUpfrontFee: {
        serializedName: 'securedCommission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      securedCommissionTrailing: {
        serializedName: 'securedCommission_Trailing',
        type: {
          name: 'Number'
        }
      },
      securedCommissionDrawFee: {
        serializedName: 'securedCommission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionUpfrontFee: {
        serializedName: 'lineOfCreditCommission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionTrailing: {
        serializedName: 'lineOfCreditCommission_Trailing',
        type: {
          name: 'Number'
        }
      },
      lineOfCreditCommissionDrawFee: {
        serializedName: 'lineOfCreditCommission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      mobileNumber: {
        serializedName: 'mobileNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const CustomerLoanProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'CustomerLoanProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'CustomerLoanProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerLoanProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CustomerLookupData: msRest.CompositeMapper = {
  serializedName: 'CustomerLookupData',
  type: {
    name: 'Composite',
    className: 'CustomerLookupData',
    modelProperties: {
      fullName: {
        serializedName: 'fullName',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'Number'
        }
      },
      abn: {
        serializedName: 'abn',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      organisationId: {
        serializedName: 'organisationId',
        type: {
          name: 'Uuid'
        }
      },
      offerId: {
        serializedName: 'offerId',
        type: {
          name: 'Uuid'
        }
      },
      display: {
        readOnly: true,
        serializedName: 'display',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      mobileNumber: {
        serializedName: 'mobileNumber',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const CustomerProjection: msRest.CompositeMapper = {
  serializedName: 'CustomerProjection',
  type: {
    name: 'Composite',
    className: 'CustomerProjection',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      mobileNumber: {
        serializedName: 'mobileNumber',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      tradingName: {
        serializedName: 'tradingName',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      parentId: {
        serializedName: 'parentId',
        type: {
          name: 'String'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      role: {
        serializedName: 'role',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const CustomerProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'CustomerProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'CustomerProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DashboardAccount: msRest.CompositeMapper = {
  serializedName: 'DashboardAccount',
  type: {
    name: 'Composite',
    className: 'DashboardAccount',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      requestId: {
        serializedName: 'requestId',
        type: {
          name: 'String'
        }
      },
      institutionId: {
        serializedName: 'institutionId',
        type: {
          name: 'String'
        }
      },
      institutionName: {
        serializedName: 'institutionName',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      icon: {
        serializedName: 'icon',
        type: {
          name: 'ByteArray'
        }
      },
      isConnected: {
        serializedName: 'isConnected',
        type: {
          name: 'Boolean'
        }
      },
      isPlaid: {
        serializedName: 'isPlaid',
        type: {
          name: 'Boolean'
        }
      },
      isYodlee: {
        serializedName: 'isYodlee',
        type: {
          name: 'Boolean'
        }
      },
      isProviso: {
        serializedName: 'isProviso',
        type: {
          name: 'Boolean'
        }
      },
      isAuxiliary: {
        serializedName: 'isAuxiliary',
        type: {
          name: 'Boolean'
        }
      },
      notFound: {
        serializedName: 'notFound',
        type: {
          name: 'Boolean'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const DashboardAccounts: msRest.CompositeMapper = {
  serializedName: 'DashboardAccounts',
  type: {
    name: 'Composite',
    className: 'DashboardAccounts',
    modelProperties: {
      totalAmount: {
        readOnly: true,
        serializedName: 'totalAmount',
        type: {
          name: 'Number'
        }
      },
      banks: {
        serializedName: 'banks',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DashboardAccount'
            }
          }
        }
      }
    }
  }
};

export const DashboardBusinessPerformance: msRest.CompositeMapper = {
  serializedName: 'DashboardBusinessPerformance',
  type: {
    name: 'Composite',
    className: 'DashboardBusinessPerformance',
    modelProperties: {
      performacePercentage: {
        serializedName: 'performacePercentage',
        type: {
          name: 'String'
        }
      },
      currentCashRatio: {
        serializedName: 'currentCashRatio',
        type: {
          name: 'Number'
        }
      },
      monthlyProfitability: {
        serializedName: 'monthlyProfitability',
        type: {
          name: 'Number'
        }
      },
      profitRunRate: {
        serializedName: 'profitRunRate',
        type: {
          name: 'Number'
        }
      },
      cashRunway: {
        serializedName: 'cashRunway',
        type: {
          name: 'Number'
        }
      },
      monthMargin: {
        serializedName: 'monthMargin',
        type: {
          name: 'Number'
        }
      },
      revenueRunRate: {
        serializedName: 'revenueRunRate',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DashboardCashFlows: msRest.CompositeMapper = {
  serializedName: 'DashboardCashFlows',
  type: {
    name: 'Composite',
    className: 'DashboardCashFlows',
    modelProperties: {
      expectedBalance: {
        serializedName: 'expectedBalance',
        type: {
          name: 'Number'
        }
      },
      expectedNetCashFlow: {
        serializedName: 'expectedNetCashFlow',
        type: {
          name: 'Number'
        }
      },
      cashFlows: {
        serializedName: 'cashFlows',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CashFlow'
            }
          }
        }
      },
      incomes: {
        readOnly: true,
        serializedName: 'incomes',
        type: {
          name: 'Number'
        }
      },
      expenses: {
        readOnly: true,
        serializedName: 'expenses',
        type: {
          name: 'Number'
        }
      },
      netCashFlow: {
        readOnly: true,
        serializedName: 'netCashFlow',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DashboardCreditUtilisation: msRest.CompositeMapper = {
  serializedName: 'DashboardCreditUtilisation',
  type: {
    name: 'Composite',
    className: 'DashboardCreditUtilisation',
    modelProperties: {
      creditUtilisation: {
        serializedName: 'creditUtilisation',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CreditUtilisation'
            }
          }
        }
      }
    }
  }
};

export const DashboardFundUtilisation: msRest.CompositeMapper = {
  serializedName: 'DashboardFundUtilisation',
  type: {
    name: 'Composite',
    className: 'DashboardFundUtilisation',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const Insight: msRest.CompositeMapper = {
  serializedName: 'Insight',
  type: {
    name: 'Composite',
    className: 'Insight',
    modelProperties: {
      insightId: {
        serializedName: 'insightId',
        type: {
          name: 'Uuid'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      description: {
        serializedName: 'description',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      isNew: {
        serializedName: 'isNew',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const DashboardInsight: msRest.CompositeMapper = {
  serializedName: 'DashboardInsight',
  type: {
    name: 'Composite',
    className: 'DashboardInsight',
    modelProperties: {
      insights: {
        serializedName: 'insights',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Insight'
            }
          }
        }
      }
    }
  }
};

export const DashboardLoan: msRest.CompositeMapper = {
  serializedName: 'DashboardLoan',
  type: {
    name: 'Composite',
    className: 'DashboardLoan',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      availableAmount: {
        serializedName: 'availableAmount',
        type: {
          name: 'Number'
        }
      },
      usedAmount: {
        serializedName: 'usedAmount',
        type: {
          name: 'Number'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const MonthlyLoanRepayment: msRest.CompositeMapper = {
  serializedName: 'MonthlyLoanRepayment',
  type: {
    name: 'Composite',
    className: 'MonthlyLoanRepayment',
    modelProperties: {
      date: {
        serializedName: 'date',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const LoanRepayment: msRest.CompositeMapper = {
  serializedName: 'LoanRepayment',
  type: {
    name: 'Composite',
    className: 'LoanRepayment',
    modelProperties: {
      month: {
        serializedName: 'month',
        type: {
          name: 'String'
        }
      },
      expectedinvoices: {
        serializedName: 'expectedinvoices',
        type: {
          name: 'Number'
        }
      },
      overdue: {
        serializedName: 'overdue',
        type: {
          name: 'Number'
        }
      },
      totalAmount: {
        readOnly: true,
        serializedName: 'totalAmount',
        type: {
          name: 'Number'
        }
      },
      monthlyLoanRepayments: {
        serializedName: 'monthlyLoanRepayments',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'MonthlyLoanRepayment'
            }
          }
        }
      }
    }
  }
};

export const DashboardLoanRepayment: msRest.CompositeMapper = {
  serializedName: 'DashboardLoanRepayment',
  type: {
    name: 'Composite',
    className: 'DashboardLoanRepayment',
    modelProperties: {
      loanRepayments: {
        serializedName: 'loanRepayments',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LoanRepayment'
            }
          }
        }
      }
    }
  }
};

export const OutgoingInvoice: msRest.CompositeMapper = {
  serializedName: 'OutgoingInvoice',
  type: {
    name: 'Composite',
    className: 'OutgoingInvoice',
    modelProperties: {
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const DashboardOutgoingInvoices: msRest.CompositeMapper = {
  serializedName: 'DashboardOutgoingInvoices',
  type: {
    name: 'Composite',
    className: 'DashboardOutgoingInvoices',
    modelProperties: {
      totalAmount: {
        readOnly: true,
        serializedName: 'totalAmount',
        type: {
          name: 'Number'
        }
      },
      outgoingInvoices: {
        serializedName: 'outgoingInvoices',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'OutgoingInvoice'
            }
          }
        }
      },
      fundingAmount: {
        serializedName: 'fundingAmount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DatedTransactionDataFile: msRest.CompositeMapper = {
  serializedName: 'DatedTransactionDataFile',
  type: {
    name: 'Composite',
    className: 'DatedTransactionDataFile',
    modelProperties: {
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      data: {
        serializedName: 'data',
        type: {
          name: 'ByteArray'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      fileType: {
        serializedName: 'fileType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const DatedTransactionDataFilePagedList: msRest.CompositeMapper = {
  serializedName: 'DatedTransactionDataFilePagedList',
  type: {
    name: 'Composite',
    className: 'DatedTransactionDataFilePagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DatedTransactionDataFile'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DebtorDetails: msRest.CompositeMapper = {
  serializedName: 'DebtorDetails',
  type: {
    name: 'Composite',
    className: 'DebtorDetails',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      invoiceTypes: {
        serializedName: 'invoiceTypes',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Number'
            }
          }
        }
      }
    }
  }
};

export const DecisionDetails: msRest.CompositeMapper = {
  serializedName: 'DecisionDetails',
  type: {
    name: 'Composite',
    className: 'DecisionDetails',
    modelProperties: {
      reasons: {
        serializedName: 'reasons',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      },
      reasonsToString: {
        readOnly: true,
        serializedName: 'reasonsToString',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'Number'
        }
      },
      statusName: {
        readOnly: true,
        serializedName: 'statusName',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const DisplayMessageModal: msRest.CompositeMapper = {
  serializedName: 'DisplayMessageModal',
  type: {
    name: 'Composite',
    className: 'DisplayMessageModal',
    modelProperties: {
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      isDismiss: {
        serializedName: 'isDismiss',
        type: {
          name: 'Boolean'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      isActiveLoansOnly: {
        serializedName: 'isActiveLoansOnly',
        type: {
          name: 'Boolean'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const EarlyRepaymentCalculation: msRest.CompositeMapper = {
  serializedName: 'EarlyRepaymentCalculation',
  type: {
    name: 'Composite',
    className: 'EarlyRepaymentCalculation',
    modelProperties: {
      saving: {
        serializedName: 'saving',
        type: {
          name: 'Number'
        }
      },
      repayment: {
        serializedName: 'repayment',
        type: {
          name: 'Number'
        }
      },
      scheduleTransaction: {
        serializedName: 'scheduleTransaction',
        type: {
          name: 'DateTime'
        }
      },
      weeklyRepayment: {
        serializedName: 'weeklyRepayment',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ExtendedAdvanceProjection: msRest.CompositeMapper = {
  serializedName: 'ExtendedAdvanceProjection',
  type: {
    name: 'Composite',
    className: 'ExtendedAdvanceProjection',
    modelProperties: {
      calculatedAdvanceRate: {
        serializedName: 'calculatedAdvanceRate',
        type: {
          name: 'Number'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amountValue: {
        serializedName: 'amount_Value',
        type: {
          name: 'Number'
        }
      },
      amountCurrencyCode: {
        serializedName: 'amount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      remainingValue: {
        serializedName: 'remaining_Value',
        type: {
          name: 'Number'
        }
      },
      remainingCurrencyCode: {
        serializedName: 'remaining_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentValueValue: {
        serializedName: 'nextRepaymentValue_Value',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentValueCurrencyCode: {
        serializedName: 'nextRepaymentValue_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDateUTC: {
        serializedName: 'nextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      actualNextRepaymentDay: {
        readOnly: true,
        serializedName: 'actualNextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      nextRepaymentDay: {
        readOnly: true,
        serializedName: 'nextRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDay: {
        readOnly: true,
        serializedName: 'lastRepaymentDay',
        type: {
          name: 'String'
        }
      },
      lastRepaymentDateUTC: {
        readOnly: true,
        serializedName: 'lastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      totalRepaymentAmount: {
        serializedName: 'totalRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      weeklyPayment: {
        serializedName: 'weeklyPayment',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      terms: {
        serializedName: 'terms',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      percentage: {
        serializedName: 'percentage',
        type: {
          name: 'Number'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      name: {
        readOnly: true,
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      earlyRepaid: {
        serializedName: 'earlyRepaid',
        type: {
          name: 'Boolean'
        }
      },
      earlyRepaidDate: {
        serializedName: 'earlyRepaidDate',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepaymentDateUTC: {
        serializedName: 'overrideLastRepaymentDateUTC',
        type: {
          name: 'DateTime'
        }
      },
      overrideLastRepayment: {
        serializedName: 'overrideLastRepayment',
        type: {
          name: 'Boolean'
        }
      },
      legacy: {
        serializedName: 'legacy',
        type: {
          name: 'Boolean'
        }
      },
      monthlyFee: {
        serializedName: 'monthlyFee',
        type: {
          name: 'Boolean'
        }
      },
      commissionUpfrontFee: {
        serializedName: 'commission_UpfrontFee',
        type: {
          name: 'Number'
        }
      },
      commissionTrailing: {
        serializedName: 'commission_Trailing',
        type: {
          name: 'Number'
        }
      },
      commissionDrawFee: {
        serializedName: 'commission_DrawFee',
        type: {
          name: 'Number'
        }
      },
      commissionAgentFee: {
        serializedName: 'commission_AgentFee',
        type: {
          name: 'Number'
        }
      },
      fixed: {
        serializedName: 'fixed',
        type: {
          name: 'Boolean'
        }
      },
      fixedRepayment: {
        serializedName: 'fixedRepayment',
        type: {
          name: 'Number'
        }
      },
      fixedRepaymentFee: {
        serializedName: 'fixedRepaymentFee',
        type: {
          name: 'Number'
        }
      },
      principleBalance: {
        serializedName: 'principleBalance',
        type: {
          name: 'Number'
        }
      },
      accruedBalance: {
        serializedName: 'accruedBalance',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ExtendedAdvanceProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'ExtendedAdvanceProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'ExtendedAdvanceProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ExtendedAdvanceProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const FileDetails: msRest.CompositeMapper = {
  serializedName: 'FileDetails',
  type: {
    name: 'Composite',
    className: 'FileDetails',
    modelProperties: {
      location: {
        serializedName: 'location',
        type: {
          name: 'String'
        }
      },
      fileType: {
        serializedName: 'fileType',
        type: {
          name: 'String'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      internalLocation: {
        serializedName: 'internalLocation',
        type: {
          name: 'String'
        }
      },
      missingTransaction: {
        serializedName: 'missingTransaction',
        type: {
          name: 'Boolean'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      isSecondary: {
        serializedName: 'isSecondary',
        type: {
          name: 'Boolean'
        }
      },
      fileDateStr: {
        serializedName: 'fileDateStr',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const FixedRepaymentCalculation: msRest.CompositeMapper = {
  serializedName: 'FixedRepaymentCalculation',
  type: {
    name: 'Composite',
    className: 'FixedRepaymentCalculation',
    modelProperties: {
      terms: {
        serializedName: 'terms',
        type: {
          name: 'Number'
        }
      },
      weeklyRepayment: {
        serializedName: 'weeklyRepayment',
        type: {
          name: 'Number'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      withdrawAmount: {
        serializedName: 'withdrawAmount',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      totalRepayment: {
        serializedName: 'totalRepayment',
        type: {
          name: 'Number'
        }
      },
      firstRepayment: {
        serializedName: 'firstRepayment',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const HealthCheckRule: msRest.CompositeMapper = {
  serializedName: 'HealthCheckRule',
  type: {
    name: 'Composite',
    className: 'HealthCheckRule',
    modelProperties: {
      internalNote: {
        serializedName: 'internalNote',
        type: {
          name: 'String'
        }
      },
      externalNote: {
        serializedName: 'externalNote',
        type: {
          name: 'String'
        }
      },
      passRule: {
        serializedName: 'passRule',
        type: {
          name: 'Boolean'
        }
      },
      testName: {
        serializedName: 'testName',
        type: {
          name: 'String'
        }
      },
      transactionDataStaleTest: {
        serializedName: 'transactionDataStaleTest',
        type: {
          name: 'Boolean'
        }
      },
      rawData: {
        serializedName: 'rawData',
        type: {
          name: 'String'
        }
      },
      allowDisable: {
        serializedName: 'allowDisable',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const HealthCheckCollection: msRest.CompositeMapper = {
  serializedName: 'HealthCheckCollection',
  type: {
    name: 'Composite',
    className: 'HealthCheckCollection',
    modelProperties: {
      rules: {
        serializedName: 'rules',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'HealthCheckRule'
            }
          }
        }
      },
      transactionBalance: {
        serializedName: 'transactionBalance',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const HealthRuleDetails: msRest.CompositeMapper = {
  serializedName: 'HealthRuleDetails',
  type: {
    name: 'Composite',
    className: 'HealthRuleDetails',
    modelProperties: {
      loan: {
        serializedName: 'loan',
        type: {
          name: 'Composite',
          className: 'CustomerLoanProjection'
        }
      },
      rules: {
        serializedName: 'rules',
        type: {
          name: 'Composite',
          className: 'HealthCheckCollection'
        }
      },
      rulesString: {
        readOnly: true,
        serializedName: 'rulesString',
        type: {
          name: 'String'
        }
      },
      isStatusChanged: {
        serializedName: 'isStatusChanged',
        type: {
          name: 'Boolean'
        }
      },
      refinanceScore: {
        serializedName: 'refinanceScore',
        type: {
          name: 'Number'
        }
      },
      status: {
        readOnly: true,
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      overrideEnabled: {
        serializedName: 'overrideEnabled',
        type: {
          name: 'DateTime'
        }
      },
      getDaysRemaining: {
        readOnly: true,
        serializedName: 'getDaysRemaining',
        type: {
          name: 'Number'
        }
      },
      daysSinceCreated: {
        readOnly: true,
        serializedName: 'daysSinceCreated',
        type: {
          name: 'Number'
        }
      },
      rawData: {
        serializedName: 'rawData',
        type: {
          name: 'String'
        }
      },
      lastAdvance: {
        serializedName: 'lastAdvance',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const IncomeSummary: msRest.CompositeMapper = {
  serializedName: 'IncomeSummary',
  type: {
    name: 'Composite',
    className: 'IncomeSummary',
    modelProperties: {
      income: {
        serializedName: 'income',
        type: {
          name: 'Number'
        }
      },
      repayment: {
        serializedName: 'repayment',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const IncomeDetails: msRest.CompositeMapper = {
  serializedName: 'IncomeDetails',
  type: {
    name: 'Composite',
    className: 'IncomeDetails',
    modelProperties: {
      currentMonthIncomeChangePercent: {
        serializedName: 'currentMonthIncomeChangePercent',
        type: {
          name: 'Number'
        }
      },
      currentMonthIncomeChangeUp: {
        serializedName: 'currentMonthIncomeChangeUp',
        type: {
          name: 'Boolean'
        }
      },
      currentWeek: {
        serializedName: 'currentWeek',
        type: {
          name: 'Composite',
          className: 'IncomeSummary'
        }
      },
      estimatedWeek: {
        serializedName: 'estimatedWeek',
        type: {
          name: 'Composite',
          className: 'IncomeSummary'
        }
      },
      historicalWeeks: {
        serializedName: 'historicalWeeks',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Composite',
              className: 'IncomeSummary'
            }
          }
        }
      },
      nextRepaymentDate: {
        serializedName: 'nextRepaymentDate',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const IntelligenceResult: msRest.CompositeMapper = {
  serializedName: 'IntelligenceResult',
  type: {
    name: 'Composite',
    className: 'IntelligenceResult',
    modelProperties: {
      dateCreated: {
        serializedName: 'dateCreated',
        type: {
          name: 'DateTime'
        }
      },
      weigth: {
        serializedName: 'weigth',
        type: {
          name: 'Number'
        }
      },
      flagged: {
        serializedName: 'flagged',
        type: {
          name: 'Boolean'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      link: {
        serializedName: 'link',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const InvoicePaymentCalculation: msRest.CompositeMapper = {
  serializedName: 'InvoicePaymentCalculation',
  type: {
    name: 'Composite',
    className: 'InvoicePaymentCalculation',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      withdrawAmount: {
        serializedName: 'withdrawAmount',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      totalRepayment: {
        serializedName: 'totalRepayment',
        type: {
          name: 'Number'
        }
      },
      firstRepayment: {
        serializedName: 'firstRepayment',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const LineOfCreditRepaymentCalculation: msRest.CompositeMapper = {
  serializedName: 'LineOfCreditRepaymentCalculation',
  type: {
    name: 'Composite',
    className: 'LineOfCreditRepaymentCalculation',
    modelProperties: {
      percentageOfIncome: {
        serializedName: 'percentageOfIncome',
        type: {
          name: 'Number'
        }
      },
      weeklyRepayment: {
        serializedName: 'weeklyRepayment',
        type: {
          name: 'Number'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      weeklyForecastIncome: {
        serializedName: 'weeklyForecastIncome',
        type: {
          name: 'Number'
        }
      },
      fixed: {
        serializedName: 'fixed',
        type: {
          name: 'Boolean'
        }
      },
      isFirstAdvance: {
        serializedName: 'isFirstAdvance',
        type: {
          name: 'Boolean'
        }
      },
      isAdvanceEnabled: {
        serializedName: 'isAdvanceEnabled',
        type: {
          name: 'Boolean'
        }
      },
      advanceDisableMessage: {
        serializedName: 'advanceDisableMessage',
        type: {
          name: 'String'
        }
      },
      principleBalance: {
        serializedName: 'principleBalance',
        type: {
          name: 'Number'
        }
      },
      accruedBalance: {
        serializedName: 'accruedBalance',
        type: {
          name: 'Number'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      withdrawAmount: {
        serializedName: 'withdrawAmount',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      totalRepayment: {
        serializedName: 'totalRepayment',
        type: {
          name: 'Number'
        }
      },
      firstRepayment: {
        serializedName: 'firstRepayment',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const LoanDebtorProjection: msRest.CompositeMapper = {
  serializedName: 'LoanDebtorProjection',
  type: {
    name: 'Composite',
    className: 'LoanDebtorProjection',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'Composite',
          className: 'DebtorDetails'
        }
      },
      approved: {
        serializedName: 'approved',
        type: {
          name: 'Number'
        }
      },
      fundingRate: {
        serializedName: 'fundingRate',
        type: {
          name: 'Number'
        }
      },
      available: {
        serializedName: 'available',
        type: {
          name: 'Number'
        }
      },
      selected: {
        serializedName: 'selected',
        type: {
          name: 'Boolean'
        }
      },
      requested: {
        serializedName: 'requested',
        type: {
          name: 'Boolean'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      adminSelected: {
        serializedName: 'adminSelected',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const LoanDebtorProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'LoanDebtorProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'LoanDebtorProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LoanDebtorProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const LoanNotificationProjection: msRest.CompositeMapper = {
  serializedName: 'LoanNotificationProjection',
  type: {
    name: 'Composite',
    className: 'LoanNotificationProjection',
    modelProperties: {
      notificationId: {
        serializedName: 'notificationId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      createdOnUtc: {
        serializedName: 'createdOnUtc',
        type: {
          name: 'DateTime'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      read: {
        serializedName: 'read',
        type: {
          name: 'Boolean'
        }
      },
      readOnUtc: {
        serializedName: 'readOnUtc',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const LoanNotificationProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'LoanNotificationProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'LoanNotificationProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LoanNotificationProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const LoanReadyApplication: msRest.CompositeMapper = {
  serializedName: 'LoanReadyApplication',
  type: {
    name: 'Composite',
    className: 'LoanReadyApplication',
    modelProperties: {
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      availableCredit: {
        serializedName: 'availableCredit',
        type: {
          name: 'Composite',
          className: 'Amount'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      totalAmount: {
        serializedName: 'totalAmount',
        type: {
          name: 'Composite',
          className: 'Amount'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const LoanReadyApplicationPagedList: msRest.CompositeMapper = {
  serializedName: 'LoanReadyApplicationPagedList',
  type: {
    name: 'Composite',
    className: 'LoanReadyApplicationPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LoanReadyApplication'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const LoanSettings: msRest.CompositeMapper = {
  serializedName: 'LoanSettings',
  type: {
    name: 'Composite',
    className: 'LoanSettings',
    modelProperties: {
      fixedRepayment: {
        serializedName: 'fixedRepayment',
        type: {
          name: 'Boolean'
        }
      },
      percentOfIncome: {
        serializedName: 'percentOfIncome',
        type: {
          name: 'Boolean'
        }
      },
      invoicePayment: {
        serializedName: 'invoicePayment',
        type: {
          name: 'Boolean'
        }
      },
      lineOfCreditPayment: {
        serializedName: 'lineOfCreditPayment',
        type: {
          name: 'Boolean'
        }
      },
      revolving: {
        serializedName: 'revolving',
        type: {
          name: 'Boolean'
        }
      },
      enabledDebtors: {
        serializedName: 'enabledDebtors',
        type: {
          name: 'Boolean'
        }
      },
      showAllDebtors: {
        serializedName: 'showAllDebtors',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const Location: msRest.CompositeMapper = {
  serializedName: 'Location',
  type: {
    name: 'Composite',
    className: 'Location',
    modelProperties: {
      ip: {
        serializedName: 'ip',
        type: {
          name: 'String'
        }
      },
      city: {
        serializedName: 'city',
        type: {
          name: 'String'
        }
      },
      region: {
        serializedName: 'region',
        type: {
          name: 'String'
        }
      },
      regionCode: {
        serializedName: 'region_code',
        type: {
          name: 'String'
        }
      },
      countryCode: {
        serializedName: 'country_code',
        type: {
          name: 'String'
        }
      },
      countryCodeIso3: {
        serializedName: 'country_code_iso3',
        type: {
          name: 'String'
        }
      },
      countryName: {
        serializedName: 'country_name',
        type: {
          name: 'String'
        }
      },
      countryTld: {
        serializedName: 'country_tld',
        type: {
          name: 'String'
        }
      },
      continentCode: {
        serializedName: 'continent_code',
        type: {
          name: 'String'
        }
      },
      inEu: {
        serializedName: 'in_eu',
        type: {
          name: 'Boolean'
        }
      },
      postal: {
        serializedName: 'postal',
        type: {
          name: 'String'
        }
      },
      latitude: {
        serializedName: 'latitude',
        type: {
          name: 'Number'
        }
      },
      longitude: {
        serializedName: 'longitude',
        type: {
          name: 'Number'
        }
      },
      timezone: {
        serializedName: 'timezone',
        type: {
          name: 'String'
        }
      },
      utcOffset: {
        serializedName: 'utc_offset',
        type: {
          name: 'String'
        }
      },
      countryCallingCode: {
        serializedName: 'country_calling_code',
        type: {
          name: 'String'
        }
      },
      languages: {
        serializedName: 'languages',
        type: {
          name: 'String'
        }
      },
      countryArea: {
        serializedName: 'country_area',
        type: {
          name: 'Number'
        }
      },
      org: {
        serializedName: 'org',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const MultipleSearch: msRest.CompositeMapper = {
  serializedName: 'MultipleSearch',
  type: {
    name: 'Composite',
    className: 'MultipleSearch',
    modelProperties: {
      loans: {
        serializedName: 'loans',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerLoanProjection'
            }
          }
        }
      },
      applications: {
        serializedName: 'applications',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicationListProjection'
            }
          }
        }
      },
      applicants: {
        serializedName: 'applicants',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicantProjection'
            }
          }
        }
      },
      contacts: {
        serializedName: 'contacts',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Contact'
            }
          }
        }
      },
      customers: {
        serializedName: 'customers',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerProjection'
            }
          }
        }
      }
    }
  }
};

export const Name: msRest.CompositeMapper = {
  serializedName: 'Name',
  type: {
    name: 'Composite',
    className: 'Name',
    modelProperties: {
      first: {
        serializedName: 'first',
        type: {
          name: 'String'
        }
      },
      last: {
        serializedName: 'last',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Note: msRest.CompositeMapper = {
  serializedName: 'Note',
  type: {
    name: 'Composite',
    className: 'Note',
    modelProperties: {
      noteId: {
        serializedName: 'noteId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      temporary: {
        serializedName: 'temporary',
        type: {
          name: 'Boolean'
        }
      },
      author: {
        serializedName: 'author',
        type: {
          name: 'String'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const OfferType: msRest.CompositeMapper = {
  serializedName: 'OfferType',
  type: {
    name: 'Composite',
    className: 'OfferType',
    modelProperties: {
      creditLimit: {
        serializedName: 'creditLimit',
        type: {
          name: 'Number'
        }
      },
      facilityLimit: {
        serializedName: 'facilityLimit',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      establishmentFee: {
        serializedName: 'establishmentFee',
        type: {
          name: 'Number'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      decision: {
        serializedName: 'decision',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const OfferAmount: msRest.CompositeMapper = {
  serializedName: 'OfferAmount',
  type: {
    name: 'Composite',
    className: 'OfferAmount',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      unsecured: {
        serializedName: 'unsecured',
        type: {
          name: 'Composite',
          className: 'OfferType'
        }
      },
      secured: {
        serializedName: 'secured',
        type: {
          name: 'Composite',
          className: 'OfferType'
        }
      },
      lineOfCredit: {
        serializedName: 'lineOfCredit',
        type: {
          name: 'Composite',
          className: 'OfferType'
        }
      }
    }
  }
};

export const OfferDetails: msRest.CompositeMapper = {
  serializedName: 'OfferDetails',
  type: {
    name: 'Composite',
    className: 'OfferDetails',
    modelProperties: {
      offerId: {
        serializedName: 'offerId',
        type: {
          name: 'Uuid'
        }
      },
      facilityLimit: {
        serializedName: 'facilityLimit',
        type: {
          name: 'Number'
        }
      },
      creditLimit: {
        serializedName: 'creditLimit',
        type: {
          name: 'Number'
        }
      },
      commission: {
        serializedName: 'commission',
        type: {
          name: 'Composite',
          className: 'Commission'
        }
      },
      secured: {
        serializedName: 'secured',
        type: {
          name: 'Boolean'
        }
      },
      decision: {
        serializedName: 'decision',
        type: {
          name: 'Number'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'Number'
        }
      },
      cashFlowLimit: {
        serializedName: 'cashFlowLimit',
        type: {
          name: 'Number'
        }
      },
      receivableLimit: {
        serializedName: 'receivableLimit',
        type: {
          name: 'Number'
        }
      },
      expiry: {
        serializedName: 'expiry',
        type: {
          name: 'DateTime'
        }
      },
      hasExpire: {
        serializedName: 'hasExpire',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const OrganisationProjection: msRest.CompositeMapper = {
  serializedName: 'OrganisationProjection',
  type: {
    name: 'Composite',
    className: 'OrganisationProjection',
    modelProperties: {
      accounting: {
        serializedName: 'accounting',
        type: {
          name: 'Boolean'
        }
      },
      accountingSystem: {
        serializedName: 'accountingSystem',
        type: {
          name: 'String'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      archive: {
        serializedName: 'archive',
        type: {
          name: 'Boolean'
        }
      },
      bankFeed: {
        serializedName: 'bankFeed',
        type: {
          name: 'Boolean'
        }
      },
      isBankManual: {
        serializedName: 'isBankManual',
        type: {
          name: 'Boolean'
        }
      },
      bankFeedConnectionType: {
        serializedName: 'bankFeedConnectionType',
        type: {
          name: 'String'
        }
      },
      bankStatement: {
        serializedName: 'bankStatement',
        type: {
          name: 'Boolean'
        }
      },
      bankStatementType: {
        serializedName: 'bankStatementType',
        type: {
          name: 'String'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      plexeBsb: {
        serializedName: 'plexeBsb',
        type: {
          name: 'String'
        }
      },
      plexeAccountNumber: {
        serializedName: 'plexeAccountNumber',
        type: {
          name: 'String'
        }
      },
      automaticDirectDebit: {
        serializedName: 'automaticDirectDebit',
        type: {
          name: 'String'
        }
      },
      automaticAccountingImport: {
        serializedName: 'automaticAccountingImport',
        type: {
          name: 'String'
        }
      },
      bankingProviderCustomerId: {
        serializedName: 'bankingProviderCustomerId',
        type: {
          name: 'String'
        }
      },
      invoiceExclusion: {
        serializedName: 'invoiceExclusion',
        type: {
          name: 'Boolean'
        }
      },
      accountingWriteBack: {
        serializedName: 'accountingWriteBack',
        type: {
          name: 'Boolean'
        }
      },
      institutionId: {
        serializedName: 'institutionId',
        type: {
          name: 'String'
        }
      },
      requestId: {
        serializedName: 'requestId',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PaidCommission: msRest.CompositeMapper = {
  serializedName: 'PaidCommission',
  type: {
    name: 'Composite',
    className: 'PaidCommission',
    modelProperties: {
      createdOnUtc: {
        serializedName: 'createdOnUtc',
        type: {
          name: 'DateTime'
        }
      },
      paid: {
        serializedName: 'paid',
        type: {
          name: 'Boolean'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'Uuid'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      paidOn: {
        serializedName: 'paidOn',
        type: {
          name: 'DateTime'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const PaidCommissionPagedList: msRest.CompositeMapper = {
  serializedName: 'PaidCommissionPagedList',
  type: {
    name: 'Composite',
    className: 'PaidCommissionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PaidCommission'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PartnerCommission: msRest.CompositeMapper = {
  serializedName: 'PartnerCommission',
  type: {
    name: 'Composite',
    className: 'PartnerCommission',
    modelProperties: {
      unsecuredTrail: {
        serializedName: 'unsecuredTrail',
        type: {
          name: 'Number'
        }
      },
      securedTrail: {
        serializedName: 'securedTrail',
        type: {
          name: 'Number'
        }
      },
      locTrail: {
        serializedName: 'locTrail',
        type: {
          name: 'Number'
        }
      },
      upFrontFee: {
        serializedName: 'upFrontFee',
        type: {
          name: 'Number'
        }
      },
      drawFee: {
        serializedName: 'drawFee',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const Partner: msRest.CompositeMapper = {
  serializedName: 'Partner',
  type: {
    name: 'Composite',
    className: 'Partner',
    modelProperties: {
      email: {
        required: true,
        serializedName: 'email',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MinLength: 1
        },
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      mobilePhoneNumber: {
        serializedName: 'mobilePhoneNumber',
        type: {
          name: 'String'
        }
      },
      commission: {
        serializedName: 'commission',
        type: {
          name: 'Composite',
          className: 'PartnerCommission'
        }
      },
      urlReferral: {
        serializedName: 'urlReferral',
        type: {
          name: 'String'
        }
      },
      owner: {
        serializedName: 'owner',
        type: {
          name: 'Boolean'
        }
      },
      displayCommission: {
        serializedName: 'displayCommission',
        type: {
          name: 'Boolean'
        }
      },
      agentSupport: {
        serializedName: 'agentSupport',
        type: {
          name: 'Boolean'
        }
      },
      editAgentSupport: {
        serializedName: 'editAgentSupport',
        type: {
          name: 'Boolean'
        }
      },
      isPartnerAPIEnabled: {
        serializedName: 'isPartnerAPIEnabled',
        type: {
          name: 'Boolean'
        }
      },
      displayAdvances: {
        serializedName: 'displayAdvances',
        type: {
          name: 'Boolean'
        }
      },
      hideAgentApplication: {
        serializedName: 'hideAgentApplication',
        type: {
          name: 'Boolean'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const PartnerOwnerDetails: msRest.CompositeMapper = {
  serializedName: 'PartnerOwnerDetails',
  type: {
    name: 'Composite',
    className: 'PartnerOwnerDetails',
    modelProperties: {
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      preferredName: {
        serializedName: 'preferredName',
        type: {
          name: 'String'
        }
      },
      lastname: {
        serializedName: 'lastname',
        type: {
          name: 'String'
        }
      },
      cellNumber: {
        serializedName: 'cellNumber',
        type: {
          name: 'String'
        }
      },
      officeNumber: {
        serializedName: 'officeNumber',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      driversLicenceNumber: {
        serializedName: 'driversLicenceNumber',
        type: {
          name: 'String'
        }
      },
      stateIssued: {
        serializedName: 'stateIssued',
        type: {
          name: 'String'
        }
      },
      ssn: {
        serializedName: 'ssn',
        type: {
          name: 'String'
        }
      },
      dob: {
        serializedName: 'dob',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const PartnerBusinessDetails: msRest.CompositeMapper = {
  serializedName: 'PartnerBusinessDetails',
  type: {
    name: 'Composite',
    className: 'PartnerBusinessDetails',
    modelProperties: {
      companyName: {
        serializedName: 'companyName',
        type: {
          name: 'String'
        }
      },
      companyAddress: {
        serializedName: 'companyAddress',
        type: {
          name: 'String'
        }
      },
      officeNumber: {
        serializedName: 'officeNumber',
        type: {
          name: 'String'
        }
      },
      webAddress: {
        serializedName: 'webAddress',
        type: {
          name: 'String'
        }
      },
      accountPayableEmail: {
        serializedName: 'accountPayableEmail',
        type: {
          name: 'String'
        }
      },
      optionFirst: {
        serializedName: 'optionFirst',
        type: {
          name: 'Boolean'
        }
      },
      optionSecond: {
        serializedName: 'optionSecond',
        type: {
          name: 'Boolean'
        }
      },
      optionThird: {
        serializedName: 'optionThird',
        type: {
          name: 'Boolean'
        }
      },
      optionFourth: {
        serializedName: 'optionFourth',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const PartnerAchDetail: msRest.CompositeMapper = {
  serializedName: 'PartnerAchDetail',
  type: {
    name: 'Composite',
    className: 'PartnerAchDetail',
    modelProperties: {
      bankName: {
        serializedName: 'bankName',
        type: {
          name: 'String'
        }
      },
      accountType: {
        serializedName: 'accountType',
        type: {
          name: 'String'
        }
      },
      routingNumber: {
        serializedName: 'routingNumber',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PartnerAccreditationRequest: msRest.CompositeMapper = {
  serializedName: 'PartnerAccreditationRequest',
  type: {
    name: 'Composite',
    className: 'PartnerAccreditationRequest',
    modelProperties: {
      ownerDetails: {
        serializedName: 'ownerDetails',
        type: {
          name: 'Composite',
          className: 'PartnerOwnerDetails'
        }
      },
      businessDetails: {
        serializedName: 'businessDetails',
        type: {
          name: 'Composite',
          className: 'PartnerBusinessDetails'
        }
      },
      achDetail: {
        serializedName: 'achDetail',
        type: {
          name: 'Composite',
          className: 'PartnerAchDetail'
        }
      },
      companyDetail: {
        serializedName: 'companyDetail',
        type: {
          name: 'Object'
        }
      }
    }
  }
};

export const PartnerApplicationStatus: msRest.CompositeMapper = {
  serializedName: 'PartnerApplicationStatus',
  type: {
    name: 'Composite',
    className: 'PartnerApplicationStatus',
    modelProperties: {
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const PartnerBankDetails: msRest.CompositeMapper = {
  serializedName: 'PartnerBankDetails',
  type: {
    name: 'Composite',
    className: 'PartnerBankDetails',
    modelProperties: {
      bankName: {
        serializedName: 'bankName',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      },
      routingNumber: {
        serializedName: 'routingNumber',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PartnerChannelDetail: msRest.CompositeMapper = {
  serializedName: 'PartnerChannelDetail',
  type: {
    name: 'Composite',
    className: 'PartnerChannelDetail',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      url: {
        serializedName: 'url',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PartnerChannel: msRest.CompositeMapper = {
  serializedName: 'PartnerChannel',
  type: {
    name: 'Composite',
    className: 'PartnerChannel',
    modelProperties: {
      isSlack: {
        serializedName: 'isSlack',
        type: {
          name: 'Boolean'
        }
      },
      slackChannel: {
        serializedName: 'slackChannel',
        type: {
          name: 'Composite',
          className: 'PartnerChannelDetail'
        }
      },
      isMSTeams: {
        serializedName: 'isMSTeams',
        type: {
          name: 'Boolean'
        }
      },
      msTeamsChannel: {
        serializedName: 'msTeamsChannel',
        type: {
          name: 'Composite',
          className: 'PartnerChannelDetail'
        }
      }
    }
  }
};

export const PartnerPaidCommission: msRest.CompositeMapper = {
  serializedName: 'PartnerPaidCommission',
  type: {
    name: 'Composite',
    className: 'PartnerPaidCommission',
    modelProperties: {
      partnerCommissionId: {
        serializedName: 'partnerCommissionId',
        type: {
          name: 'Uuid'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      fundedAmount: {
        serializedName: 'fundedAmount',
        type: {
          name: 'Number'
        }
      },
      paid: {
        serializedName: 'paid',
        type: {
          name: 'Boolean'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      agentName: {
        serializedName: 'agentName',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PartnerProjection: msRest.CompositeMapper = {
  serializedName: 'PartnerProjection',
  type: {
    name: 'Composite',
    className: 'PartnerProjection',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      mobilePhoneNumber: {
        serializedName: 'mobilePhoneNumber',
        type: {
          name: 'String'
        }
      },
      unsecuredTrail: {
        serializedName: 'unsecuredTrail',
        type: {
          name: 'Number'
        }
      },
      securedTrail: {
        serializedName: 'securedTrail',
        type: {
          name: 'Number'
        }
      },
      locTrail: {
        serializedName: 'locTrail',
        type: {
          name: 'Number'
        }
      },
      agentFee: {
        serializedName: 'agentFee',
        type: {
          name: 'Number'
        }
      },
      upFrontFee: {
        serializedName: 'upFrontFee',
        type: {
          name: 'Number'
        }
      },
      role: {
        serializedName: 'role',
        type: {
          name: 'String'
        }
      },
      urlReferral: {
        serializedName: 'urlReferral',
        type: {
          name: 'String'
        }
      },
      drawFee: {
        serializedName: 'drawFee',
        type: {
          name: 'Number'
        }
      },
      owner: {
        serializedName: 'owner',
        type: {
          name: 'Boolean'
        }
      },
      agentSupport: {
        serializedName: 'agentSupport',
        type: {
          name: 'Boolean'
        }
      },
      editAgentSupport: {
        serializedName: 'editAgentSupport',
        type: {
          name: 'Boolean'
        }
      },
      displayAdvances: {
        serializedName: 'displayAdvances',
        type: {
          name: 'Boolean'
        }
      },
      frequency: {
        serializedName: 'frequency',
        type: {
          name: 'Number'
        }
      },
      isPartnerAPIEnabled: {
        serializedName: 'isPartnerAPIEnabled',
        type: {
          name: 'Boolean'
        }
      },
      displayCommission: {
        serializedName: 'displayCommission',
        type: {
          name: 'Boolean'
        }
      },
      logoUrl: {
        serializedName: 'logoUrl',
        type: {
          name: 'String'
        }
      },
      showAgentCommunications: {
        serializedName: 'showAgentCommunications',
        type: {
          name: 'Boolean'
        }
      },
      hideAgentApplication: {
        serializedName: 'hideAgentApplication',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const PartnerProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'PartnerProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'PartnerProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PartnerProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PaymentQueryNote: msRest.CompositeMapper = {
  serializedName: 'PaymentQueryNote',
  type: {
    name: 'Composite',
    className: 'PaymentQueryNote',
    modelProperties: {
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      note: {
        serializedName: 'note',
        type: {
          name: 'String'
        }
      },
      output: {
        readOnly: true,
        serializedName: 'output',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PaymentQuery: msRest.CompositeMapper = {
  serializedName: 'PaymentQuery',
  type: {
    name: 'Composite',
    className: 'PaymentQuery',
    modelProperties: {
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      paymentQueryType: {
        serializedName: 'paymentQueryType',
        type: {
          name: 'String'
        }
      },
      reconciled: {
        serializedName: 'reconciled',
        type: {
          name: 'Boolean'
        }
      },
      time: {
        readOnly: true,
        serializedName: 'time',
        type: {
          name: 'Number'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PaymentQueryNote'
            }
          }
        }
      },
      paymentQueryId: {
        serializedName: 'paymentQueryId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      output: {
        readOnly: true,
        serializedName: 'output',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      transactionId: {
        serializedName: 'transactionId',
        type: {
          name: 'Uuid'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      account: {
        serializedName: 'account',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const ReconciliationTransaction: msRest.CompositeMapper = {
  serializedName: 'ReconciliationTransaction',
  type: {
    name: 'Composite',
    className: 'ReconciliationTransaction',
    modelProperties: {
      source: {
        serializedName: 'source',
        type: {
          name: 'String'
        }
      },
      reconciled: {
        serializedName: 'reconciled',
        type: {
          name: 'Boolean'
        }
      },
      request: {
        serializedName: 'request',
        type: {
          name: 'Boolean'
        }
      },
      requestList: {
        serializedName: 'requestList',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      misdirectionList: {
        serializedName: 'misdirectionList',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      enabledDebtor: {
        serializedName: 'enabledDebtor',
        type: {
          name: 'Boolean'
        }
      },
      systemReconciled: {
        serializedName: 'systemReconciled',
        type: {
          name: 'Boolean'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      },
      retrievedDate: {
        serializedName: 'retrievedDate',
        type: {
          name: 'DateTime'
        }
      },
      potentialMisdirection: {
        serializedName: 'potentialMisdirection',
        type: {
          name: 'Boolean'
        }
      },
      potentialMisdirectionDetails: {
        serializedName: 'potentialMisdirectionDetails',
        type: {
          name: 'Composite',
          className: 'PaymentQuery'
        }
      },
      misdirect: {
        serializedName: 'misdirect',
        type: {
          name: 'Boolean'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      metaDescription: {
        serializedName: 'metaDescription',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      account: {
        serializedName: 'account',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PaymentAndTransactions: msRest.CompositeMapper = {
  serializedName: 'PaymentAndTransactions',
  type: {
    name: 'Composite',
    className: 'PaymentAndTransactions',
    modelProperties: {
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      list: {
        serializedName: 'list',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ReconciliationTransaction'
            }
          }
        }
      }
    }
  }
};

export const PaymentArrangement: msRest.CompositeMapper = {
  serializedName: 'PaymentArrangement',
  type: {
    name: 'Composite',
    className: 'PaymentArrangement',
    modelProperties: {
      paymentArrangementId: {
        serializedName: 'paymentArrangementId',
        type: {
          name: 'Uuid'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'Uuid'
        }
      },
      startDate: {
        serializedName: 'startDate',
        type: {
          name: 'DateTime'
        }
      },
      endDate: {
        serializedName: 'endDate',
        type: {
          name: 'DateTime'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      fullAllocation: {
        serializedName: 'fullAllocation',
        type: {
          name: 'Boolean'
        }
      },
      interestSplit: {
        serializedName: 'interestSplit',
        type: {
          name: 'Number'
        }
      },
      principleSplit: {
        serializedName: 'principleSplit',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PaymentInvoice: msRest.CompositeMapper = {
  serializedName: 'PaymentInvoice',
  type: {
    name: 'Composite',
    className: 'PaymentInvoice',
    modelProperties: {
      amountApplied: {
        serializedName: 'amountApplied',
        type: {
          name: 'Number'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      number: {
        serializedName: 'number',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PercentOfIncomeCalculation: msRest.CompositeMapper = {
  serializedName: 'PercentOfIncomeCalculation',
  type: {
    name: 'Composite',
    className: 'PercentOfIncomeCalculation',
    modelProperties: {
      rate: {
        serializedName: 'rate',
        type: {
          name: 'Number'
        }
      },
      weeks: {
        serializedName: 'weeks',
        type: {
          name: 'Number'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      withdrawAmount: {
        serializedName: 'withdrawAmount',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      totalRepayment: {
        serializedName: 'totalRepayment',
        type: {
          name: 'Number'
        }
      },
      firstRepayment: {
        serializedName: 'firstRepayment',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsCredential: msRest.CompositeMapper = {
  serializedName: 'PlexeBankStatementsProxyModelsCredential',
  type: {
    name: 'Composite',
    className: 'PlexeBankStatementsProxyModelsCredential',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      fieldID: {
        serializedName: 'fieldID',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      description: {
        serializedName: 'description',
        type: {
          name: 'String'
        }
      },
      values: {
        serializedName: 'values',
        type: {
          name: 'String'
        }
      },
      keyboardType: {
        serializedName: 'keyboardType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsToken: msRest.CompositeMapper = {
  serializedName: 'PlexeBankStatementsProxyModelsToken',
  type: {
    name: 'Composite',
    className: 'PlexeBankStatementsProxyModelsToken',
    modelProperties: {
      accessToken: {
        serializedName: 'accessToken',
        type: {
          name: 'String'
        }
      },
      issuedAt: {
        serializedName: 'issuedAt',
        type: {
          name: 'DateTime'
        }
      },
      expiresIn: {
        serializedName: 'expiresIn',
        type: {
          name: 'Number'
        }
      },
      isValid: {
        serializedName: 'isValid',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsFastLinkToken: msRest.CompositeMapper = {
  serializedName: 'PlexeBankStatementsProxyModelsFastLinkToken',
  type: {
    name: 'Composite',
    className: 'PlexeBankStatementsProxyModelsFastLinkToken',
    modelProperties: {
      fastLinkUri: {
        serializedName: 'fastLinkUri',
        type: {
          name: 'String'
        }
      },
      token: {
        serializedName: 'token',
        type: {
          name: 'Composite',
          className: 'PlexeBankStatementsProxyModelsToken'
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsField: msRest.CompositeMapper = {
  serializedName: 'PlexeBankStatementsProxyModelsField',
  type: {
    name: 'Composite',
    className: 'PlexeBankStatementsProxyModelsField',
    modelProperties: {
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      text: {
        serializedName: 'text',
        type: {
          name: 'String'
        }
      },
      value: {
        serializedName: 'value',
        type: {
          name: 'String'
        }
      },
      values: {
        serializedName: 'values',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      fieldID: {
        serializedName: 'fieldID',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsInstitution: msRest.CompositeMapper = {
  serializedName: 'PlexeBankStatementsProxyModelsInstitution',
  type: {
    name: 'Composite',
    className: 'PlexeBankStatementsProxyModelsInstitution',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      slug: {
        serializedName: 'slug',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      credentials: {
        serializedName: 'credentials',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeBankStatementsProxyModelsCredential'
            }
          }
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      searchable: {
        serializedName: 'searchable',
        type: {
          name: 'String'
        }
      },
      display: {
        serializedName: 'display',
        type: {
          name: 'String'
        }
      },
      searchVal: {
        serializedName: 'searchVal',
        type: {
          name: 'String'
        }
      },
      region: {
        serializedName: 'region',
        type: {
          name: 'String'
        }
      },
      exportWithPassword: {
        serializedName: 'export_with_password',
        type: {
          name: 'Number'
        }
      },
      estatementsSupported: {
        serializedName: 'estatements_supported',
        type: {
          name: 'Number'
        }
      },
      transactionListingsSupported: {
        serializedName: 'transaction_listings_supported',
        type: {
          name: 'String'
        }
      },
      requiresPreload: {
        serializedName: 'requires_preload',
        type: {
          name: 'String'
        }
      },
      requiresMfa: {
        serializedName: 'requires_mfa',
        type: {
          name: 'String'
        }
      },
      updatedAt: {
        serializedName: 'updated_at',
        type: {
          name: 'String'
        }
      },
      maxDays: {
        serializedName: 'max_days',
        type: {
          name: 'String'
        }
      },
      continues: {
        serializedName: 'continues',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsMFADetails: msRest.CompositeMapper = {
  serializedName: 'PlexeBankStatementsProxyModelsMFADetails',
  type: {
    name: 'Composite',
    className: 'PlexeBankStatementsProxyModelsMFADetails',
    modelProperties: {
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      fields: {
        serializedName: 'fields',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeBankStatementsProxyModelsField'
            }
          }
        }
      },
      submitTo: {
        serializedName: 'submitTo',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsTransactionMatrix: msRest.CompositeMapper = {
  serializedName: 'PlexeOrganisationServiceModelsTransactionMatrix',
  type: {
    name: 'Composite',
    className: 'PlexeOrganisationServiceModelsTransactionMatrix',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      institutionName: {
        serializedName: 'institutionName',
        type: {
          name: 'String'
        }
      },
      accountName: {
        serializedName: 'accountName',
        type: {
          name: 'String'
        }
      },
      accountNumber: {
        serializedName: 'accountNumber',
        type: {
          name: 'String'
        }
      },
      transactionDate: {
        serializedName: 'transactionDate',
        type: {
          name: 'DateTime'
        }
      },
      dateStr: {
        serializedName: 'dateStr',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      uploaded: {
        serializedName: 'uploaded',
        type: {
          name: 'Boolean'
        }
      },
      uploadedDate: {
        serializedName: 'uploadedDate',
        type: {
          name: 'DateTime'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      isDelete: {
        serializedName: 'isDelete',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const PrimaryApplicant: msRest.CompositeMapper = {
  serializedName: 'PrimaryApplicant',
  type: {
    name: 'Composite',
    className: 'PrimaryApplicant',
    modelProperties: {
      isManuallyAdded: {
        serializedName: 'isManuallyAdded',
        type: {
          name: 'Boolean'
        }
      },
      image: {
        serializedName: 'image',
        type: {
          name: 'ByteArray'
        }
      },
      miscellaneousData: {
        serializedName: 'miscellaneousData',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      address: {
        serializedName: 'address',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      licenceState: {
        serializedName: 'licenceState',
        type: {
          name: 'String'
        }
      },
      licenceNumber: {
        serializedName: 'licenceNumber',
        type: {
          name: 'String'
        }
      },
      licenceExpiryDate: {
        serializedName: 'licenceExpiryDate',
        type: {
          name: 'DateTime'
        }
      },
      licenceName: {
        serializedName: 'licenceName',
        type: {
          name: 'String'
        }
      },
      licenceAddress: {
        serializedName: 'licenceAddress',
        type: {
          name: 'String'
        }
      },
      medicareName: {
        serializedName: 'medicareName',
        type: {
          name: 'String'
        }
      },
      medicareDate: {
        serializedName: 'medicareDate',
        type: {
          name: 'DateTime'
        }
      },
      medicareNumber: {
        serializedName: 'medicareNumber',
        type: {
          name: 'String'
        }
      },
      medicareIssue: {
        serializedName: 'medicareIssue',
        type: {
          name: 'String'
        }
      },
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      licenceCity: {
        serializedName: 'licenceCity',
        type: {
          name: 'String'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const ProcessedPayment: msRest.CompositeMapper = {
  serializedName: 'ProcessedPayment',
  type: {
    name: 'Composite',
    className: 'ProcessedPayment',
    modelProperties: {
      manualExclude: {
        serializedName: 'manualExclude',
        type: {
          name: 'Boolean'
        }
      },
      paymentKey: {
        readOnly: true,
        serializedName: 'paymentKey',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'Number'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'Number'
        }
      },
      currencyRate: {
        serializedName: 'currencyRate',
        type: {
          name: 'Number'
        }
      },
      bankAmount: {
        serializedName: 'bankAmount',
        type: {
          name: 'Number'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      reference: {
        serializedName: 'reference',
        type: {
          name: 'String'
        }
      },
      isReconciled: {
        serializedName: 'isReconciled',
        type: {
          name: 'Boolean'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      account: {
        serializedName: 'account',
        type: {
          name: 'String'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      },
      retrievedDate: {
        serializedName: 'retrievedDate',
        type: {
          name: 'DateTime'
        }
      },
      invoices: {
        serializedName: 'invoices',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PaymentInvoice'
            }
          }
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const QuestionMessageModal: msRest.CompositeMapper = {
  serializedName: 'QuestionMessageModal',
  type: {
    name: 'Composite',
    className: 'QuestionMessageModal',
    modelProperties: {
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      isReplied: {
        serializedName: 'isReplied',
        type: {
          name: 'Boolean'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      answer: {
        serializedName: 'answer',
        type: {
          name: 'Boolean'
        }
      },
      isActiveLoansOnly: {
        serializedName: 'isActiveLoansOnly',
        type: {
          name: 'Boolean'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const SyntheticPayment: msRest.CompositeMapper = {
  serializedName: 'SyntheticPayment',
  type: {
    name: 'Composite',
    className: 'SyntheticPayment',
    modelProperties: {
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      transactionId: {
        serializedName: 'transactionId',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const ReconciledPayment: msRest.CompositeMapper = {
  serializedName: 'ReconciledPayment',
  type: {
    name: 'Composite',
    className: 'ReconciledPayment',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      paymentQueryId: {
        serializedName: 'paymentQueryId',
        type: {
          name: 'Uuid'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      transactionId: {
        serializedName: 'transactionId',
        type: {
          name: 'Uuid'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      payment: {
        serializedName: 'payment',
        type: {
          name: 'Composite',
          className: 'ProcessedPayment'
        }
      },
      syntheticPayment: {
        serializedName: 'syntheticPayment',
        type: {
          name: 'Composite',
          className: 'SyntheticPayment'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      autoMatch: {
        serializedName: 'autoMatch',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const ReconciledPaymentPagedList: msRest.CompositeMapper = {
  serializedName: 'ReconciledPaymentPagedList',
  type: {
    name: 'Composite',
    className: 'ReconciledPaymentPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ReconciledPayment'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const SecondaryApplicant: msRest.CompositeMapper = {
  serializedName: 'SecondaryApplicant',
  type: {
    name: 'Composite',
    className: 'SecondaryApplicant',
    modelProperties: {
      isManuallyAdded: {
        serializedName: 'isManuallyAdded',
        type: {
          name: 'Boolean'
        }
      },
      image: {
        serializedName: 'image',
        type: {
          name: 'ByteArray'
        }
      },
      miscellaneousData: {
        serializedName: 'miscellaneousData',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      dateOfBirth: {
        serializedName: 'dateOfBirth',
        type: {
          name: 'DateTime'
        }
      },
      address: {
        serializedName: 'address',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      licenceState: {
        serializedName: 'licenceState',
        type: {
          name: 'String'
        }
      },
      licenceNumber: {
        serializedName: 'licenceNumber',
        type: {
          name: 'String'
        }
      },
      licenceExpiryDate: {
        serializedName: 'licenceExpiryDate',
        type: {
          name: 'DateTime'
        }
      },
      licenceName: {
        serializedName: 'licenceName',
        type: {
          name: 'String'
        }
      },
      licenceAddress: {
        serializedName: 'licenceAddress',
        type: {
          name: 'String'
        }
      },
      medicareName: {
        serializedName: 'medicareName',
        type: {
          name: 'String'
        }
      },
      medicareDate: {
        serializedName: 'medicareDate',
        type: {
          name: 'DateTime'
        }
      },
      medicareNumber: {
        serializedName: 'medicareNumber',
        type: {
          name: 'String'
        }
      },
      medicareIssue: {
        serializedName: 'medicareIssue',
        type: {
          name: 'String'
        }
      },
      isPrimary: {
        serializedName: 'isPrimary',
        type: {
          name: 'Boolean'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      licenceCity: {
        serializedName: 'licenceCity',
        type: {
          name: 'String'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const SendLinkRequest: msRest.CompositeMapper = {
  serializedName: 'SendLinkRequest',
  type: {
    name: 'Composite',
    className: 'SendLinkRequest',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      ein: {
        serializedName: 'ein',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      mobile: {
        serializedName: 'mobile',
        type: {
          name: 'String'
        }
      },
      createdDate: {
        serializedName: 'createdDate',
        type: {
          name: 'DateTime'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const SiteDetails: msRest.CompositeMapper = {
  serializedName: 'SiteDetails',
  type: {
    name: 'Composite',
    className: 'SiteDetails',
    modelProperties: {
      items: {
        serializedName: 'items',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      images: {
        serializedName: 'images',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      urls: {
        serializedName: 'urls',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      },
      returnUrl: {
        serializedName: 'returnUrl',
        type: {
          name: 'String'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      darkThemeEnabled: {
        serializedName: 'darkThemeEnabled',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const SkipSchedule: msRest.CompositeMapper = {
  serializedName: 'SkipSchedule',
  type: {
    name: 'Composite',
    className: 'SkipSchedule',
    modelProperties: {
      repaymentDate: {
        serializedName: 'repaymentDate',
        type: {
          name: 'DateTime'
        }
      },
      nextRepaymentDate: {
        serializedName: 'nextRepaymentDate',
        type: {
          name: 'DateTime'
        }
      },
      repaymentAmount: {
        serializedName: 'repaymentAmount',
        type: {
          name: 'Number'
        }
      },
      nextRepaymentAmount: {
        serializedName: 'nextRepaymentAmount',
        type: {
          name: 'Number'
        }
      },
      lastDate: {
        serializedName: 'lastDate',
        type: {
          name: 'DateTime'
        }
      },
      skipFee: {
        serializedName: 'skipFee',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const SkipRepaymentCalculation: msRest.CompositeMapper = {
  serializedName: 'SkipRepaymentCalculation',
  type: {
    name: 'Composite',
    className: 'SkipRepaymentCalculation',
    modelProperties: {
      current: {
        serializedName: 'current',
        type: {
          name: 'Composite',
          className: 'SkipSchedule'
        }
      },
      next: {
        serializedName: 'next',
        type: {
          name: 'Composite',
          className: 'SkipSchedule'
        }
      }
    }
  }
};

export const SliderResult: msRest.CompositeMapper = {
  serializedName: 'SliderResult',
  type: {
    name: 'Composite',
    className: 'SliderResult',
    modelProperties: {
      position: {
        serializedName: 'position',
        type: {
          name: 'Number'
        }
      },
      percentageOfIncome: {
        serializedName: 'percentageOfIncome',
        type: {
          name: 'Number'
        }
      },
      term: {
        serializedName: 'term',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const StatusSummary: msRest.CompositeMapper = {
  serializedName: 'StatusSummary',
  type: {
    name: 'Composite',
    className: 'StatusSummary',
    modelProperties: {
      partnerBusinessName: {
        serializedName: 'partnerBusinessName',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      count: {
        serializedName: 'count',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const Summary: msRest.CompositeMapper = {
  serializedName: 'Summary',
  type: {
    name: 'Composite',
    className: 'Summary',
    modelProperties: {
      loan: {
        serializedName: 'loan',
        type: {
          name: 'Composite',
          className: 'CustomerLoanProjection'
        }
      },
      application: {
        serializedName: 'application',
        type: {
          name: 'Composite',
          className: 'ApplicationListProjection'
        }
      },
      quickUrl: {
        serializedName: 'quickUrl',
        type: {
          name: 'String'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'Composite',
          className: 'Customer'
        }
      },
      customers: {
        serializedName: 'customers',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Customer'
            }
          }
        }
      },
      partner: {
        serializedName: 'partner',
        type: {
          name: 'Composite',
          className: 'Partner'
        }
      },
      organisation: {
        serializedName: 'organisation',
        type: {
          name: 'Composite',
          className: 'OrganisationProjection'
        }
      },
      applicants: {
        serializedName: 'applicants',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicantProjection'
            }
          }
        }
      },
      agent: {
        serializedName: 'agent',
        type: {
          name: 'Composite',
          className: 'Agent'
        }
      }
    }
  }
};

export const SupportDocumentDetails: msRest.CompositeMapper = {
  serializedName: 'SupportDocumentDetails',
  type: {
    name: 'Composite',
    className: 'SupportDocumentDetails',
    modelProperties: {
      label: {
        serializedName: 'label',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      submittedDate: {
        serializedName: 'submittedDate',
        type: {
          name: 'DateTime'
        }
      },
      submitted: {
        serializedName: 'submitted',
        type: {
          name: 'Boolean'
        }
      },
      forSecondaryAlso: {
        serializedName: 'forSecondaryAlso',
        type: {
          name: 'Boolean'
        }
      },
      locations: {
        serializedName: 'locations',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'FileDetails'
            }
          }
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const SupportDocumentDetailsPagedList: msRest.CompositeMapper = {
  serializedName: 'SupportDocumentDetailsPagedList',
  type: {
    name: 'Composite',
    className: 'SupportDocumentDetailsPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SupportDocumentDetails'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const SystemMessageModal: msRest.CompositeMapper = {
  serializedName: 'SystemMessageModal',
  type: {
    name: 'Composite',
    className: 'SystemMessageModal',
    modelProperties: {
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const TransactionDataFile: msRest.CompositeMapper = {
  serializedName: 'TransactionDataFile',
  type: {
    name: 'Composite',
    className: 'TransactionDataFile',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'ByteArray'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      fileType: {
        serializedName: 'fileType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const TransactionFile: msRest.CompositeMapper = {
  serializedName: 'TransactionFile',
  type: {
    name: 'Composite',
    className: 'TransactionFile',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'String'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      fileType: {
        serializedName: 'fileType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const TransactionProjection: msRest.CompositeMapper = {
  serializedName: 'TransactionProjection',
  type: {
    name: 'Composite',
    className: 'TransactionProjection',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      overrideId: {
        serializedName: 'overrideId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'String'
        }
      },
      advanceName: {
        serializedName: 'advanceName',
        type: {
          name: 'String'
        }
      },
      dateUTC: {
        serializedName: 'dateUTC',
        type: {
          name: 'DateTime'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amountValue: {
        serializedName: 'amount_Value',
        type: {
          name: 'Number'
        }
      },
      amountCurrencyCode: {
        serializedName: 'amount_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      balanceValue: {
        serializedName: 'balance_Value',
        type: {
          name: 'Number'
        }
      },
      balanceCurrencyCode: {
        serializedName: 'balance_CurrencyCode',
        type: {
          name: 'String'
        }
      },
      reconciled: {
        serializedName: 'reconciled',
        type: {
          name: 'Boolean'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      batchName: {
        serializedName: 'batchName',
        type: {
          name: 'String'
        }
      },
      batchDate: {
        serializedName: 'batchDate',
        type: {
          name: 'DateTime'
        }
      },
      batchId: {
        serializedName: 'batchId',
        type: {
          name: 'Uuid'
        }
      },
      isCredit: {
        serializedName: 'isCredit',
        type: {
          name: 'Boolean'
        }
      },
      bankReceipt: {
        serializedName: 'bankReceipt',
        type: {
          name: 'String'
        }
      },
      force: {
        serializedName: 'force',
        type: {
          name: 'Boolean'
        }
      },
      typeDisplay: {
        readOnly: true,
        serializedName: 'typeDisplay',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const TransactionProjectionPagedList: msRest.CompositeMapper = {
  serializedName: 'TransactionProjectionPagedList',
  type: {
    name: 'Composite',
    className: 'TransactionProjectionPagedList',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionProjection'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const UsIndustryRecord: msRest.CompositeMapper = {
  serializedName: 'UsIndustryRecord',
  type: {
    name: 'Composite',
    className: 'UsIndustryRecord',
    modelProperties: {
      sic4Cd: {
        serializedName: 'sic4_cd',
        type: {
          name: 'Number'
        }
      },
      sic4Desc: {
        serializedName: 'sic4_desc',
        type: {
          name: 'String'
        }
      },
      indCd: {
        serializedName: 'ind_cd',
        type: {
          name: 'Number'
        }
      },
      indDesc: {
        serializedName: 'ind_desc',
        type: {
          name: 'String'
        }
      },
      majCd: {
        serializedName: 'maj_cd',
        type: {
          name: 'Number'
        }
      },
      majDesc: {
        serializedName: 'maj_desc',
        type: {
          name: 'String'
        }
      },
      divCd: {
        serializedName: 'div_cd',
        type: {
          name: 'String'
        }
      },
      divDesc: {
        serializedName: 'div_desc',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const WhiteLabelSiteDetail: msRest.CompositeMapper = {
  serializedName: 'WhiteLabelSiteDetail',
  type: {
    name: 'Composite',
    className: 'WhiteLabelSiteDetail',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      urls: {
        serializedName: 'urls',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      },
      returnUrl: {
        serializedName: 'returnUrl',
        type: {
          name: 'String'
        }
      },
      partnerId: {
        serializedName: 'partnerId',
        type: {
          name: 'Uuid'
        }
      },
      partnerName: {
        serializedName: 'partnerName',
        type: {
          name: 'String'
        }
      },
      agentId: {
        serializedName: 'agentId',
        type: {
          name: 'Uuid'
        }
      },
      agentName: {
        serializedName: 'agentName',
        type: {
          name: 'String'
        }
      },
      darkThemeEnabled: {
        serializedName: 'darkThemeEnabled',
        type: {
          name: 'String'
        }
      }
    }
  }
};
