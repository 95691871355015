import { Injectable } from '@angular/core';
import { OriginationReadApi } from '@app/core/proxy/Read/originationReadApi';
import { Data, DataState, DataStore } from '@app/DataStore';
import { Subject } from 'rxjs';
import {
  Agent,
  ApplicationListProjection,
  ApplicationSettings,
  BankAccount,
  BankAccountConnectionSettings,
  CommunicationProjectionExtension,
  DashboardAccounts,
  IncomeDetails,
  OfferDetails,
  PlexeOrganisationServiceModelsTransactionMatrix,
  SupportDocumentDetails
} from '../proxy/Read/models';
import {
  AccountingData,
  BankingData,
  Commission,
  Communication,
  CompleteContractRequest,
  CreateApplicationAndCustomerRequest,
  SendLinkRequest,
  StartApplicationRequest,
  BankAccountConnectionDetails,
  AgentNotes,
  CreateApplicationAndCustomerAndSupportDocumentsRequest,
  TransactionDataFile
} from '../proxy/Write/models';
import { OriginationWriteApi } from '../proxy/Write/originationWriteApi';
import { Logger } from './logger.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApplicationService {
  private incomeDetailsStatusSubject = new Subject<boolean>();

  constructor(
    private readApi: OriginationReadApi,
    private writeApi: OriginationWriteApi,
    private toastrService: ToastrService
  ) {}

  retry(retry, callback: Function) {
    var logger = new Logger();
    if (retry && localStorage.getItem('e_retry') === 'true') {
      logger.error(`${callback.name}: Retry call.`);
      localStorage.setItem('e_retry', 'false');
      callback();
      return true;
    } else {
      logger.error(`${callback.name}: api failed to process.`);
      return false;
    }
  }

  setRetry(retry) {
    if (retry) {
      localStorage.setItem('e_retry', 'true');
    }
  }

  //  ____   ___  ____ _____
  // |  _ \ / _ \/ ___|_   _|
  // | |_) | | | \___ \ | |
  // |  __/| |_| |___) || |
  // |_|    \___/|____/ |_|

  bankAccountConnectionSettings(isPlaid = false, isYodlee = false, enablePlaidMultibank = true) {
    return this.writeApi
      .bankAccountConnectionSettings({
        isPlaid: isPlaid,
        isYodlee: isYodlee,
        enablePlaidMultibank: enablePlaidMultibank
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  sentToCustomer(applicationId: string) {
    return this.writeApi
      .sendCustomerOffer(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  addUpdatePartnerCommunication(communication: CommunicationProjectionExtension) {
    return this.writeApi
      .addUpdatePartnerCommunication({ body: communication, sendToAgent: true })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  deleteBankStatementMissingTransactionDocument(organisationId: string, fileName: string) {
    return this.writeApi
      .deleteBankStatementMissingTransactionDocument(organisationId, { fileName: fileName })
      .then(res => {
        return res;
      })
      .catch(err => {});
  }

  bankStatementUploadComplete(organisationId: string) {
    return this.writeApi
      .bankStatementUploadComplete(organisationId)
      .then(res => {
        return res;
      })
      .catch(err => {});
  }

  uploadBankStatementMissingTransactionDocument(organisationId: string, missingTransactionId: string, file: any) {
    return this.writeApi
      .uploadBankStatementMissingTransactionDocument(organisationId, {
        missingTransactionId: missingTransactionId,
        uploadedFile: file
      })
      .then(res => {
        return res;
      })
      .catch(err => {});
  }

  confirmBankfeedMFADetails(
    applicationId: string,
    params: {
      body: any;
      uri: string;
      yodlee: boolean;
    },
    retry = true
  ) {
    this.setRetry(retry);
    return this.writeApi
      .confirmBankfeedMFADetails(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.confirmBankfeedMFADetails(applicationId, params, false));
      });
  }

  connectBankfeedAccount(applicationId: string, params: { body: BankingData }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .connectBankfeedAccount(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.connectBankfeedAccount(applicationId, params, false));
      });
  }

  sendLinkForExisting(partnerId: string, params: { applicationId: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .sendLinkForExisting(partnerId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.sendLinkForExisting(partnerId, params, false));
      });
  }

  resendPartnerSendLink(partnerId: string, params: { requestId: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .resendSendLink1(partnerId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.resendPartnerSendLink(partnerId, params, false));
      });
  }

  resendAgentSendLink(agentId: string, params: { requestId: string; partnerId: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .resendSendLink(agentId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.resendPartnerSendLink(agentId, params, false));
      });
  }

  sendPartnerLink(partnerId: string, params: { body: SendLinkRequest }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .sendLink1(partnerId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.sendPartnerLink(partnerId, params, false));
      });
  }

  sendAgentLink(partnerId: string, agentId: string, body: SendLinkRequest, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .sendLink(agentId, { body: body, partnerId: partnerId })
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.sendAgentLink(partnerId, agentId, body, false));
      });
  }

  sendConnectionLink(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .sendConnectionLink(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.sendConnectionLink(applicationId, false));
      });
  }

  sendCustomerApplication(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .sendCustomerApplication(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.sendCustomerApplication(applicationId, false));
      });
  }

  confirmBankfeedAccounts(applicationId: string, params: { body: BankAccount[] }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .confirmBankfeedAccounts(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.confirmBankfeedAccounts(applicationId, params, false));
      });
  }

  confirmBankfeedYodlee(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .confirmBankfeedYodlee(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.confirmBankfeedYodlee(applicationId, false));
      });
  }

  confirmBankfeedPlaid(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .confirmBankfeedPlaid(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.confirmBankfeedPlaid(applicationId, false));
      });
  }

  updatedContractCompleted(applicationId: string, params: { body: CompleteContractRequest }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .updatedContractCompleted(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.updatedContractCompleted(applicationId, params, false));
      });
  }

  submittedApplication(applicationId: string, params: { sms: boolean; email: boolean; comment: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .submittedApplication(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.submittedApplication(applicationId, params, false));
      });
  }

  commissionMethod(applicationId: string, params: { body: Commission; secured: number }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .commissionMethod(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.commissionMethod(applicationId, params, false));
      });
  }

  reconnectAccountingData(params: { body: AccountingData; host: string; loanId: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .reconnectAccountingData(params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.reconnectAccountingData(params, false));
      });
  }

  reprocessAccountingDataCommand(params: { loanId: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .reprocessAccountingDataCommand(params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.reprocessAccountingDataCommand(params, false));
      });
  }
  // {
  //   supportingDocumentId: document.id,
  //   fileData: array,
  //   fileName: file.name,
  //   fileType: file.type
  // }

  createApplication(params: { body: StartApplicationRequest }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .createApplication(params)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.createApplication(params, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  createApplicationAndCustomerAndSupportDocuments(
    params: { body: CreateApplicationAndCustomerAndSupportDocumentsRequest },
    retry = true
  ) {
    this.setRetry(retry);
    return this.writeApi
      .createApplicationAndCustomerAndSupportDocuments(params)
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.createApplicationAndCustomerAndSupportDocuments(params, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  connectPlaidBankfeedAccount(applicationId: string, connect: BankAccountConnectionDetails, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .connectPlaidBankfeedAccount(applicationId, connect)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.connectPlaidBankfeedAccount(applicationId, connect, false));
      });
  }

  connectYodleeBankfeedAccount(applicationId: string, connect: BankAccountConnectionDetails, retry = true) {
    this.setRetry(retry);
    connect.providerId = connect.providerId.toString();
    connect.accountId = connect.accountId.toString();

    return this.writeApi
      .connectYodleeBankfeedAccount(applicationId, connect)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.connectYodleeBankfeedAccount(applicationId, connect, false));
      });
  }

  reconnectYodleeBankfeedAccount(applicationId: string) {
    return this.writeApi
      .reconnectYodleeBankfeedAccount(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  registerCommunication(applicationId: string, params: { body: Communication }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .registerCommunication(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.registerCommunication(applicationId, params, false));
      });
  }

  connectAccountingCompanyWithDetails(applicationId: string, params: { body: any }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .connectAccountingCompanyWithDetails(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.connectAccountingCompanyWithDetails(applicationId, params, false));
      });
  }

  connectAccountingCompany(applicationId: string, params: { companyUri: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .connectAccountingCompany(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () =>
          this.connectAccountingCompanyWithDetails(applicationId, { body: params.companyUri }, false)
        );
      });
  }

  linkAccoutingData(applicationId: string, params: { body: AccountingData; host: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .linkAccoutingData(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.linkAccoutingData(applicationId, params, false));
      });
  }

  createApplicationAndCustomer(params: { body: CreateApplicationAndCustomerRequest }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .createApplicationAndCustomer(params)
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.createApplicationAndCustomer(params, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  sendApplicationOtp(params: { number: string }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .sendApplicationOtp(params)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        this.retry(retry, () => this.sendApplicationOtp(params, false));
      });
  }

  addSupportingDocument(
    applicationId: string,
    params: {
      supportingDocumentId: string;
      body: Uint8Array;
      fileName: string;
      fileType: string;
      isSecondary: boolean;
    },
    retry = true
  ) {
    this.setRetry(retry);
    return this.writeApi
      .addSupportingDocument(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.addSupportingDocument(applicationId, params, false));
      });
  }

  contractComplete(
    applicationId: string,
    params: { request: CompleteContractRequest },
    applicantId: string = null,
    retry = true
  ) {
    if (!params) {
      params = { request: null };
    }
    this.setRetry(retry);
    if (params.request.secondaryApplicant) {
      applicantId = DataStore.applicantId;
    }
    return this.writeApi
      .contractComplete(applicationId, { body: params.request, applicantId: applicantId })
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.contractComplete(applicationId, params, applicantId, false));
      });
  }

  refer(applicationId: string, params: { sms: boolean; email: boolean }, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .refer(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.refer(applicationId, params, false));
      });
  }

  completeApplication(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .completeApplication(applicationId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        this.retry(retry, () => this.completeApplication(applicationId, false));
      });
  }

  updateApplicationCurrentProcess(applicationId: string, applicantId: string = null, status: number = 5, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .updateApplicationCurrentProcess(applicationId, { applicantId: applicantId, status: status })
      .then(res => {
        return res.body;
      })
      .catch(err => {
        this.retry(retry, () => this.updateApplicationCurrentProcess(applicationId, applicantId, status, false));
      });
  }

  completed(applicationId: string, params: { sms: boolean; email: boolean } = null, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .completed(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.completed(applicationId, params, false));
      });
  }

  updateBusinessName(applicationId: string, businessName: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .updateBusinessName(applicationId, { businessName })
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.updateBusinessName(applicationId, businessName, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  cancelApplication(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .cancelApplication(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.cancelApplication(applicationId, false));
      });
  }

  // {
  //   details: {
  //     zipcode: this.companyDetailsForm.value.zipcode,
  //     legalEntityType: this.companyDetailsForm.value.legalEntityType,
  //     businessTaxId: this.companyDetailsForm.value.businessTaxId
  //       ? this.companyDetailsForm.value.businessTaxId.replace('-', '')
  //       : '',
  //     city: this.companyDetailsForm.value.city
  //   }
  // })
  updateBusinessDetails(applicationId: string, companyDetails: any, retry = true) {
    this.setBusinessName(null);
    this.setRetry(retry);
    return this.writeApi
      .updateBusinessDetails(applicationId, {
        body: {
          zipcode: companyDetails.zipcode,
          legalEntityType: companyDetails.legalEntityType,
          businessTaxId: companyDetails.businessTaxId ? companyDetails.businessTaxId.replace('-', '') : '',
          city: companyDetails.city
        }
      })
      .then(res => {
        if (res.body && res.body != '') {
          this.setBusinessName(companyDetails.legalBusinessName);
        }
        return res.body;
      })
      .catch(err => {
        this.setBusinessName(null);
        this.retry(retry, () => this.updateBusinessDetails(applicationId, companyDetails, false));
      });
  }

  addAgent(partnerId: string, agent: Agent, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .addAgent(partnerId, { body: agent })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.addAgent(partnerId, agent, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  removeAgent(partnerId: string, id: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .removeAgent(partnerId, { agentId: id })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.removeAgent(partnerId, id, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  updateAgent(partnerId: string, agent: Agent, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .editAgent(partnerId, { body: agent })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.updateAgent(partnerId, agent, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  disableAgent(partnerId: string, agentId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .disableAgent(partnerId, { agentId: agentId })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.disableAgent(partnerId, agentId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  enableAgent(partnerId: string, agentId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .enableAgent(partnerId, { agentId: agentId })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.enableAgent(partnerId, agentId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  updateAgentNote(partnerId: string, agentId: string, noteId: string, note: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .editAgentNote(partnerId, { agentId: agentId, noteId: noteId, note: note })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.updateAgentNote(partnerId, agentId, noteId, note, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  removeAgentNote(partnerId: string, agentId: string, noteId: string, retry = true) {
    this.setRetry(retry);
    return this.writeApi
      .removeAgentNote(partnerId, { agentId: agentId, noteId: noteId })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.removeAgentNote(partnerId, agentId, noteId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  removeSupportingDocument(applicationId: string, documentId: string, locationId: string) {
    return this.writeApi
      .removeSupportingDocument(applicationId, { documentId: documentId, locationId: locationId })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  //   ____ _____ _____
  //  / ___| ____|_   _|
  // | |  _|  _|   | |
  // | |_| | |___  | |
  //  \____|_____| |_|

  isExistingCustomerEmail(email: string) {
    return this.readApi
      .isExistingCustomerEmail({ email: email })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  isExistingCustomerPhone(phone: string) {
    return this.readApi
      .isExistingCustomerPhone({ phone: phone })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  isApplicantImageCaptureEnabled() {
    return this.readApi
      .isApplicantImageCaptureEnabled()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  getFrontendBaseUrl() {
    return this.readApi
      .getFrontendBaseUrl()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  loadCommissionDetails(
    applicationId: string,
    params: { secured: number; establishmentFeeRate: number; commissionRate: number },
    retry = true
  ) {
    this.setRetry(retry);
    return this.readApi
      .getCommissionDetails(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.loadCommissionDetails(applicationId, params, false));
      });
  }

  loadAccountingAuthorizeUrl(systemCode: string, organisationId: string, host: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getAccountingAuthorizeUrl(systemCode, organisationId, { host: host })
      .then(res => {
        return res.body;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadAccountingAuthorizeUrl(systemCode, organisationId, host, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadSupportedAccountingSoftware(retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getSupportedAccountingSoftware()
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadSupportedAccountingSoftware(false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadBankfeedMFADetails(applicationId: string, youdlee: boolean, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getBankfeedMFADetails(applicationId, { yodlee: youdlee })
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadBankfeedMFADetails(applicationId, youdlee, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadBankfeedPlaidLink(applicationId: string, isNew: boolean = true, requestId: string = null, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getBankfeedPlaidLink(applicationId, {
        redirectUri: window.location.origin + '/plaid',
        isNew: isNew,
        requestId: requestId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadBankfeedPlaidLink(applicationId, isNew, requestId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadBankfeedPlaidLinkDemo(retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getBankfeedPlaidLinkDemo()
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadBankfeedPlaidLinkDemo(false));
        if (!isRetry) {
          return err;
        }
      });
  }

  reconnectPlaidBankfeedAccount(applicationId: string) {
    return this.writeApi
      .reconnectPlaidBankfeedAccount(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  loadBankfeedFaskLink(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getBankfeedFaskLink(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadBankfeedFaskLink(applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  validateOtp(otp: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .validateOtp(otp)
      .then(res => {
        return res.value;
      })
      .catch(err => {
        this.retry(retry, () => this.validateOtp(otp, false));
      });
  }

  debtorSummaryOnApplicationId(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .debtorSummaryOnApplicationId(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.debtorSummaryOnApplicationId(applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  isOfferReady(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .isOfferReady(applicationId)
      .then(res => {
        return res.value;
      })
      .catch(err => {
        this.retry(retry, () => this.isOfferReady(applicationId, false));
      });
  }

  loadOfferId(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getOfferId(applicationId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadOfferId(applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  applicationConnectionStatus(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .applicationConnectionStatus(applicationId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        this.retry(retry, () => this.applicationConnectionStatus(applicationId, false));
      });
  }

  loadQuickUrl(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getQuickUrl(applicationId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadQuickUrl(applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadSendLinkUrl(partnerId: string, applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getSendLinkUrl(partnerId, { requestId: applicationId })
      .then(res => {
        return res.body;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadSendLinkUrl(partnerId, applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadAgentSendLinkUrl(partnerId: string, agentId: string, applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getAgentSendLinkUrl(agentId, { requestId: applicationId, partnerId: partnerId })
      .then(res => {
        return res.body;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadAgentSendLinkUrl(partnerId, agentId, applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  // loadBusinessName(abn: string, retry = true) {
  //   this.setRetry(retry);
  //   return this.readApi
  //     .getBusinessName(abn)
  //     .then(res => {
  //       return res.body;
  //     })
  //     .catch(err => {
  //       this.retry(retry, () => this.loadBusinessName(abn, false));
  //     });
  // }

  // loadBusinessNameDetails(abn: string, retry = true) {
  //   this.setRetry(retry);
  //   return this.readApi
  //     .getBusinessNameDetails(abn)
  //     .then(res => {
  //       return res;
  //     })
  //     .catch(err => {
  //       this.retry(retry, () => this.loadBusinessNameDetails(abn, false));
  //     });
  // }

  loadPartnerSentLinkRequest(partnerId: string, applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getPartnerSentLinkRequest(partnerId, { requestId: applicationId })
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadPartnerSentLinkRequest(partnerId, applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadAgentSentLinkRequest(agentId: string, applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getAgentSentLinkRequest(agentId, { requestId: applicationId })
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadAgentSentLinkRequest(agentId, applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadCommunication(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getCommunication(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadCommunication(applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  hasLoggedIn(id: string, retry = true) {
    this.setRetry(retry);
    this.setHasLoggedIn(false);
    return this.readApi
      .hasLoggedIn(id)
      .then(res => {
        this.setHasLoggedIn(res.value);
        return res.value;
      })
      .catch(err => {
        this.setHasLoggedIn(false);
        var isRetry = this.retry(retry, () => this.hasLoggedIn(id, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadApplicationByApplicationId(id: string) {
    this.loadApplicationSettings(id);
    return this.loadApplication(id);
  }

  loadApplication(id: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getApplication(id)
      .then(res => {
        const authorizedResult = res as any;
        if (authorizedResult.statusCode == 401) {
          this.toastrService.error('Error while getting application details, redirecting to home page.', 'Error');
          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
        } else {
          if (res) {
            this.setApplication(res);
          }
          return res;
        }
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadApplication(id, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadApplicationSettings(id: string, retry = true) {
    this.setApplicationSettings(null);
    this.setRetry(retry);
    return this.readApi
      .getApplicationSettings(id)
      .then(res => {
        this.setApplicationSettings(res);
        return res;
      })
      .catch(err => {
        this.setApplicationSettings(null);
        this.retry(retry, () => this.loadApplicationSettings(id, false));
      });
  }

  loadApplicationByShortCode(shortCode: string, retry = true) {
    this.setRetry(retry);
    this.resetApplication();
    return this.readApi
      .getApplicationOnShortCode(shortCode)
      .then(res => {
        if (res) {
          this.setApplication(res);
        }
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.loadApplicationByShortCode(shortCode, false));
      });
  }

  loadApplicationBankAccountsByShortCode(shortCode: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getApplicationIdOnShortCode(shortCode)
      .then(res => {
        this.loadBankAccounts(res.body);
      })
      .catch(err => {
        this.retry(retry, () => this.loadApplicationBankAccountsByShortCode(shortCode, false));
      });
  }

  loadBankAccounts(applicationId: string, retry = true) {
    this.setRetry(retry);
    this.setApplicationBankAccounts(null);
    return this.readApi
      .getBankfeedAccounts(applicationId)
      .then(res => {
        if (res && res._response) {
          this.setApplicationBankAccounts(res._response.parsedBody);
        }
        return res;
      })
      .catch(err => {
        this.setApplicationBankAccounts(null);
        const isRetry = this.retry(retry, () => this.loadBankAccounts(applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadIncomeDetails(id: string, retry = true) {
    this.setRetry(retry);
    this.incomeDetailsStatusSubject.next(false);
    this.setIncomeDetails(null);
    return this.readApi
      .incomeDetailsForApplication(id)
      .then(res => {
        this.setIncomeDetails(res);
        this.incomeDetailsStatusSubject.next(true);
        return res;
      })
      .catch(err => {
        this.setIncomeDetails(null);
        this.incomeDetailsStatusSubject.next(false);
        this.retry(retry, () => this.loadIncomeDetails(id, false));
      });
  }

  subscribeIncomeDetails() {
    return this.incomeDetailsStatusSubject;
  }

  loadOfferDetails(id: string, retry = true) {
    this.setRetry(retry);
    this.setOfferDetails(null);
    return this.readApi
      .getOfferDetails(id)
      .then(res => {
        this.setOfferDetails(res);
        return res;
      })
      .catch(err => {
        this.setOfferDetails(null);
        var isRetry = this.retry(retry, () => this.loadOfferDetails(id, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  // loadPersonaSetting(retry = true) {
  //   this.setRetry(retry);
  //   return this.readApi
  //     .getPersonaSetting()
  //     .then(res => {
  //       return res;
  //     })
  //     .catch(err => {
  //       var isRetry = this.retry(retry, () => this.loadPersonaSetting(false));
  //       if (!isRetry) {
  //         return err;
  //       }
  //     });
  // }

  // savePersonaInquiryId(applicationId: string, inquiryId: string, retry = true) {
  //   this.setRetry(retry);
  //   return this.writeApi
  //     .savePersonaInquiryId({ applicationId: applicationId, inquiryId: inquiryId })
  //     .then(res => {
  //       return res;
  //     })
  //     .catch(err => {
  //       var isRetry = this.retry(retry, () => this.savePersonaInquiryId(applicationId, inquiryId, false));
  //       if (!isRetry) {
  //         return err;
  //       }
  //     });
  // }

  isZipCodeValid(zipcode: number, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .isZipCodeValid(zipcode)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.isZipCodeValid(zipcode, false));
      });
  }

  isNotAllowedAddress(address: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .isNotAllowedAddress({ address })
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.isNotAllowedAddress(address, false));
      });
  }

  loadSupportingDocumentRequest(
    applicationId: string,
    refreshData: boolean = true,
    isSecondary: boolean = false,
    retry = true
  ) {
    this.setRetry(retry);
    if (refreshData) {
      this.setSupportingDocumentDetails(null);
    }
    return this.readApi
      .getSupportingDocumentRequest(applicationId, { isSecondary: isSecondary })
      .then(res => {
        this.setSupportingDocumentDetails(res);
        return res;
      })
      .catch(err => {
        this.setSupportingDocumentDetails(null);
        var isRetry = this.retry(retry, () => this.loadSupportingDocumentRequest(applicationId, refreshData, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  isSupportedEnabled(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .isSupportedEnabled(applicationId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        this.retry(retry, () => this.isSupportedEnabled(applicationId, false));
      });
  }

  loadApplicationCurrentProcess(
    applicationId: string,
    applicantId: string = null,
    isSecondary: boolean = false,
    retry = true
  ) {
    this.setRetry(retry);
    if (isSecondary) {
      this.setSecondaryApplicationCurrentProcess(null);
    } else {
      this.setPrimaryApplicationCurrentProcess(null);
    }
    return this.readApi
      .getApplicationCurrentProcess(applicationId, { applicantId: applicantId, isSecondary: isSecondary })
      .then(res => {
        if (isSecondary) {
          this.setSecondaryApplicationCurrentProcess(res.body);
        } else {
          this.setPrimaryApplicationCurrentProcess(res.body);
        }
        return res.body;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadApplicationCurrentProcess(applicationId, applicantId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  calculateIndicativeFixedRepayment(applicationId: string, params: { amount?: number; terms?: number }, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .calculateIndicativeFixedRepayment(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.calculateIndicativeFixedRepayment(applicationId, params, false));
      });
  }

  calculateIndicativeInvoicePayment(
    applicationId: string,
    params: { amount?: number; overrideCalculation?: boolean },
    retry = true
  ) {
    this.setRetry(retry);
    return this.readApi
      .calculateIndicativeInvoicePayment(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.calculateIndicativeInvoicePayment(applicationId, params, false));
      });
  }

  calculateIndicativeLineOfCreditRepayment(
    applicationId: string,
    params: { amount?: number; percentOfIncome?: number; isFixedRepayment?: boolean; terms?: number },
    retry = true
  ) {
    this.setRetry(retry);
    return this.readApi
      .calculateIndicativeLineOfCreditRepayment(
        applicationId,
        params.amount,
        params.percentOfIncome,
        params.isFixedRepayment,
        params.terms
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.calculateIndicativeLineOfCreditRepayment(applicationId, params, false));
      });
  }

  calculateIndicativeLineOfCreditSlider(applicationId: string, amount: number, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .calculateIndicativeLineOfCreditSlider(applicationId, amount)
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.calculateIndicativeLineOfCreditSlider(applicationId, amount, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  calculateIndicativePercentOfIncome(
    applicationId: string,
    params: { amount?: number; percent?: number },
    retry = true
  ) {
    this.setRetry(retry);
    return this.readApi
      .calculateIndicativePercentOfIncome(applicationId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.calculateIndicativePercentOfIncome(applicationId, params, false));
      });
  }

  loadSingleApplication(customerId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getSingleApplication(customerId)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.loadSingleApplication(customerId, false));
      });
  }

  loadPlatformAgreementSettings() {
    this.setPlatformSettings(null);
    return this.writeApi
      .getPlatformAgreementSettings()
      .then(res => {
        this.setPlatformSettings(res);
        return res;
      })
      .catch(err => {
        this.setPlatformSettings(null);
        return err;
      });
  }

  getPartnerApplicationStatus(applicationId: string) {
    return this.readApi
      .getPartnerApplicationStatus({ applicationId: applicationId })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  getAllPartnerApplicationStatusTypes() {
    return this.readApi
      .getAllPartnerApplicationStatusTypes()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  getBankAccountConnectionDetails(applicationId: string) {
    return this.readApi
      .getBankAccountConnectionDetails(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  setPartnerApplicationStatus(applicationId: string, details: string) {
    return this.writeApi
      .setPartnerApplicationStatus({ applicationId: applicationId, details: details })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  loadCurrentProcess(
    isUSRegion: boolean,
    application: any,
    currentPage: string,
    isSecured = false,
    applicantId = null,
    isSecondary = false
  ) {
    return this.loadApplicationCurrentProcess(application.id, applicantId, isSecondary).then(res => {
      let currentUrl =
        res === currentPage ||
        ((currentPage === 'NotStarted' || (!isUSRegion && currentPage === 'CompanyDetailsAdded')) &&
          res === 'PrimaryBankAccountSelected' &&
          !application.primaryApplicantAdded);
      let url = '';

      if (res === 'CompanyDetailsAdded') {
        url = '../applicant-details';
      }

      if (res === 'ApplicantDetailsAdded') {
        if (isUSRegion && !isSecondary) {
          url = '../bank-feed-account-us';
        } else {
          url = '../open-agreement';
        }
      }

      if (res === 'PrimaryBankAccountSelected') {
        url = '../open-agreement';
      }

      if (res === 'DocumentSigned') {
        url = '../supporting-documentation';
      }

      if (res === 'SupportingDocumentUploaded') {
        if (!isSecured && !(application.secondaryApplicantAdded || application.secondaryApplicantRequested)) {
          url = '../other-application-complete';
        } else {
          url = '../secured-application-complete';
        }
      }
      if (
        res === 'NotStarted' ||
        (currentPage === 'NotStarted' && res === 'PrimaryBankAccountSelected' && !application.primaryApplicantAdded)
      ) {
        url = '../applicant-company-details';
      }

      return {
        currentUrl,
        url
      };
    });
  }

  loadCurrentProcessSecondApplicant(
    isUSRegion: boolean,
    application: any,
    currentPage: string,
    isSecured = false,
    applicantId = null,
    isSecondary = false
  ) {
    if (application) {
      return this.loadApplicationCurrentProcess(application.id, applicantId, isSecondary).then(res => {
        let currentUrl = currentPage === res;
        let url = '';

        if (res === 'CompanyDetailsAdded') {
          url = '../applicant-details';
        }

        if (res === 'ApplicantDetailsAdded') {
          url = '../open-agreement';
        }

        if (res === 'DocumentSigned') {
          url = '../supporting-documentation';
        }

        if (res === 'SupportingDocumentUploaded') {
          if (!isSecured && !(application.secondaryApplicantAdded || application.secondaryApplicantRequested)) {
            url = '../other-application-complete';
          } else {
            url = '../secured-application-complete';
          }
        }

        if (res === 'NotStarted') {
          url = '../applicant-company-details';
          currentUrl = true;
        }

        return {
          currentUrl,
          url
        };
      });
    } else {
      let currentUrl = true;
      let url = '';
      return Promise.resolve({ currentUrl, url });
    }
  }

  loadDesignElementsOnSite(hostName: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getDesignElementsOnSite(hostName)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.retry(retry, () => this.loadDesignElementsOnSite(hostName, false));
      });
  }

  hasOrganisationNameBeenFinalised(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .hasOrganisationNameBeenFinalised(applicationId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.hasOrganisationNameBeenFinalised(applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadUSIndustryBySearch(search) {
    return this.readApi
      .searchByUsIndustryName({ search: search })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  loadDashboardAccounts(id: string) {
    this.setDashboardAccounts({ data: null, state: DataState.Loading });
    return this.readApi
      .getDashboardAccounts({ applicationId: id })
      .then(res => {
        this.setDashboardAccounts({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setDashboardAccounts({ data: null, state: DataState.Error });
        return err;
      });
  }

  loadBankAccountConnectionSettings() {
    this.setBankAccountConnectionSettings({ data: null, state: DataState.Loading });
    return this.readApi
      .getBankAccountConnectionSettings()
      .then(res => {
        this.setBankAccountConnectionSettings({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setBankAccountConnectionSettings({ data: null, state: DataState.Error });
        return err;
      });
  }

  loadBankStatementMissingTransactions(organisationId: string) {
    this.setMissingBankStatementDocuments({ data: null, state: DataState.Loading });
    return this.readApi
      .getBankStatementMissingTransactions(organisationId)
      .then(res => {
        this.setMissingBankStatementDocuments({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setMissingBankStatementDocuments({ data: null, state: DataState.Error });
        return err;
      });
  }

  loadPartnerCommunications(partnerId: string) {
    this.setPartnerCommunications({ data: null, state: DataState.Loading });
    return this.readApi
      .getPartnerCommunications(partnerId)
      .then(res => {
        this.setPartnerCommunications({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setPartnerCommunications({ data: null, state: DataState.Error });
        return err;
      });
  }

  loadPartnerApplicationCommunications(partnerId: string, applicationId: string) {
    this.setPartnerCommunications({ data: null, state: DataState.Loading });
    return this.readApi
      .getPartnerApplicationCommunications(partnerId, { applicationId: applicationId })
      .then(res => {
        this.setPartnerCommunications({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setPartnerCommunications({ data: null, state: DataState.Error });
        return err;
      });
  }

  loadNewPartnerApplicationCommunicationList(partnerId: string) {
    return this.readApi
      .isNewPartnerApplicationCommunicationList(partnerId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  loadNewPartnerApplicationCommunication(partnerId: string, applicationId: string) {
    const isRead = DataStore.partnerData.hasNewCommunication;
    this.setReadPartnerCommunications(isRead);
    return this.readApi
      .isNewPartnerApplicationCommunication(partnerId, { applicationId: applicationId })
      .then(res => {
        if (res && res._response) {
          this.setReadPartnerCommunications(res._response.parsedBody);
        }
        return res;
      })
      .catch(err => {
        this.setReadPartnerCommunications(isRead);
        return err;
      });
  }

  readPartnerApplicationCommunication(partnerId: string, applicationId: string) {
    return this.writeApi
      .readPartnerCommunication1(partnerId, { applicationId: applicationId })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  readPartnerCommunications(partnerId: string) {
    return this.writeApi
      .readPartnerCommunication(partnerId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  partnerCommunicationStatus(partnerId: string) {
    const isRead = DataStore.partnerData.hasNewCommunication;
    this.setReadPartnerCommunications(isRead);
    return this.readApi
      .isNewPartnerCommunication(partnerId)
      .then(res => {
        if (res && res._response) {
          this.setReadPartnerCommunications(res._response.parsedBody);
        }
        return res;
      })
      .catch(err => {
        this.setReadPartnerCommunications(isRead);
        return err;
      });
  }

  agentCommunicationStatus(agentId: string) {
    const isRead = DataStore.agentData.hasNewCommunication;
    this.setReadAgentCommunications(isRead);
    return this.readApi
      .isNewAgentCommunication(agentId)
      .then(res => {
        if (res && res._response) {
          this.setReadAgentCommunications(res._response.parsedBody);
        }
        return res;
      })
      .catch(err => {
        this.setReadAgentCommunications(isRead);
        return err;
      });
  }

  readCommunications(customerId: string) {
    return this.writeApi
      .readCommunication(customerId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  communicationStatus(applicationId: string) {
    const isRead = DataStore.customerData.hasNewCommunication;
    this.setReadCommunications(isRead);
    return this.readApi
      .isNewCommunication(applicationId)
      .then(res => {
        if (res && res._response) {
          this.setReadCommunications(res._response.parsedBody);
        }
        return res;
      })
      .catch(err => {
        this.setReadCommunications(isRead);
        return err;
      });
  }

  uploadDocument(applicationId: string, file: any, externalSubmitted: boolean = true) {
    return this.writeApi
      .uploadDocument(applicationId, { uploadedFile: file, externalSubmitted: externalSubmitted })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  uploadDocuments(applicationId: string, files: any, externalSubmitted: boolean = true) {
    return this.writeApi
      .uploadDocuments(applicationId, { body: files, externalSubmitted: externalSubmitted })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  uploadBankStatementDocument(
    organisationId: string,
    file: any,
    externalSubmitted: boolean = true,
    fileDateStr: string = ''
  ) {
    return this.writeApi
      .uploadBankStatementDocument(organisationId, {
        uploadedFile: file,
        externalSubmitted: externalSubmitted,
        fileDateStr: fileDateStr
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  uploadBankStatementDocuments(organisationId: string, files: any, externalSubmitted: boolean = true) {
    return this.writeApi
      .uploadBankStatementDocuments(organisationId, { body: files, externalSubmitted: externalSubmitted })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  loadBankStatementDocuments(organisationId: string, externalSubmitted: boolean = true) {
    return this.readApi
      .getBankStatementDocuments(organisationId, { externalSubmitted: externalSubmitted })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  loadGeneralDocuments(applicationId: string, externalSubmitted: boolean = true) {
    return this.readApi
      .getDocumentsPaged(applicationId, { externalSubmitted: externalSubmitted })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  addNote(applicationId, note) {
    return this.writeApi
      .addNote(applicationId, { body: note })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  loadAuthorNotes(applicationId: string) {
    return this.readApi
      .getAuthorNotes(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  addAgentNote(partnerId, agentId: string, note: string) {
    return this.writeApi
      .addAgentNote(partnerId, { agentId: agentId, note: note })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  loadPartnerLogo(partnerId: string) {
    return this.readApi
      .downloadPartnerLogo(partnerId)
      .then(res => res)
      .catch(err => null);
  }

  loadNewAgentApplicationCommunicationList(agentId: string) {
    return this.readApi
      .isNewAgentApplicationCommunicationList(agentId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  loadNewAgentApplicationCommunication(agentId: string, applicationId: string) {
    const isRead = DataStore.agentData.hasNewCommunication;
    this.setReadAgentCommunications(isRead);
    return this.readApi
      .isNewAgentApplicationCommunication(agentId, { applicationId: applicationId })
      .then(res => {
        if (res && res._response) {
          this.setReadAgentCommunications(res._response.parsedBody);
        }
        return res;
      })
      .catch(err => {
        this.setReadAgentCommunications(isRead);
        return err;
      });
  }

  loadAgentApplicationCommunications(agentId: string, applicationId: string) {
    this.setAgentCommunications({ data: null, state: DataState.Loading });
    return this.readApi
      .getAgentApplicationCommunications(agentId, { applicationId: applicationId })
      .then(res => {
        this.setAgentCommunications({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setAgentCommunications({ data: null, state: DataState.Error });
        return err;
      });
  }

  readAgentApplicationCommunication(agentId: string, applicationId: string) {
    return this.writeApi
      .readAgentCommunication(agentId, { applicationId: applicationId })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  addUpdateAgentCommunication(communication: CommunicationProjectionExtension) {
    return this.writeApi
      .addUpdateAgentCommunication({ body: communication, sendToPartner: true })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  getApplicationMaxTerm(applicationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getMaximumTerm(applicationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.getApplicationMaxTerm(applicationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  isAccountingSystemEnabled(retry = true) {
    this.setRetry(retry);
    this.setAccountSystemEnabled(null);
    return this.readApi
      .isAccountingSystemEnabled()
      .then(res => {
        this.setAccountSystemEnabled(res._response.parsedBody);
        return res;
      })
      .catch(err => {
        console.log(err);
        var isRetry = this.retry(retry, () => this.isAccountingSystemEnabled(false));
        if (!isRetry) {
          return err;
        }
      });
  }

  getSystemMessage() {
    this.setSystemMessage(null);
    return this.readApi
      .getSystemMessage()
      .then(result => {
        this.setSystemMessage(result.message);
        return result.message;
      })
      .catch(err => {
        this.setSystemMessage(null);
        return err;
      });
  }

  uploadSupportDocuments(applicationId: string, file: any) {
    return this.writeApi
      .uploadSupportDocuments({ applicationId: applicationId, body: file })
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  loadBankStatementStaleDetail(organisationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getBankStatementStaleDetail(organisationId)
      .then(res => {
        if (res && res.manualUpload && res.manualUpload.isUploadStatement && res.manualUpload.missingStatements) {
          let data: PlexeOrganisationServiceModelsTransactionMatrix[] = [];
          res.manualUpload.missingStatements.forEach(item => {
            data.push({ dateStr: item });
          });
          this.setMissingBankStatementDocuments({ data: data, state: DataState.Loaded });
        }
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadBankStatementStaleDetail(organisationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadManualUploadDetails(organisationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getManualUploadDetails(organisationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadManualUploadDetails(organisationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  loadDisconnectedBankAccounts(organisationId: string, retry = true) {
    this.setRetry(retry);
    return this.readApi
      .getDisconnectedBankAccounts(organisationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        var isRetry = this.retry(retry, () => this.loadDisconnectedBankAccounts(organisationId, false));
        if (!isRetry) {
          return err;
        }
      });
  }

  //  ____  _____ _____
  // / ___|| ____|_   _|
  // \___ \|  _|   | |
  //  ___) | |___  | |
  // |____/|_____| |_|

  setAccountSystemEnabled(isEnable: boolean) {
    DataStore.isAccountSystemEnabled = isEnable;
  }

  setAgentCommunications(data: Data<CommunicationProjectionExtension[]>) {
    DataStore.agentData.communications = data;
  }

  setReadCommunications(isRead: boolean) {
    DataStore.customerData.hasNewCommunication = isRead;
  }

  setReadPartnerCommunications(isRead: boolean) {
    DataStore.partnerData.hasNewCommunication = isRead;
  }

  setReadAgentCommunications(isRead: boolean) {
    DataStore.agentData.hasNewCommunication = isRead;
  }

  setPartnerCommunications(data: Data<CommunicationProjectionExtension[]>) {
    DataStore.partnerData.partnerCommunications = data;
  }

  setDashboardAccounts(data: Data<DashboardAccounts>) {
    DataStore.dashboardAccounts = data;
  }

  setBusinessName(data: string) {
    DataStore.application.businessName = data;
  }

  setHasLoggedIn(data: boolean) {
    DataStore.applicationHasLoggedIn = data;
  }

  setSystemMessage(message: string) {
    DataStore.systemMessage = message;
  }

  setApplication(data: ApplicationListProjection) {
    DataStore.application = Object.assign(data, { bankAccounts: null });
  }

  setApplicationSettings(data: ApplicationSettings) {
    DataStore.applicationSetting = data;
  }

  setApplicationBankAccounts(data: BankAccount[]) {
    DataStore.bankAccounts = data;
  }

  setIncomeDetails(data: IncomeDetails) {
    DataStore.applicationIncomeDetails = data;
  }

  setOfferDetails(data: OfferDetails) {
    DataStore.applicationOfferDetails = data;
  }

  setSupportingDocumentDetails(data: SupportDocumentDetails[]) {
    DataStore.supportDocumentDetails = data;
  }

  setPrimaryApplicationCurrentProcess(data: string) {
    DataStore.primaryApplicationCurrentProcess = data;
  }

  setSecondaryApplicationCurrentProcess(data: string) {
    DataStore.secondaryApplicationCurrentProcess = data;
  }

  setBankAccountConnectionSettings(data: Data<BankAccountConnectionSettings>) {
    DataStore.bankAccountConnectionSettings = data;
  }

  setMissingBankStatementDocuments(data: Data<PlexeOrganisationServiceModelsTransactionMatrix[]>) {
    DataStore.missingBankStatementDocuments = data;
  }

  setPlatformSettings(data: any) {
    DataStore.platformSettings = data;
  }

  resetApplication() {
    DataStore.application = null;
    this.resetApplicationBankAccounts();
  }

  resetApplicationBankAccounts() {
    DataStore.bankAccounts = null;
  }

  getOrganisationExtraDetails(organisationId: string) {
    return this.readApi.getOrganisationExtraDetails({ organisationId: organisationId });
  }

  sendCustomerAudit(applicationId: string, message: string) {
    return this.writeApi
      .sendCustomerAudit(applicationId, { message: message })
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }
}
